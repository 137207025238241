import Investments from '@/views/dashboard/Investments.vue'
import CreateBusiness from '@/views/factoring/CreateBusiness.vue'
import BeforeFacture from '@/views/factoring/BeforeFacture.vue'
import Stats from '@/views/factoring/Stats.vue'
import PersonalData from '@/views/factoring/NewMyinfo.vue'

export default [
  {
    path: 'estadisticas',
    name: 'stats',
    component: Stats,
  },
  {
    path: 'mis-facturas',
    name: 'my-facture',
    component: Investments,
  },
  {
    path: 'anticipo-facturas',
    name: 'anticipo-facture',
    component: BeforeFacture,
	},
  {
    path: 'pronto-pago',
    name: 'pronto-pago',
    component: () => import(/* webpackChunkName: "payment-status" */ '@/views/dashboard/PaymentStatus.vue'),
  },
  {
    path: 'linea',
    name: 'payment-mensual',
    component: () => import(/* webpackChunkName: "payment-mensual" */ '@/views/dashboard/CartolaMensual.vue'),
  },
  {
    path: 'detalle-pagos',
    name: 'payment-details',
    component: () => import(/* webpackChunkName: "payment-details" */ '@/views/dashboard/PaymentDetails.vue'),
  },
	//Profile stuffs
  {
    path: 'cuenta-banco',
    name: 'My-info-acount-bank',
    component: () => import(/* webpackChunkName: "bank-account" */ '@/views/factoring/NewMyinfo.vue'),
  },
  {
    path:'mis-claves',
    name: 'My-keys-acount-bank',
    component: () => import(/* webpackChunkName: "bank-account" */ '@/views/factoring/Mypass.vue'),
  },
  {
    path: 'pagar-socio-club',
    name: 'pay-membership',
    component: () => import(/* webpackChunkName: "pay-membership" */ '@/views/dashboard/PayMembership.vue'),
  },
  {
    path: 'pago-pendiente/:paymentId',
    name: 'pay-pending-payment',
    component: () => import(/* webpackChunkName: "pay-pending-payment" */ '@/views/dashboard/PayPendingPayment.vue'),
    props: true,
  },
  {
    path: 'createbussiness',
    name: 'create-bussiness',
    component: CreateBusiness,
    props: true,
  },
  {
    path: 'empresas',
    name: 'bussiness',
    component: () => import(/* webpackChunkName: "busines" */ '@/views/factoring/Business.vue'),
  },
  {
    path: 'operaciones',
    name: 'my-operation',
    component: () => import(/* webpackChunkName: "busines" */ '@/views/factoring/Myoperations.vue'),
  },
  {
    path: 'mis-operaciones',
    name: 'my-operation-request',
    component: () => import(/* webpackChunkName: "busines" */ '@/views/factoring/MyOperationsRequests.vue'),
  },
  // {
  //   path: 'operaciones',
  //   name: 'my-operation-request',
  //   component: () => import(/* webpackChunkName: "busines" */ '@/views/factoring/MyOperationsRequests.vue'),
  // },
  {
    path: 'sii',
    name: 'My-sii',
    component: () => import(/* webpackChunkName: "bank-account" */ '@/views/factoring/Mysii.vue'),
  },
  {
    path: 'tgr',
    name: 'Tgr',
    component: () => import(/* webpackChunkName: "bank-account" */ '@/views/factoring/Tgr.vue'),
  },
  {
    path: 'previred',
    name: 'Previred',
    component: () => import(/* webpackChunkName: "bank-account" */ '@/views/factoring/Previred.vue'),
  },
  {
    path: 'notificaciones',
    name: 'Notification',
    component: () => import(/* webpackChunkName: "bank-account" */ '@/views/factoring/Notification.vue'),
  },
  {
    path: 'ceder-facturas',
    name: 'ceder-facture',
    component: () => import(/* webpackChunkName: "bank-account" */ '@/views/factoring/FactureCeder.vue'),
  },
  {
    path: 'creditos',
    name: 'credit',
    component: Stats,
  },
  {
    path: 'datos-personales',
    name: 'personal-data',
    component: PersonalData,
  },
  {
    path: 'cambiar-contraseña',
    name: 'change-password',
    component: () => import( /* webpackChunkName: "change-password" */ '@/views/dashboard/ChangePassword.vue'),
  },
  {
    path: 'solicitar/credito',
    name: 'request-credit',
    component: () => import(/* webpackChunkName: "credit" */ '@/views/dashboard/Credit.vue'),
  },
  {
    path: 'editar-empresa/:companyId',
    name: 'edit-business',
    component: () => import( /* webpackChunkName: "edit-business" */ '@/views/dashboard/EditBusiness.vue'),
    props: true,
  },
  {
    path: 'contratos',
    name: 'contracts',
    component: () => import(/* webpackChunkName: "contracts" */ '@/views/dashboard/Contracts.vue'),
  },
  {
    path: 'mandatos',
    name: 'terms',
    component: () => import(/* webpackChunkName: "terms" */ '@/views/dashboard/Terms.vue'),
  },
  {
    path: 'declaraciones',
    name: 'declaration',
    component: () => import(/* webpackChunkName: "terms" */ '@/views/dashboard/Declarations.vue'),
  },
  {
    path: 'pagares-y-certificados',
    name: 'iou-and-certificates',
    component: () => import(/* webpackChunkName: "iou-and-certificates" */ '@/views/dashboard/IOUAndCertificates.vue'),
  },
  {
    path: 'Documentos',
    name: 'mis-documents',
    component: () => import(/* webpackChunkName: "iou-and-certificates" */ '@/views/factoring/documents/Documents.vue'),
  },
  {
    path: 'finanzas',
    name: 'mis-finanzas',
    component: () => import(/* webpackChunkName: "iou-and-certificates" */ '@/views/factoring/Finances.vue'),
  },
  
]