<template>
	<div class="public-navbar">
		<v-toolbar v-resize="onResize" dark fixed app clipped-left :color ="dark ? 'active-menu' : 'black'" :class ="user != null && showPanelInv != false ? { [`pt-${isMobile ? 0 : 0}`]: true } : { [`pt-${isMobile ? 0 : 0}`]: true }">
      		<router-link to="/">
				<img alt="RedCapital logo" :src="`${RedCapital[SelectedCountry].Logo}`" class="rclogo" style="padding-right: 20px;"/>
			</router-link>
			<img v-if="user != null && showPanelInv != false" alt="RedCapital logo" :src="urlimag" class="invlogo"/>
			<v-toolbar-items v-if="!isMobile" style="width:100%;align-items:center;">
				<v-btn v-if=" !valroutesfacto && projectsVisibility && SelectedCountry != 'PYME'" v-show="!userAnglo" class="font-OneRem" style="text-transform:capitalize ;font-size: 1.2rem !important" to="/invertir" flat small>
					Invertir
				</v-btn>
				<v-spacer></v-spacer>
				<!-- RC -->
				<!-- to="/panel-facto/empresas" -->
				<v-tooltip v-if="showTooltip" bottom>
      				<template v-slot:activator="{ on }">
						<!-- RC -->
						<div
							v-on="on"
							@click="accionNombreNav('/panel-facto/empresas')"
							ref="businessNombre"
							v-if="businesSelected != null && menu == 3 && user.via_id != 2" 
							class="rc-nombreEmpresa__navbar dis-none rc-navEmpresa">
							{{ businesSelected.nombre }}
						</div>
						<!-- ANGLO -->
						<div
							v-on="on"
							@click="accionNombreNav('/panel-facto/solicitante/editar-negocio/'+businesSelected.id)"
							ref="businessNombre"
							v-if="businesSelected != null && menu == 3 && user.via_id == 2"
							class="rc-nombreEmpresa__navbar dis-none rc-navEmpresa">
							{{ businesSelected.nombre }}
						</div>
					</template>
					<span>{{ businesSelected.nombre }}</span>
				</v-tooltip>
				<div v-else>
					<!-- RC -->
					<div
						@click="accionNombreNav('/panel-facto/empresas')"
						ref="businessNombre"
						v-if="businesSelected != null && menu == 3 && user.via_id != 2" 
						class="rc-nombreEmpresa__navbar dis-none rc-navEmpresa">
						{{ businesSelected.nombre }}
					</div>
					<!-- ANGLO -->
					<div
						v-on="on"
						@click="accionNombreNav('/panel-facto/solicitante/editar-negocio/'+businesSelected.id)"
						ref="businessNombre"
						v-if="businesSelected != null && menu == 3 && user.via_id == 2"
						class="rc-nombreEmpresa__navbar dis-none rc-navEmpresa">
						{{ businesSelected.nombre }}
					</div>
				</div>
				<v-spacer></v-spacer>
				<v-btn v-if="user != null && user.user_tipo_id == 1" to="/admin/asociar" flat>
					Controlar cuenta
				</v-btn>
				<v-btn class="ml-2 " style="text-transform:capitalize;height: auto;padding: 5px 17px;font-size:1rem !important" v-if="user == null" outline round color="white" dark v-on="on" to="/registrar">
					Prontopago
				</v-btn>
				<v-btn class="ml-2 " style="text-transform:capitalize;height: auto;padding: 5px 17px;font-size:1rem !important" v-if="user == null" outline round color="white" dark v-on="on" to="/registrar">
					Registrarse
				</v-btn>
				<v-btn class="ml-2 " style="text-transform:capitalize;height: auto;padding: 5px 17px;background: rgba(81, 89, 96,80%) !important;font-size:1rem !important" v-if="user == null" outline round color="white" dark v-on="on" to="/login">
					Ingresar
				</v-btn>
				<v-menu offset-y v-if="user != null">
					<template v-slot:activator="{ on }">
						<v-btn class="ml-2 " style="text-transform:capitalize;height: auto;padding: 5px 17px;font-size:1rem !important" outline round color="white" dark v-if="user != null" v-on="on">
							<div>{{ capitalize(user.nombre)  }} {{ capitalize(user.app_pat) }}</div>
							<v-icon>expand_more</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-tile v-if="menu == 2 || user.user_tipo_id != 3" to="/invertir">
							<v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat>
								Invertir
							</v-list-tile-title>
						</v-list-tile>
						<v-list-tile v-if="menu == 2 || user.user_tipo_id != 3" @click="panelInversionist()">
							<v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat>
								Panel Inversionista
							</v-list-tile-title>
						</v-list-tile>
						<v-list-tile v-if="menu == 3 && user.user_tipo_id != 2" to="/panel-facto/cuenta-banco">
							<v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat>
								Mis datos
							</v-list-tile-title>
						</v-list-tile>
						<v-list-tile v-if="menu == 2 || user.user_tipo_id != 3" to="/panel/inversionista/mis-inversiones">
							<v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat>
								Resumen inversiones
							</v-list-tile-title>
						</v-list-tile>
						<v-list-tile v-if="menu == 2 || user.user_tipo_id != 3" to="/panel/inversionista/transferencias-pendientes">
							<v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat>
								Transferencias pendientes
							</v-list-tile-title>
						</v-list-tile>
						<v-list-tile @click="logout">
							<v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat>
								Cerrar sesión
							</v-list-tile-title>
						</v-list-tile>
					</v-list>
				</v-menu>
			</v-toolbar-items>
			<v-toolbar-side-icon v-if="isMobile" @click="showMenu = true" class="ml-auto" alt="Mostrar menu" aria-label="Menu">
				<v-icon>account_circle</v-icon>
			</v-toolbar-side-icon>
		</v-toolbar>
		<!-- Menu Movil -->
		<v-navigation-drawer v-model="showMenu"  right app fixed temporary class="rc-menuDrawerSitios">
			<!-- Datos usuario logeado en Panel inversionista y solicitante -->
			<v-list class="transparent" v-if="user != null">
				<!-- Avatar -->
				<v-list-tile class="v-list-item rc-listAvatar" style="height:80px">
					<div style="text-align: center; width: 100%; line-height: 1;">
						<v-icon large style="margin-bottom:10px;">account_circle</v-icon>
						<div>{{ capitalize(user.nombre)  }} {{ capitalize(user.app_pat)}}</div>
					</div>
				</v-list-tile>
				<!-- Avatar -->
				<!-- Menu solicitante -->
				<v-list v-if="menu === 3 && user.user_tipo_id != 2" class="transparent pa-2">
					<div v-for="(perfilSolicitanteMenu, index2) in perfilSolicitanteMenus" :key="`${perfilSolicitanteMenu.icon}-${index2}`">
						<v-list-tile v-if="!perfilSolicitanteMenu.categories" class="v-list-item rc-list-item rc-listItem-pad" :active-class="activeClassMenu" :to="perfilSolicitanteMenu.to.path ? perfilSolicitanteMenu.to.path : {name: perfilSolicitanteMenu.to.name}">
							<div style="text-align: center; width: 100%; line-height: 1;">
								<span class="rc-listItem-img material-icons-outlined" style="height:26px;width:26px">{{ perfilSolicitanteMenu.icon }}</span>
								<div class="rc-listItem-title">{{ perfilSolicitanteMenu.text }}</div>
							</div>
						</v-list-tile>
						<v-list-group v-else class="rc-list-item">
							<template v-slot:activator>
								<div style="text-align: center; width: 100%; line-height: 1;">
									<span class="rc-listItem-img material-icons-outlined" style="height:26px;width:26px">{{ perfilSolicitanteMenu.icon }}</span>
									<div class="rc-listItem-title">{{ perfilSolicitanteMenu.text }}</div>
								</div>
							</template>
							<v-list-tile class="v-list-item rc-list-item rc-listItem-pad" v-for="(category, index3) in perfilSolicitanteMenu.categories" :key="`${category.icon}-${index3}`" :active-class="activeClassMenu" :to="category.to.path ? category.to.path : {name: category.to.name}">
								<div style="text-align: center; width: 100%; line-height: 1;">
									<span class="rc-listItem-img material-icons-outlined" style="height:26px;width:26px">{{ category.icon }}</span>
									<div class="rc-listItem-title">{{ category.text }}</div>
								</div>
							</v-list-tile>
						</v-list-group>
					</div>
					<v-list-tile class="v-list-item rc-listItem-pad rc-listCerrarSesion" :active-class="activeClassMenu" @click="logout">
						<div style="text-align: center; width: 100%; line-height: 1;">
							<v-icon class="rc-listItem-img">logout</v-icon>
							<div class="rc-listItem-title">Cerrar sesión</div>
						</div>
					</v-list-tile>
				</v-list>
				<!-- Menu inversionista -->
				<v-list v-if="menu === 2 || user.user_tipo_id != 3"  class="transparent pa-2">
					<div v-for="(perfilInversionistaMenu, index2) in perfilInversionistaMenus" :key="`${perfilInversionistaMenu.icon}-${index2}`">
						<v-list-tile v-if="!perfilInversionistaMenu.categories" class="v-list-item rc-list-item rc-listItem-pad" :active-class="activeClassMenu" :to="perfilInversionistaMenu.to.path ? perfilInversionistaMenu.to.path : {name: perfilInversionistaMenu.to.name}">
							<div style="text-align: center; width: 100%; line-height: 1;">
								<span class="rc-listItem-img material-icons-outlined" style="height:26px;width:26px">{{ perfilInversionistaMenu.icon }}</span>
								<div class="rc-listItem-title">{{ perfilInversionistaMenu.text }}</div>
							</div>
						</v-list-tile>
						<v-list-group v-else class="rc-list-item">
							<template v-slot:activator>
								<div style="text-align: center; width: 100%; line-height: 1;">
									<span class="rc-listItem-img material-icons-outlined" style="height:26px;width:26px">{{ perfilInversionistaMenu.icon }}</span>
									<div class="rc-listItem-title">{{ perfilInversionistaMenu.text }}</div>
								</div>
							</template>
							<v-list-tile class="v-list-item rc-list-item rc-listItem-pad" v-for="(category, index3) in perfilInversionistaMenu.categories" :key="`${category.icon}-${index3}`" :active-class="activeClassMenu" :to="category.to.path ? category.to.path : {name: category.to.name}">
								<div style="text-align: center; width: 100%; line-height: 1;">
									<span class="rc-listItem-img material-icons-outlined" style="height:26px;width:26px">{{ category.icon }}</span>
									<div class="rc-listItem-title">{{ category.text }}</div>
								</div>
							</v-list-tile>
						</v-list-group>
					</div>
					<v-list-tile class="v-list-item rc-listItem-pad rc-listCerrarSesion" :active-class="activeClassMenu" @click="logout">
						<div style="text-align: center; width: 100%; line-height: 1;">
							<v-icon class="rc-listItem-img">logout</v-icon>
							<div class="rc-listItem-title">Cerrar sesión</div>
						</div>
					</v-list-tile>
				</v-list>
			</v-list>
			<!-- Datos usuario logeado en Panel inversionista y solicitante -->
			<v-list v-else>
				<v-list-tile :to="{ name: 'comofuncionasolicitar' }" v-if="SelectedCountry == 'CL' || SelectedCountry == 'PYME'">
					<v-list-tile-title>Solicitar Financiamiento</v-list-tile-title>
				</v-list-tile>
				<v-list-tile v-show="!userAnglo" v-if=" !valroutesfacto && projectsVisibility && SelectedCountry != 'PYME'" :to="{ name: 'investment' }">
					<v-list-tile-title>Invertir</v-list-tile-title>
				</v-list-tile>
				<v-list class="transparent" >
					<v-list-group >
						<template v-slot:activator>
							<v-list-tile>
								<v-list-tile-title>¿Por qué RedCapital?</v-list-tile-title>
							</v-list-tile>
						</template>
						<v-list-tile to="/quienes-somos">
							<v-list-tile-title class="ml-1 nav-1000 " flat style="text-transform:capitalize">¿Quiénes somos?</v-list-tile-title>
						</v-list-tile>
						<v-list-tile to="/quienes-somos#testimonios">
							<v-list-tile-title class="ml-1 nav-1000 " flat style="text-transform:capitalize">Testimonios</v-list-tile-title>
						</v-list-tile>
						<v-list-tile to="/prensa">
							<v-list-tile-title class="ml-1 nav-1000 " flat style="text-transform:capitalize">En Prensa</v-list-tile-title>
						</v-list-tile>
					</v-list-group>
				</v-list>
				<v-list class="transparent" >
					<v-list-group >
						<template v-slot:activator>
							<v-list-tile>
								<v-list-tile-title>Ayuda</v-list-tile-title>
							</v-list-tile>
						</template>
						<v-list-tile to="/preguntas-frecuentes">
							<v-list-tile-title class="ml-1 nav-1000 " flat style="text-transform:capitalize">Preguntas Frecuentes</v-list-tile-title>
						</v-list-tile>
						<v-list-tile to="/garantia-riesgos">
							<v-list-tile-title class="ml-1 nav-1000 " flat style="text-transform:capitalize">Garantías y riesgos</v-list-tile-title>
						</v-list-tile>
						<v-list-tile to="/contacto">
							<v-list-tile-title class="ml-1 nav-1000 " flat style="text-transform:capitalize">Contacto</v-list-tile-title>
						</v-list-tile>
						<v-list-tile @click="goblog">
							<v-list-tile-title class="ml-1 nav-1000 " flat style="text-transform:capitalize">Blog</v-list-tile-title>
						</v-list-tile>
					</v-list-group>
				</v-list>
				<v-list-tile v-if="user == null" to="/registrar">
					<v-list-tile-title>Crear cuenta</v-list-tile-title>
				</v-list-tile>
				<v-list-tile v-if="user == null" @click="login">
					<v-list-tile-title>Ingresar</v-list-tile-title>
				</v-list-tile>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	import { mapState } from "vuex";
	import {
		perfilInversionistaMenus, // Menus para sitio inversionista
		perfilSolicitanteMenus // Menus para sitio Solicitante
	} from "@/models/DashboardLinks";
	import { EventBus } from "@/main";
	import { RedCapital, RedCapitalConfig, SelectedCountry } from "@/configuration";

	@Component({
		computed: {
    		...mapState(["user", "menu", "showPanelInv", "businesSelected"]),
    		canShowLinks() {
				return (
					this.$route.path.startsWith("/panel/") ||
					this.$route.path.startsWith("/panel-facto/")
				);
    		},
    		projectsVisibility() {
				return (
					this.$store.state.user != null ||
					RedCapitalConfig(SelectedCountry).Project.PublicVisibility
				);
			},
			activeClass() {
				return "primary white--text";
			},
			accountType() {
				return this.$store.state.user.user_tipo_id == 2
					? "investor"
					: "applicant";
			},
			urlimag() {
				return this.$store.state.menu == 2
					? "/images/sitio-inversionista.png"
					: "/images/sitio-solicitante.png";
			},
			valroutesfacto(){
				return (
					this.$route.path.startsWith("/panel-facto/")
				)
			},
			userAnglo(){
				return (
					this.$store.state.user != null && this.$store.state.user.via_id == 2 ? true : false
				)
			},
			activeClassMenu() {
				return "rc-menuPanelActive";
			},
			nombreCompleto(){
				return(
					this.capitalize(this.$store.state.user.nombre)+' '+this.capitalize(this.$store.state.user.app_pat)
				)
			} 
  		},
  		data() {
			return {
				SelectedCountry,
				RedCapital,
				perfilInversionistaMenus,
				perfilSolicitanteMenus,
				nombreCompleto: null,
				dark: true,
				canShowUserMenu: false,
				showMenu: false,
				isMobile: true,
				uglyLinks: [
				{
					to: "/quienes-somos",
					text: "Sobre nosotros",
				},
				{
					to: "/prensa",
					text: "En prensa",
				},
				{
					to: "/contacto",
					text: "Contacto",
				},
				{
					to: "/preguntas-frecuentes",
					text: "Preguntas frecuentes",
				},
				{
					to: "/garantia-riesgos",
					text: "Garantías y riesgos",
				},
				],
				widthEmpresaNav: 0,
				resizeCount:{
					large: 0,
					medium: 0
				},
				showTooltip: false
			};
		},
		methods: {
			onResize() {
				if (window.innerWidth <= 960) {
					this.$data.isMobile = true;
				} else {
					this.$data.isMobile = false;
					this.$data.showMenu = false;
				}
				let widthScreen = window.innerWidth;
				// @ts-ignore
				this.checkNombreNav(widthScreen);
			},
			login() {
				this.$data.showMenu = false;
				this.$data.canShowUserMenu = false;
				EventBus.$emit("open-login-dialog");
			},
			logout() {
				EventBus.$emit("logout");
			},
			goblog(){
				window.open("https://blog.redcapital.cl/", '_blank');
			},
			capitalize(texto){ // #Pendiente dejar esta funcion global para llamar donde sea.
				const lower = texto.toLowerCase();
				return texto.charAt(0).toUpperCase()+lower.slice(1); 
			},
			checkNombreNav(widthScreen){
				this.$data.resizeCount.medium = 0;
				this.$data.resizeCount.large = 0;
				this.$data.showTooltip = false;
				if(widthScreen > 960 && widthScreen < 1264){
					this.$data.resizeCount.large = 0;
					this.$data.resizeCount.medium += 1;
					// @ts-ignore
					this.evalTooltipBussines(this.$data.resizeCount.medium, 32);
				}else if(widthScreen > 1264 && widthScreen < 1904){
					this.$data.resizeCount.medium = 0;
					this.$data.resizeCount.large += 1;
					// @ts-ignore
					this.evalTooltipBussines(this.$data.resizeCount.large, 56);
				}else if(widthScreen > 1904){
					this.$data.widthEmpresaNav = 0;
					this.$data.resizeCount.medium = 0;
					this.$data.resizeCount.large = 0;
					this.$data.showTooltip = false;
				}
			},
			evalTooltipBussines(countScreen, numChar){
				if(countScreen == 1){
					this.$nextTick(() => {
						this.$data.widthEmpresaNav = this.$refs['businessNombre'].clientWidth;
						if(this.$store.state.businesSelected.nombre.length >= numChar){
							this.$data.showTooltip = true;
						}else{
							this.$data.showTooltip = false;
						}
					});
				}
			},
			accionNombreNav(url){
				this.$router.push(url);
			}
		},
		beforeMount() {
			this.$data.nombreCompleto = this.capitalize(this.$store.state.user.nombre)+' '+this.capitalize(this.$store.state.user.app_pat);
		},
		watch:{
			businesSelected(v){
				let widthScreen = window.innerWidth;
				// @ts-ignore
				this.checkNombreNav(widthScreen);
			}
		}
	})
	export default class NavBar extends Vue {}
</script>

<style lang="scss" scoped>
	.rc-navEmpresa{
	border: 1.5px solid #FFFFFF;
	border-radius: 500px;
	color: #FFFFFF;
	font-family: SF Pro Text;
	font-style: normal;
	font-weight: 600;
	background-color:transparent

	/* identical to box height */

	
	}
	.font-OneRem{
		font-size: 1rem !important;
	}
	.navbar {
		z-index: 3;
	}
	.rclogo {
		@media (max-width: 960px) {
			height: 40px;
		}
		@media (min-width: 960px) {
			height: 50px;
		}
	}
	.invlogo {
		@media (max-width: 960px) {
			height: 20px;
		}
		@media (min-width: 960px) {
			height: 25px;
		}
	}
	.dis-none {
		@media (max-width: 600px) {
			display: none;
		}
	}
	.v-menu__content {
		position: fixed !important;
		border-radius: 1rem !important;
	}
	.active {
		box-shadow: 0px -2px 5px 0px rgb(195 87 14);
		//box-shadow: 4px 0 0px -2px #888;
		color: white;
		//border-radius: 0.325rem 0.325rem 0px 0px;
		border-radius: 15px 15px 0px 0px;
	}
	.btnsitio {
		background-color: #ecded4 !important;
		color: #c3570f;
		//border-radius: 0.325rem 0.325rem 0px 0px;
		border-radius: 15px 15px 0px 0px;
	}
	
	// MENUS Sito Solicitante e Inversionista
	/*.rc-listCerrarSesion{
		position: absolute;
		bottom: 0;
		width: 284px;
		margin-bottom: 30px;
		a{
			div{
				i{
					color: $rc-prim-n1 !important;
				}
				div
				{
					color: $rc-prim-n1 !important;
					font-weight: 600;
				}
			}
		}
	}*/
	.rc-menuDrawerSitios{
		margin-top:64px !important;
		z-index:1; 
		width:150px !important; 
		height: calc( 100vh - 64px ) !important; 
		min-height: 100%;
	}
	.rc-listItem-title{
		font-weight: 600;
		//color: $rc-prim-g2 !important;
	}
	// Breakpoints 
	@each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
				
			}@else if $breakpoint == "large"{
				
			}@else if $breakpoint == "medium-to-large"{
				.rc-menuDrawerSitios{
					margin-top: 0 !important;
					z-index: 5;
					width: 300px !important;
				}
				.rc-listItem-img{
					float: left;
				}
				.rc-listItem-title{
					float: left;
					line-height: 2;
					margin-left: 10px;
				}
			}@else if $breakpoint == "small-to-medium"{
				.rc-menuDrawerSitios{
					margin-top: 0 !important;
					z-index: 5;
					width: 300px !important;
				}
				.rc-listItem-img{
					float: left;
				}
				.rc-listItem-title{
					float: left;
					line-height: 2;
					margin-left: 10px;
				}
			}@else if $breakpoint == "small"{
				.rc-menuDrawerSitios{
					margin-top: 0 !important;
					z-index: 5;
					width: 300px !important;
				}
				.rc-listItem-img{
					float: left;
				}
				.rc-listItem-title{
					float: left;
					line-height: 2;
					margin-left: 10px;
				}
			}
		}
	}
</style>
