export default [
  {
    path: 'solicitudes',
    name: 'requests',
    component: () => import('@/views/dashboard/applicant/Requests.vue'),
  },
  {
    path: 'mis-proyectos',
    name: 'my-projects',
    component: () => import('@/views/dashboard/applicant/Projects.vue'),
  },
  /* Ruta para editar negocio de anglo */
  {
    path: 'editar-negocio/:companyId',
    name: 'edit-negocio',
    component: () => import('@/views/angloAmerican/EditarNegocio.vue'),
    props: true,
  },
]