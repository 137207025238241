import store from "@/store";

export const authenticationRequired = (to: any, from: any, next: any) => {
  if (store.state.user != null && store.state.token != null) {
    // @ts-ignore
    if(store.state.user.via_id == 1){
      if(to.name == "pruebas-angloamerican" ){
        next('/evaluacion');
      }
      // @ts-ignore
    }else if (store.state.user.via_id == 2){
      if(to.name == "pruebas-scoring"){
        next('/angloamerican');
      }
    }
    if(to.path.startsWith("/panel/")){
      //@ts-ignore
      store.state.menu = 2
    }else if(to.path.startsWith("/panel-facto/")){
      //@ts-ignore
      store.state.menu = 3
    }
    next();
  } else {
    store.commit("logout");
    next(`/ingresar?return=${to.path}`);
  }
};
