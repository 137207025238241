import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import api from './api';
import { State } from './configuration';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    menu:null,
    user: null,
    token: null,
    expireTime: null,
    gatewayTransaction: null,
    lastCod: null,
    onlyActives: true,
    gatewayTransactionConfirmed: false,
    transactionIsPendingPayment: false,
    pendingPaymentsIds: [],
    transactionProjectId: null,
    business: null,
    requesting: null,
    proyectId: null,
    currentInvestmentId: null,
    isLastInvestment: false,
    lastSimulation: null,
    amountFinanced: null,
    amountPymes: null,
    amountRenta: null,
    amountFacturas: null,
    amountInveriones: null,
    questionId: null,
    routes: null,
    ip:null,
    transferencias:null,
    businesSelected:null,
    businessLink:null,
    showPanelInv:null,
    siiConfigurations:null,
    claveSii:null,
    certificadoSii:null,
    estadoCertificado:null, //"VIGENTE", "POR EXPIRAR", "EXPIRADO"
    openMenuPanel: false,
    miperfil:null,
    miperfil_completo:null,
    token_ip: null,

  },
  mutations: {
    setQuestionId(state, id) {
      state.questionId = id;
    },
    clearQuestionId(state) {
      state.questionId = null;
    },
    hideOrNot(state, newState) {
      state.onlyActives = newState;
    },
    setCurrentInvestment(state, { investmentId, isLastInvestment, proyectId }) {
      state.currentInvestmentId = investmentId;
      state.isLastInvestment = isLastInvestment;
      state.proyectId = proyectId;
    },
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
    setBusiness(state, business) {
      state.business = business;
    },
    setRequesting(state, request) {
      state.requesting = request
    },
    setEmpresasCount(state, count) {
      // @ts-ignore
      state.user.empresas_count = count;
    },
    setExpireTime(state, expireTime) {
      state.expireTime = expireTime;
    },
    deleteSimulation(state) {
      state.lastSimulation = null;
    },
    logout(state) {
      api.logout()
      .then((resolve) =>{
        state.user = null;
        state.token = null;
        state.expireTime = null;
        state.lastSimulation = null;
        state.menu = null;
        state.miperfil = null;
        state.miperfil_completo = null;
      }).catch((error)=>{
        state.user = null;
        state.token = null;
        state.expireTime = null;
        state.lastSimulation = null;
        state.menu = null;
        state.miperfil = null;
        state.miperfil_completo = null;
      })
    
    },
    logAndReconnect(state) {
      state.user = null;
      state.expireTime = null;
      state.lastSimulation = null;
    },
    setGatewayTransaction(state, transaction) {
      state.gatewayTransaction = transaction;
      state.gatewayTransactionConfirmed = false;
      state.transactionIsPendingPayment = false;
    },
    resetGatewayTransactionConfirmation(state) {
      state.gatewayTransactionConfirmed = false;
    },
    setLastCod(state, cod) {
      state.lastCod = cod;
    },
    confirmGatewayTransaction(state) {
      state.gatewayTransactionConfirmed = true;
    },
    setTransactionPendingPayment(state) {
      state.transactionIsPendingPayment = true;
    },
    setTransactionPaymentType(state, id) {
      // @ts-ignore
      state.gatewayTransaction.pago_deta_tipo_id = id;
    },
    setPendingPaymentsIds(state, ids) {
      state.pendingPaymentsIds = ids;
    },
    setTransactionProjectId(state, id) {
      state.transactionProjectId = id;
    },
    setLastSimulation(state, simulation) {
      state.lastSimulation = simulation;
    },
    setAmountFinanced(state, amountFinanced) {
      state.amountFinanced = amountFinanced;
    },
    setAmountDataHome(state, dataHome){
      state.amountFinanced = dataHome.amountFinanced;
      if(dataHome.amountPymes)
        state.amountPymes = dataHome.amountPymes;
      if(dataHome.amountRenta)
        state.amountRenta = dataHome.amountRenta;
    },
    setAmountDataHomeProntoPago(state, dataHome){
      state.amountFinanced = dataHome.amountFinanced;
      if(dataHome.amountFacturas)
        state.amountFacturas = dataHome.amountFacturas;
      if(dataHome.amountInveriones)
        state.amountInveriones = dataHome.amountInveriones;
    },
    setRoutes(state, route) {
      state.routes = route;
    },
    setIp(state,payload){
      state.ip = payload
    },

    setTransferencias(state,payload){
      state.transferencias = payload
    },
    setMenu(state,payload){
      state.menu = payload
    },
    setBusinessSelected(state,payload){
      state.businesSelected = payload
    },
    setBusinessLink(state,payload){
      state.businessLink = payload
    },
    setShowPanelInv(state,payload){
      state.showPanelInv = payload
    },
    setsiiConfigurations(state,payload){
      state.siiConfigurations = payload
    },
    setclaveSii(state,payload){
      state.claveSii = payload
    },
    setcertificadoSii(state,payload){
      state.certificadoSii = payload
    },
    setEstadoCertificadoSii(state, payload){
      state.estadoCertificado = payload;
    },
    setOpenMenuPanel(state, estado){
      state.openMenuPanel = estado;
    },
    setmiperfil(state,payload){
      state.miperfil = payload
    },
    setMiPerfilCompletoInversionista(state,payload){
      state.miperfil_completo = payload;
    },
    setTokenIP(state, token) {
      state.token_ip = token;
    },
  },
  getters: {
    canShowPendingPayments: state => (ids: Array<number>) => {
      return false;
      // @ts-ignore
      // return !(ids.map((id: number) => state.pendingPaymentsIds.includes(id)).every((val) => val == true) && ids.length == state.pendingPaymentsIds.length)
    }
  },
  actions: {}
});
