export default [
  {
    path: 'solicitudes',
    name: 'requests',
    component: () => import('@/views/dashboard/applicant/Requests.vue'),
  },
  {
    path: 'mis-proyectos',
    name: 'my-projects',
    component: () => import('@/views/dashboard/applicant/Projects.vue'),
  },
]