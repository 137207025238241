import store from "@/store";

export const chileanRoutes = (to: any, from: any, next: any) => {
  if (
    process.env.VUE_APP_SELECTED_COUNTRY == "CL" ||
    process.env.VUE_APP_SELECTED_COUNTRY == "PYME"
  ) {
    next();
  } else {
    next("/");
  }
};

export const peruvianRoutes = (to: any, from: any, next: any) => {
  if (process.env.VUE_APP_SELECTED_COUNTRY == "PE") {
    next();
  } else {
    next("/");
  }
};

export const adminRoutes = (to: any, from: any, next: any) => {
  // @ts-ignore
  if (store.state.user != null && store.state.user.user_tipo_id == 1) {
    next();
  } else {
    next("/");
  }
};

export const development = (to: any, from: any, next: any) => {
  // @ts-ignore
  if (process.env.VUE_APP_STATE == 2 || process.env.VUE_APP_STATE == 3) {
    next();
  } else {
    next("/");
  }
};
