import DashboardInvestor from "./routes/dashboard.factoring";
import DashboardApplicant from "@/routes/dashboard.applicant";
import store from "@/store";
import { authenticationRequired } from "./authentication";

export default [
  {
    path: "",
    name: "dashboard-root",
    beforeEnter: (to: any, from: any, next: any) => {
      next({
        name: "dashboard-stats"
      });
    }
  },
  ...DashboardInvestor.map((route: any) => {
    route.path = `${route.path}`;
    route.name = `${route.name}`;
    return route;
  }),
  /*
  ...DashboardInvestor.map((route: any) => {
    route.path = `inversionista/${route.path}`;
    route.name = `investor-${route.name}`;

    return route;
  }),*/
  ...DashboardApplicant.map((route: any) => {
    route.path = `solicitante/${route.path}`;
    route.name = `applicant-${route.name}`;
    return route;
  }),
];
