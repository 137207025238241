import { authenticationRequired } from "@/authentication";
import { adminRoutes } from "@/dynamic.routes";
import RutasActividad from "./rutas.actividad";

export default [
    ...RutasActividad,
    {
        path: "/scoring",
        name: "pruebas-scoring",
        component: () => import("@/views/scoring/ScoringV2.vue"),
        beforeEnter: authenticationRequired
    },{
        path: "/angloamerican",
        name: "pruebas-angloamerican",
        component: () => import("@/views/angloAmerican/AngloAmericanV2.vue"),
        beforeEnter: authenticationRequired
    },{
        path: "/evaluacion",
        name: "scoring",
        component: () => import("@/views/scoring/ScoringV2.vue"),
        beforeEnter: authenticationRequired
    },{
        path: "/admin/asociar",
        name: "asociate",
        component: () => import("@/views/admin/Asociate.vue"),
        beforeEnter: adminRoutes
    },{
        path: "/solicitud-credito",
        name: "credit",
        component: () => import("@/views/RequestCredit.vue"),
        beforeEnter: authenticationRequired
    },{
        path: "/crear-empresa",
        name: "create-business",
        component: () => import("@/views/factoring/NewCreateBusiness.vue"),
        beforeEnter: authenticationRequired
    },{
        path: "/crear-negocio",
        name: "create-negocio",
        component: () => import("@/views/angloAmerican/CreaNegocio.vue"),
        beforeEnter: authenticationRequired
    },{
        path: "/gracias",
        name: "thankyou",
        component: () => import("@/views/ThankYou.vue"),
        beforeEnter: authenticationRequired
    },{
        path: "/lo-sentimos",
        name: "lo-sentimos",
        component: () => import("@/views/Decline.vue"),
        beforeEnter: authenticationRequired
    },{
        path: "/agendar-llamada/:requestId",
        name: "schedule-call",
        component: () => import("@/views/ScheduleCall.vue"),
        beforeEnter: authenticationRequired
    },{
        path: "/ver-proyecto/:projectId",
        name: "view-project",
        component: () => import("@/views/ViewProject.vue"),
        beforeEnter: authenticationRequired,
        props: true
    },{
        path: "/pago-exitoso",
        name: "successful-payment",
        component: () => import("@/views/SuccessfulPayment.vue"),
        beforeEnter: authenticationRequired
    },{
        path: "/pago-erroneo",
        name: "failure-payment",
        component: () => import("@/views/FailurePayment.vue"),
        beforeEnter: authenticationRequired
    },{
        path: "/informar-pago",
        name: "payment-info",
        component: () => import("@/views/ReportPayment.vue"),
        beforeEnter: authenticationRequired
    },{
        path: "/conocerte",
        name: "meet-you",
        component: () => import("@/views/MeetYou.vue"),
        beforeEnter: authenticationRequired
    },{
        path: "/crear-empresa",
        name: "create-business",
        component: () => import("@/views/CreateBusiness.vue"),
        beforeEnter: authenticationRequired
    },{
        path: "/bienvenido",
        name: "welcome",
        component: () => import("@/views/Filter.vue"),
        beforeEnter: authenticationRequired
    },{
        path: "/gracias-por-invertir",
        name: "thanks-for-investing",
        component: () => import("@/views/ThanksForInvesting.vue"),
        beforeEnter: authenticationRequired
    },
]