import Investments from '@/views/dashboard/Investments.vue'
import PendingPayments from '@/views/dashboard/PendingPayments.vue'
import CancelPayments from '@/views/dashboard/CancelPayment.vue'

export default [
  {
    path: 'mis-inversiones',
    name: 'my-investments',
    component: Investments,
  },
  {
    path: 'transferencias-pendientes',
    name: 'pending-payments',
    component: PendingPayments,
  },
  {
    path: 'inversiones-anuladas',
    name: 'cancel-payment',
    component: PendingPayments,
  },
  {
    path: 'estado-pagos',
    name: 'payment-status',
    component: () => import(/* webpackChunkName: "payment-status" */ '@/views/dashboard/PaymentDetails.vue'),
  },
  {
    path: 'cartola-inversiones',
    name: 'payment-mensual',
    component: () => import(/* webpackChunkName: "payment-mensual" */ '@/views/dashboard/CartolaMensual.vue'),
  },
  {
    path: "/cartola-mensual",
    redirect: "cartola-inversiones"
  },
  {
    path: 'detalle-pagos',
    name: 'payment-details',
    component: () => import(/* webpackChunkName: "payment-details" */ '@/views/dashboard/PaymentDetails.vue'),
  },
	//Profile stuffs
  {
    path: 'cuenta-bancaria',
    name: 'bank-account',
    component: () => import(/* webpackChunkName: "bank-account" */ '@/views/dashboard/BankAccount.vue'),
  },
  {
    path: 'pagar-socio-club',
    name: 'pay-membership',
    component: () => import(/* webpackChunkName: "pay-membership" */ '@/views/dashboard/PayMembership.vue'),
  },
  {
    path: 'pago-pendiente/:paymentId',
    name: 'pay-pending-payment',
    component: () => import(/* webpackChunkName: "pay-pending-payment" */ '@/views/dashboard/PayPendingPayment.vue'),
    props: true,
  },
  {
    path: 'detalle-cuotas',
    name: 'fees-details',
    component: () => import(/* webpackChunkName: "pay-pending-payment" */ '@/views/dashboard/FeesDetails.vue'),
  },
  
]