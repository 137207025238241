<template>
	<v-layout class="rc-showLoadingPanel" align-center justify-center>
        <v-flex xs12 text-xs-center>
            <v-progress-circular class="rc-showLoadingPanel__spinner" size="60" width="7" indeterminate style="margin: 1.5rem"></v-progress-circular>
            <p>{{ tituloLoading }}</p>
        </v-flex>
    </v-layout>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	
	@Component({
		props: {
			tituloLoading : String
        },
	})
	export default class ShowLoading extends Vue {}
</script>

<style lang="scss" scoped>
    .rc-showLoadingPanel__spinner{
        color : $rc-prim-n1 !important
    }
	@each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
				.rc-showLoadingPanel{
                    height: calc(100vh - 64px);
                }
			}@else if $breakpoint == "large"{
				.rc-showLoadingPanel{
                    height: calc(100vh - 64px);
                }
			}@else if $breakpoint == "medium-to-large"{
				.rc-showLoadingPanel{
                    height: calc(100vh - 64px);
                }
			}@else if $breakpoint == "small-to-medium"{
			    .rc-showLoadingPanel{
                    height: calc(100vh - 48px);
                }
			}@else if $breakpoint == "small"{
                .rc-showLoadingPanel{
                    height: calc(100vh - 56px);
                }
			}
		}
	}
</style>
