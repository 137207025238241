import { authenticationRequired } from "@/authentication";

export default [{
        path: "/actividad/administracion",
        name: "AdministracionDashboard",
        component: () => import("@/views/actividad/dashboard.vue"),
        beforeEnter: authenticationRequired
    },{
        path: "/actividad/admin-user",
        name: "AdministracionDashboardUser",
        component: () => import("@/views/actividad/dashboarduser.vue"),
        beforeEnter: authenticationRequired
    },{
        path: "/actividad/sabermas",
        name: "sabermas",
        component: () => import("@/views/actividad/Sabermas.vue"),
        beforeEnter: authenticationRequired,
    },{
        path: "/actividad/administrarfinanzas",
        name: "AdministracionOrganiza",
        component: () => import("@/views/actividad/AdministracionActividad1.vue"),
        beforeEnter: authenticationRequired
    },{
        path: "/actividad/administrarcostos",
        name: "AdministracionCostos",
        component: () => import("@/views/actividad/AdministracionActividad2.vue"),
        beforeEnter: authenticationRequired
    },{
        path: "/actividad/administrarfuturo",
        name: "AdministracionFuturo",
        component: () => import("@/views/actividad/AdministracionActividad3.vue"),
        beforeEnter: authenticationRequired
    },{
        path: "/actividad/administrardeuda",
        name: "AdministracionDeuda",
        component: () => import("@/views/actividad/AdministracionActividad4.vue"),
        beforeEnter: authenticationRequired
    },{
        path: "/actividad/organiza/",
        name: "actividad-organiza-tus-finanzas",
        component: () => import("@/views/actividad/OrganizaTusFinanzas.vue"),
        beforeEnter: authenticationRequired,
        props: true
    },{
        path: "/actividad/costos",
        name: "controla-tus-gastos",
        component: () => import("@/views/actividad/ControlaTusCostos.vue"),
        beforeEnter: authenticationRequired,
        props: true
    },{
        path: "/actividad/metasacortoplazo",
        name: "metas-a-corto-plazo",
        component: () => import("@/views/actividad/MetasaCortoPlazo.vue"),
        beforeEnter: authenticationRequired,
        props: true
    },{
        path: "/actividad/gestionatudeuda",
        name: "gestiona-tu-deuda",
        component: () => import("@/views/actividad/GestionaTuDeuda.vue"),
        beforeEnter: authenticationRequired,
        props: true
    },
]