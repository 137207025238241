import { render, staticRenderFns } from "./DniFieldBusiness.vue?vue&type=template&id=f057de7c&scoped=true&"
import script from "./DniFieldBusiness.vue?vue&type=script&lang=ts&"
export * from "./DniFieldBusiness.vue?vue&type=script&lang=ts&"
import style0 from "./DniFieldBusiness.vue?vue&type=style&index=0&id=f057de7c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f057de7c",
  null
  
)

export default component.exports