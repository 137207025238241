class DatatableFilter {
  items: string;
  filters: string;
  filter: string;
  headers: string;
  filterCallbacks: any;

  constructor(items: string, filters: string, filter: string, headers: string) {
    this.items = items;
    this.filters = filters;
    this.filter = filter;
    this.headers = headers;
    this.filterCallbacks = {};
  }

  static updateFilters(filters: string, val: string) {
    return Object.assign({}, filters, val);
  }

  registerFilter(filterName: string, filterCallback: string) {
    this.filterCallbacks[filterName] = filterCallback;
  }

  runFilters() {
    const self = this;
    let filteredItems = self.items;

    Object.entries(this.filterCallbacks).forEach(([entity, cb]: any) => {
      filteredItems = cb.call(self, self.filters[entity], filteredItems);
    });

    return filteredItems;
  }
}

const DatatableFilterPlugin = {
  install(Vue: any, options: any) {
    Vue.prototype.$DatatableFilter = DatatableFilter;
  }
};

export default DatatableFilterPlugin;
