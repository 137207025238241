import DashboardAdministrator from "./routes/dashboard.administrator";
import DashboardApplicant from "@/routes/dashboard.applicant2";
import DashboardInvestor from "@/routes/dashboard.investor";
import DashboardCommon from "@/routes/dashboard.common";
import store from "@/store";
import { authenticationRequired } from "./authentication";

export default [
  {
    path: "",
    name: "dashboard-root",
    beforeEnter: (to: any, from: any, next: any) => {
      next({
        name: "dashboard-stats"
      });
    }
  },
  ...DashboardAdministrator.map((route: any) => {
    route.path = `administrador/${route.path}`;
    route.name = `administrator-${route.name}`;
    route.beforeEnter = (to: any, from: any, next: any) => {
      // @ts-ignore
      if (store.state.user.user_tipo_id == 1) {
        next();
      } else {
        next({
          name: "404"
        });
      }
    };

    return route;
  }),
  ...DashboardInvestor.map((route: any) => {
      route.path = `inversionista/${route.path}`;
      route.name = `investor-${route.name}`;
      return route;   
  }),
  ...DashboardApplicant.map((route: any) => {
    route.path = `solicitante/${route.path}`;
    route.name = `applicant-${route.name}`;
    return route;
  }),
  ...DashboardCommon
];
