import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import { authenticationRequired } from "@/authentication";
import {
  chileanRoutes,
  peruvianRoutes,
  adminRoutes,
  development
} from "@/dynamic.routes";
import Home from "@/views/Home.vue";

import DashboardRoutes from "@/router.dashboard";
import DashboardRoutesFactoring from "@/router.dashboard.factoring";
import RutasPrivadas from "@/routes/rutas.privadas";
import RutasPublicas from "@/routes/rutas.publicas";
import { EventBus } from "@/main";
import { isUserLogin } from "./utils";
import { SelectedCountry, State } from "./configuration";

Vue.use(Router);

const router = new Router({
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? savedPosition : { x: 0, y: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    ...RutasPublicas,
    ...RutasPrivadas,
    {
      path: "/bcp",
      name: "landing-bcp",
      component: () =>
        import(/* webpackChunkName: "landing" */ "@/views/LandingBCP.vue"),
      beforeEnter: peruvianRoutes
    },
    /* {
      path: "/quienes-somos",
      name: "about",
      component: () =>
        import("@/views/About.vue")
    }, */
    {
      path: "/invertir",
      name: "investment",
      component: () =>
        import(
          /* webpackChunkName: "investment-opportunities" */ "@/views/Investment.vue"
        ),
      beforeEnter: (to: any, from: any, next: any) => {
        if (SelectedCountry == "PYME") {
          next("/");
        } else {
          isUserLogin(
            () => {
              next();
            },
            () => {
              if (SelectedCountry == "PE") {
                store.commit("logout");
                next(`/ingresar?return=${to.path}`);
              } 
              else {
                next();
              }
            }
          );
        }
      }
    },
    {
      path: "/prontopago",
      name:"prontopago",
      redirect: (to)=>{
        window.location.href = 'https://prontopago.redcapital.cl/';
      }
    },
    ...DashboardRoutes.map(route => {
      route.path = `/panel/${route.path}`;
      route.name = `dashboard-${route.name}`;
      route.beforeEnter = authenticationRequired;
      return route;
    }),
    ...DashboardRoutesFactoring.map(route => {
      route.path = `/panel-facto/${route.path}`;
      route.name = `dashboard-factoring-${route.name}`;
      route.beforeEnter = authenticationRequired;
      return route;
    }),
    {
      path: "/404",
      name: "404",
      component: () => import("@/views/404New.vue")
    },
    {
      path: "*",
      name: "not-found",
      redirect: "/404"
    },
    {
      path: "/mantenimiento",
      name: "mantenimiento",
      component: () => import("@/views/404-mantainNew.vue"),
      // beforeEnter: development
    },
    {
      path: "/estamos-trabajando",
      name: "trabajando",
      component: () => import("@/views/404-working.vue"),
      beforeEnter: development
    },
    

    
    /*  {
      path: "/design-system-redcapital",
      name: "dsrc",
      component: () => import("@/views/designRc/designSystem.vue"),
      beforeEnter: chileanRoutes
    } */ 
  ]
});

// router.afterEach((to, from) => {
//   if (State == 2) {
//     if (to.path != "/mantenimiento") {
//       router.push({ path: "/mantenimiento" });
//     }
//   }
//   if (State == 3) {
//     if (to.path != "/estamos-trabajando") {
//       router.push({ path: "/estamos-trabajando" });
//     }
//   }
// });

router.afterEach((to, from) => {
  if(to.fullPath.indexOf('/panel') == 0){
    store.commit("setShowPanelInv",true)
  } else {
    store.commit("setShowPanelInv",false)
  }
  store.commit("setRoutes", { to: to.path, from: from.path });
});

const r = router;
// @ts-ignore

function getRoutesList(routes: any, pre: any) {
  return routes.reduce((array: any, route: any) => {
    const path = `${pre}${route.path}`;

    if (route.path !== "*") {
      array.push(path);
    }

    if (route.children) {
      array.push(...getRoutesList(route.children, `${path}/`));
    }
    return array;
  }, []);
}

export default router;
