<template>
	<div class="dashboard-navbar">
		<!-- Menu Btn -->
		<v-layout wrap row align-start justify-start style="position:fixed;z-index:1;margin: 10px">
			<v-flex xs12 @click="onMenu()">
				<v-icon style="cursor: pointer; color: #ff8a00">menu</v-icon>
			</v-flex>
		</v-layout>
		<!-- List Menu -->
    	<v-navigation-drawer id="rc-menu-solicitante" v-model="drawer" fixed hide-overlay class="rc-menu-solicitante-drawer" style="margin-top:64px;z-index:1; width:200px; height: calc( 100vh - 64px ) !important; min-height: 100%">
			<v-list class="transparent pa-2" v-if="user.via_id != 2">
        		<!-- Mi Panel -->
				<v-list-tile :active-class="activeClass" to="/panel-facto/estadisticas" class="v-list-item rc-list-item rc-listItem-pad">
          			<div style="text-align: center; width: 100%; line-height: 1;">
						<img class="rc-listItem-img" src="/images/panel/solicitante/ico_miPanel.svg" height="26px" width="26px" alt="Mi Panel">
						<div class="rc-listItem-title">Mi panel</div>
					</div>
        		</v-list-tile>
				<!-- Anticipar Facturas -->
				<v-list-tile :active-class="activeClass" to="/panel-facto/anticipo-facturas" class="v-list-item rc-list-item rc-listItem-pad">
          			<div style="text-align: center; width: 100%; line-height: 1;">
						<img class="rc-listItem-img" src="/images/panel/solicitante/ico_anticiparFacturas.svg" height="26px" width="26px" alt="Anticipar Facturas">
						<div class="rc-listItem-title">Anticipar Facturas</div>
					</div>
        		</v-list-tile>
				<!-- Ceder Facturas -->
				<v-list-tile :active-class="activeClass" to="/panel-facto/ceder-facturas" class="v-list-item rc-list-item rc-listItem-pad">
          			<div style="text-align: center; width: 100%; line-height: 1;">
						<img class="rc-listItem-img" src="/images/panel/solicitante/ico_cederFacturas.svg" height="26px" width="26px" alt="Ceder Facturas">
						<div class="rc-listItem-title">Ceder Facturas</div>
					</div>
        		</v-list-tile>
				<!-- Mis Operaciones -->
				<v-list-tile :active-class="activeClass" to="/panel-facto/mis-operaciones" class="v-list-item rc-list-item rc-listItem-pad">
          			<div style="text-align: center; width: 100%; line-height: 1;">
						<img class="rc-listItem-img" src="/images/panel/solicitante/ico_misOperaciones.svg" height="26px" width="26px" alt="Mis operaciones">
						<div class="rc-listItem-title">Mis operaciones</div>
					</div>
        		</v-list-tile>
				<!-- Mis datos -->
				<v-list-tile :active-class="activeClass" to="/panel-facto/cuenta-banco" class="v-list-item rc-list-item rc-listItem-pad">
          			<div style="text-align: center; width: 100%; line-height: 1;">
						<img class="rc-listItem-img" src="/images/panel/solicitante/ico_misDatos.svg" height="26px" width="26px" alt="Mi datos">
						<div class="rc-listItem-title">Mis datos</div>
					</div>
        		</v-list-tile>
				<!-- Mis empresas -->
				<v-list-tile :active-class="activeClass" to="/panel-facto/empresas" class="v-list-item rc-list-item rc-listItem-pad">
          			<div style="text-align: center; width: 100%; line-height: 1;">
						<img class="rc-listItem-img" src="/images/panel/solicitante/ico_misEmpresas.svg" height="26px" width="26px" alt="Mi empresas">
						<div class="rc-listItem-title">Mis empresas</div>
					</div>
        		</v-list-tile>
				<!-- Mis documentos -->
				<v-list-tile :active-class="activeClass" to="/panel-facto/documentos" class="v-list-item rc-list-item rc-listItem-pad">
          			<div style="text-align: center; width: 100%; line-height: 1;">
						<img class="rc-listItem-img" src="/images/panel/solicitante/ico_misDocumentos.svg" height="26px" width="26px" alt="Mi documentos">
						<div class="rc-listItem-title">Mis documentos</div>
					</div>
        		</v-list-tile>
				<!-- Mis Finanzas -->
				<v-list-tile :active-class="activeClass" to="/panel-facto/finanzas" class="v-list-item rc-list-item rc-listItem-pad">
          			<div style="text-align: center; width: 100%; line-height: 1;">
						<img class="rc-listItem-img" src="/images/panel/solicitante/ico_misFinanzas.svg" height="26px" width="26px" alt="Mi Finanzas">
						<div class="rc-listItem-title">Mis Finanzas</div>
					</div>
        		</v-list-tile>
			</v-list>

			<v-list class="transparent pa-2" v-if="user.via_id == 2">
				<!-- Mi Panel -->
				<v-list-tile :active-class="activeClass" to="/panel-facto/estadisticas" class="v-list-item rc-list-item rc-listItem-pad">
          			<div style="text-align: center; width: 100%; line-height: 1;">
						<img class="rc-listItem-img" src="/images/panel/solicitante/ico_miPanel.svg" height="26px" width="26px" alt="Mi Panel">
						<div class="rc-listItem-title">Mi panel</div>
					</div>
        		</v-list-tile>
				<!-- Mis Solicitudes -->
				<v-list-tile :active-class="activeClass" to="/panel-facto/solicitante/solicitudes" class="v-list-item rc-list-item rc-listItem-pad">
          			<div style="text-align: center; width: 100%; line-height: 1;">
						<img class="rc-listItem-img" src="/images/panel/solicitante/ico_miSolicitudes.svg" height="26px" width="26px" alt="Mis Solicitudes">
						<div class="rc-listItem-title">Mis Solicitudes</div>
					</div>
        		</v-list-tile>
				<!-- Mis datos -->
				<v-list-tile :active-class="activeClass" to="/panel-facto/cuenta-banco" class="v-list-item rc-list-item rc-listItem-pad">
          			<div style="text-align: center; width: 100%; line-height: 1;">
						<img class="rc-listItem-img" src="/images/panel/solicitante/ico_misDatos.svg" height="26px" width="26px" alt="Mis datos">
						<div class="rc-listItem-title">Mis datos</div>
					</div>
        		</v-list-tile>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import API from "@/api";
import {
  MyDocuments,
  Profile,
  Profile2,
  NewMenus,
  Menus,
  MyBusiness,
  Legal,
  Anticipo,
  Finanzas
} from "@/models/DashboardFactoringLinks";
import { EventBus } from "@/main";

@Component({
  computed: {
    ...mapState(["user","openMenuPanelSolicit"]),
    activeClass() {
      return "rc-menuSolicitante-active";
    },
    accountType() {
      return this.$store.state.user.user_tipo_id == 2
        ? "investor"
        : "applicant";
    },
    showtable(){
        return this.$store.state.user.id == 6984 || this.$store.state.user.id == 28 || this.$store.state.user.id == 167 || this.$store.state.user.id == 274 || this.$store.state.user.id == 123;
    }
  },
  data() {
    return {
      title: "Dashboard Factoring",
      mini: true,
      darkMode: true,
      MyBusiness,
      Menus,
      Profile,
      Profile2,
      MyDocuments,
      Legal,
      Anticipo,
      Finanzas,
      mini: true,
      right: null,
      canShowMenu: false,
      drawer: false,
      responsive: false,
      tooltip: false,
      user_id:null,
      drawer: true,
        items: [
          { title: 'Home', icon: 'dashboard' },
          { title: 'About', icon: 'question_answer' }
        ],
        links: ['Home', 'Contacts', 'Settings'],
        mini: true,
        right: null
    };
  },
  watch: {
    $route(val) {
      // @ts-ignore
      this.changeTitle();
    }
  },
  async beforeMount() {
    // @ts-ignore
    this.changeTitle();
    this.$data.user_id = this.$store.state.user.id;
  },
  mounted() {
    // @ts-ignore
    this.onResponsive();
    // @ts-ignore
    window.addEventListener("resize", this.onResponsive);
  },
  beforeDestroy() {
    // @ts-ignore
    window.removeEventListener("resize", this.onResponsive);
  },
  methods: {
    alert(v){
      alert(v)
    },
    onResponsive() {
		if (window.innerWidth >= 1264){
		  	this.hasClassMenu();
		  	this.drawer = true;
			this.$store.commit("setopenMenuPanelSolicit", true);
		}
		if (window.innerWidth < 960) {
			this.$data.responsive = true;
		} else {
			this.$data.responsive = false;
		}
    },
    changeTitle() {
      this.$data.title = this.$route.name;
    },
    logout() {
      EventBus.$emit("logout");
    },
	onMenu(){
		//console.log(this.$store.state.openMenuPanelInvest);
		if(this.$data.drawer){
			this.drawer = false;
			this.$store.commit("setopenMenuPanelSolicit", false);
		}else{
			this.drawer = true;
			this.$store.commit("setopenMenuPanelSolicit", true);
		}
	},
	hasClassMenu(){
		if(document.getElementById('rc-menu-solicitante').className.match(/\bv-navigation-drawer--open\b/)){
			this.drawer = true;
			this.$store.commit("setopenMenuPanelSolicit", true);
		}else{
			this.drawer = false;
			this.$store.commit("setopenMenuPanelSolicit", false);
		}
	}
  }
})
export default class NavBarFactoring extends Vue {}
</script>

<style lang="scss" scope>
	
	
</style>
