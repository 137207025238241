<template>
	<div>
		<v-snackbar
			:class="tipo ? 'rc-alerta__snack-ok' : 'rc-alerta__snack-bad'"
			v-model="showSnack" 
			:timeout="timeout" 
		>
			<div>
				<span v-if="tipo" class="material-icons">check_circle_outline</span>
				<span v-else class="material-icons">highlight_off</span>
				<span>{{ mensaje }}</span>
				<v-icon v-if="isLoading" class="spin" right dark>cached</v-icon>
			</div>
			
			<v-btn
				round
				flat
				@click="showSnackChild"
			>
				Cerrar
			</v-btn>
		</v-snackbar>
	</div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	
	@Component({
		props: {
			showSnack : Boolean,
			tipo : Boolean,
			mensaje : String,
			isLoading : Boolean // para la carga de datos
		},
		data(){
			return{
				timeout: 5000,
			}
		},
		methods:{
			showSnackChild(){
				this.$emit('showSnackParent');
			}
		}
	})
	export default class AlertasSnack extends Vue {}
</script>

<style lang="scss" scoped>
	.rc-showLoadingPanel__spinner{
		color : $rc-prim-n1 !important
	}
	@each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
				.rc-showLoadingPanel{
					height: calc(100vh - 64px);
				}
			}@else if $breakpoint == "large"{
				.rc-showLoadingPanel{
					height: calc(100vh - 64px);
				}
			}@else if $breakpoint == "medium-to-large"{
				.rc-showLoadingPanel{
					height: calc(100vh - 64px);
				}
			}@else if $breakpoint == "small-to-medium"{
				.rc-showLoadingPanel{
					height: calc(100vh - 48px);
				}
			}@else if $breakpoint == "small"{
				.rc-showLoadingPanel{
					height: calc(100vh - 56px);
				}
			}
		}
	}
</style>
