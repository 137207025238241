<template>
    <nav class="navinfo">
        <div class="navinfo-body">
            <v-icon class="navinfo-icon-left">warning</v-icon>
            <div class="navinfo-content">
                <div v-html="mensaje"></div>
            </div>
            <v-icon class="navinfo-icon-right" @click="cerrarInfo">close</v-icon>
        </div>
    </nav>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	
	@Component({
		methods: {
            cerrarInfo(){
                this.$emit('cerrar-info', true);
            }
        },
        props: {
			mensaje : String
        }
	})
	export default class NavInfo extends Vue {}
</script>

<style lang="scss" scoped>
    .navinfo-hide{
		display: none;
	}
    .navinfo{
        position: sticky;
        top: 0;
        background-color: #FFF3E2;
        width: 100%;
        z-index: 3;
        .navinfo-body{
            display: flex;
            justify-content: space-between;
            // min-height: 64px !important;
            padding: 0.5rem 1.5rem !important;
        }
    }
    .navinfo-content{
        width: 100%;
        color: #3D3D3D;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        p{
            margin:0;
        }
    }
    .navinfo-icon-left{
        color: #FF8A00;
        margin-right: 20px;
        align-self: center;
    }
    .navinfo-icon-right{
        color: #FF8A00;
        margin-left: 20px;
        align-self: center;
        &:hover{
            cursor: pointer;
        }
    }
</style>
