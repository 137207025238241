<template>
	<div class="rc-button-tabs-dashboard" v-if="user != null && showPanelInv != false">
		<v-btn v-if="user != null && user.user_tipo_id == 1" flat small @click="dark = !dark"></v-btn>
		<v-btn class="pa-1 rc-estilosbtn elevation-3" :class="menu == 3 ? 'activa elevation-2' : 'rc-buttonPanel'" :color="menu == 3 ? 'btnsitios2' : ''" @click="panelFacture()" style="margin: 0 !important; margin-right:10px">
			<span class="text-capitalize">Solicitante</span>
		</v-btn>
		<v-btn class="pa-1 rc-estilosbtn elevation-3" :class="menu == 2 ? 'activa elevation-2' : 'rc-buttonPanel'" :color="menu == 2 ? 'btnsitios2' : ''" @click="panelInversionist()" style="margin: 0 !important; margin-right:1px">
			<span class="text-capitalize ">Inversionista</span>
		</v-btn>
	</div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	import { mapState } from "vuex";
	
	@Component({
		computed: {
    		...mapState(["user", "menu", "showPanelInv"])
		},
		data() {
			return {
			dark: true,
			};
		},
		methods: {
			panelInversionist() {
				this.$store.commit("setMenu", 2);
				this.$router.push("/panel/estadisticas");
			},
			panelFacture() {
				this.$store.commit("setMenu", 3);
				this.$router.push("/panel-facto/estadisticas");
			},
		},
	})
	export default class ButtonPanel extends Vue {}
</script>

<style lang="scss" scoped>
	// botones solicitante e inversionista
	.activa {
		color: white;
	}
	.rc-buttonPanel {
		background-color: $rc-other3 !important;
		color: $rc-escala5;
	}
	.rc-estilosbtn{
		width: 150px;
		height: 50px;
		margin-top: 0px;
		border-bottom-left-radius: 25px;
		border-bottom-right-radius: 25px;
	}
	.rc-button-tabs-dashboard{
		position:absolute; 
		right:0; 
		margin-top:-2px;
	}
	// Breakpoints 
	@each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
				
			}@else if $breakpoint == "large"{
				
			}@else if $breakpoint == "medium-to-large"{
				
			}@else if $breakpoint == "small-to-medium"{
			
			}@else if $breakpoint == "small"{
				
			}
		}
	}
</style>
