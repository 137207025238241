<template>
  <div class="footer">
    <div class="hidden-sm-and-down" style="min-height: 340px;display: flex;flex-wrap: wrap;align-content: center;">
      <v-container class="rc-padding fluid">
        <v-layout row wrap>
          <v-flex xs12 mb-3>
             <h2 class="white--text">Contacto</h2>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs4>
            <v-layout  row wrap>
              <v-flex xs2>
                <i class="material-icons big-icon white--text">email</i>
              </v-flex>
              <v-flex xs10 pt-2>
                <a class="white--text subheading font-weight-thin" :href="`mailto:${RedCapital[SelectedCountry].Contact.Mail}`">
                  {{ RedCapital[SelectedCountry].Contact.Mail }}
                </a>
              </v-flex>
              <v-flex xs2>
                <i class="material-icons big-icon white--text">local_phone</i>
              </v-flex>
              <v-flex xs10 pt-2>
                <a class="white--text subheading font-weight-thin" :href="`tel:${RedCapital[SelectedCountry].Contact.Phone}`">
                  {{ RedCapital[SelectedCountry].Contact.Phone }}
                </a>
                <span class="white--text subheading font-weight-thin"> / </span>
                <a v-if="RedCapital[SelectedCountry].Contact.Phone2 != null" class="white--text subheading font-weight-thin" :href="`tel:${RedCapital[SelectedCountry].Contact.Phone2}`">
                  {{ RedCapital[SelectedCountry].Contact.Phone2 }}
                </a>
              </v-flex>
              <v-flex  xs2>
                <i class="material-icons big-icon white--text">place</i>
              </v-flex>
              <v-flex xs10 pt-2>
                <a class="white--text subheading font-weight-thin" rel="noreferrer" :href="`${RedCapital[SelectedCountry].Contact.AddressLink}`" target="_blank">{{ RedCapital[SelectedCountry].Contact.AddressName }}</a>
              </v-flex>
              <v-flex  xs2>
                <i class="material-icons big-icon white--text">access_time</i>
              </v-flex>
              <v-flex xs10 pt-2>
                <a class="white--text subheading font-weight-thin" rel="noreferrer" :href="`${RedCapital[SelectedCountry].Contact.AddressLink}`" target="_blank">{{ RedCapital[SelectedCountry].Contact.Timetable }} </a>
                <span  class="white--text subheading font-weight-thin"> / </span>
                <a class="white--text subheading font-weight-thin" rel="noreferrer" :href="`${RedCapital[SelectedCountry].Contact.AddressLink}`" target="_blank">{{ RedCapital[SelectedCountry].Contact.Timetable2 }}</a>
              </v-flex>
          </v-layout>
          </v-flex>
          <v-flex xs4>
            <v-layout column class="subheading font-weight-thin">
              <v-flex xs12 pb-1 v-for="link in mobile" :key="link.text" class="text-xs-center">
                <v-hover>
                  <router-link slot-scope="{ hover }" :class="{ [`${hover ? 'primary' : 'white'}--text`]: true }" :to="link.to">
                    {{ link.text }}
                  </router-link>
                </v-hover>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs4>
             <p class="body-2 font-weight-thin white--text px-4 justify">
              A través de RedCapital los inversionistas financian a un Pyme a
              través de préstamo o compra de facturas. Si bien hay una tasa fijada
              con anticipación - Renta Fija - existen riesgos asociados y las
              fechas de pagos anunciadas son estimadas por lo que podrían varíar.
              Recomendamos revisar en detalle "<router-link class="font-weight-regular" to="/garantia-riesgos">Respaldos y Riesgos</router-link>"
            </p>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    
    
    <!-- Footer Desktop -->
    <v-container class="rc-padding fluid hidden-sm-and-down hidden-sm-and-down rc-footer_end">
      <v-layout wrap row text-xs-center justify-center ma-1>
        <v-flex xs2 style="text-align:left">
          <a href="https://www.linkedin.com/company/redcapital-chile" target="_blank"
            class="white--text px-1 pt-2"
            ><img
              v-lazy="'/images/linkedin.svg'" class="mt-2"
              style="filter: invert(1);"
              alt="RedCapital Chile"
              width="24px"
              height="24px"
          /></a>
        </v-flex>
        <v-flex xs8>
          <p class="subheading font-italic font-weight-thin mb-0 pa-2">
            RedCapital, Todos los derechos reservados {{currentTime.getFullYear()}}
          </p>
        </v-flex>
        <v-flex xs2 style="text-align:right">
          <a href="https://redcapital.com.co" class="black--text pa-2"
            ><img
              v-lazy="'/images/colombia.png'"
              class="mt-2"
              alt="RedCapital Colombia"
              width="24px"
              height="24px"
          /></a>
          <a href="https://redcapital.pe" class="black--text pa-2"
            ><img
              v-lazy="'/images/peru.png'"
              class="mt-2"
              alt="RedCapital Peru"
              width="24px"
              height="24px"
          /></a>
          <a href="https://redcapital.cl" class="white--text pa-2"
            ><img
              v-lazy="'/images/chile.png'"
              class="mt-2"
              alt="RedCapital Chile"
              width="24px"
              height="24px"
          /></a>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- / Footer Desktop -->
    <!-- Footer Mobile -->
    <v-container class="rc-padding fluid hidden-md-and-up">
        <div style="min-height:300px;display:flex;flex-wrap:wrap;">
          <div style="align-self:center;width:100%;text-align:">
            <v-layout row wrap>
              <v-flex xs12 mb-3>
                <h2 class="white--text">Contacto</h2>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12>
                <v-layout  row wrap>
                  <v-flex xs2>
                    <i class="material-icons big-icon white--text">email</i>
                  </v-flex>
                  <v-flex xs10 pt-2>
                    <a class="white--text subheading font-weight-thin" :href="`mailto:${RedCapital[SelectedCountry].Contact.Mail}`">
                      {{ RedCapital[SelectedCountry].Contact.Mail }}
                    </a>
                  </v-flex>
                  <v-flex xs2>
                    <i class="material-icons big-icon white--text">local_phone</i>
                  </v-flex>
                  <v-flex xs10 pt-2>
                    <a class="white--text subheading font-weight-thin" :href="`tel:${RedCapital[SelectedCountry].Contact.Phone}`">
                      {{ RedCapital[SelectedCountry].Contact.Phone }}
                    </a>
                    <span class="white--text subheading font-weight-thin"> / </span>
                    <a v-if="RedCapital[SelectedCountry].Contact.Phone2 != null" class="white--text subheading font-weight-thin" :href="`tel:${RedCapital[SelectedCountry].Contact.Phone2}`">
                      {{ RedCapital[SelectedCountry].Contact.Phone2 }}
                    </a>
                  </v-flex>
                  <v-flex  xs2>
                    <i class="material-icons big-icon white--text">place</i>
                  </v-flex>
                  <v-flex xs10 pt-2>
                    <a class="white--text subheading font-weight-thin" rel="noreferrer" :href="`${RedCapital[SelectedCountry].Contact.AddressLink}`" target="_blank">{{ RedCapital[SelectedCountry].Contact.AddressName }}</a>
                  </v-flex>
                  <v-flex  xs2>
                    <i class="material-icons big-icon white--text">access_time</i>
                  </v-flex>
                  <v-flex xs10 pt-2>
                    <a class="white--text subheading font-weight-thin" rel="noreferrer" :href="`${RedCapital[SelectedCountry].Contact.AddressLink}`" target="_blank">{{ RedCapital[SelectedCountry].Contact.Timetable }} </a>
                    <span  class="white--text subheading font-weight-thin"> / </span>
                    <a class="white--text subheading font-weight-thin" rel="noreferrer" :href="`${RedCapital[SelectedCountry].Contact.AddressLink}`" target="_blank">{{ RedCapital[SelectedCountry].Contact.Timetable2 }}</a>
                  </v-flex>
              </v-layout>
              </v-flex>
            </v-layout>
          </div>
        </div>
        
        <v-layout row wrap>
          <v-flex xs12>
            <v-layout column class="subheading font-weight-thin">
              <v-flex xs12 pb-1 v-for="link in mobile" :key="link.text" class="text-xs-center">
                <v-hover>
                  <router-link slot-scope="{ hover }" :class="{ [`${hover ? 'primary' : 'white'}--text`]: true }" :to="link.to">
                    {{ link.text }}
                  </router-link>
                </v-hover>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <div style="min-height:180px;display:flex;flex-wrap:wrap;">
          <div style="align-self: center;width: 100%;text-align: center;">
            <v-layout row wrap>
              <v-flex xs12>
              <a href="https://www.linkedin.com/company/redcapital-chile" target="_blank"
                class="px-2 pt-2"
                ><img
                  v-lazy="'/images/linkedin-N.svg'" class="mt-2"
                  alt="RedCapital Chile"
                  width="54px"
                  height="44px"
              /></a>
              </v-flex>
            </v-layout>
          </div>
        </div>
      </v-container>
    <!-- / Footer Mobile -->
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import { SelectedCountry, RedCapital } from "@/configuration";

@Component({
  data() {
    return {
      SelectedCountry,
      RedCapital,
      currentTime:  new Date(),
      desktop: [
        {
          to: "contacto",
          text: "Contacto"
        },
        {
          to: "quienes-somos",
          text: "Sobre Nosotros"
        },
        {
          to: "preguntas-frecuentes",
          text: "Preguntas Frecuentes "
        },
        {
          to: "garantia-riesgos",
          text: "Respaldos y Riesgos"
        },
        {
          to: "/",
          text: "Terminos y condiciones"
        },
        {
          to: "/garantia-riesgos",
          text: "Respaldos y Riesgos"
        },
        {
          to: "/politica-privacidad",
          text: "Políticas de privacidad"
        },
        {
          to: "/protocolo-cobranza",
          text: "Protocolo de cobranza"
        }
      ],
      mobile: [
        {
          to: "/quienes-somos",
          text: "Sobre Nosotros"
        },
        {
          to: "/preguntas-frecuentes",
          text: "Preguntas Frecuentes "
        },
        {
          to: "/contacto",
          text: "Contáctanos"
        },
        {
          to: "/garantia-riesgos",
          text: "Respaldos y Riesgos"
        },
        {
          to: "/politica-privacidad",
          text: "Políticas de privacidad"
        },
        {
          to: "/protocolo-cobranza",
          text: "Protocolo de cobranza"
        }
      ]
    };
  }
})
export default class Footer extends Vue {}
</script>

<style lang="scss">
.rc-footer_end{
  background-color: $rc-footer-color1 !important;
}
.rc-footer_list{
  background-color: $rc-footer-color2 !important;
  div, div{
    padding-left: 0;
    div:first-child, i{
      margin: 0;
    }
    div:last-child, div{
      overflow: none;
      text-overflow: none;
      white-space: normal;
    }
  }
}
.rc-rrss{
  color: $rc-prim-n1 !important;
}
.footer {
  background-color: $rc-footer-color2 !important;
  padding: 0 0px;
  p {
    color: #fff;
  }
  a {
    text-decoration: none;
    color: #b2b2b2;
  }
  i {
    color: #9d9d9c;
    margin: 0 5px;
  }
  a:hover {
    color: #da5a38;
  }
  li {
    list-style: none;
  }
  ul {
    padding: 0;
  }
  .footer-title {
    font-size: 15px;
    font-weight: 400;
  }
  .rrss {
    color: #fff;
    margin: 0 5px;
  }
  .rrss:hover {
    color: #da5a38;
  }
  .outlined .v-input__slot {
    padding-left: 20px !important;
  }
  .big-icon {
    font-size: 1.7em;
    margin-right: 10px;
    margin-top: 9px;
  }
}
</style>
