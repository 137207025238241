<template>
  <div class="public-navbar">
    <v-toolbar v-resize="onResize" dark fixed app clipped-left
      :color="dark ? 'active-menu' : 'black'"
      :class="
        user != null && showPanelInv != false
          ? { [`pt-${isMobile ? 0 : 0}`]: true }
          : { [`pt-${isMobile ? 0 : 0}`]: true }
      "
    >
      <!-- Menu Movil -->
      <v-toolbar-side-icon v-if="isMobile" alt="Mostrar menu" aria-label="Menu" @click="showMenu = true"></v-toolbar-side-icon>
      <router-link to="/" style="margin: auto;padding-right: 36px;">
        <img class="rclogo" alt="RedCapital logo" :src="`${RedCapital[SelectedCountry].Logo}`"/>
      </router-link>
      <img v-if="user != null && showPanelInv != false" class="invlogo" alt="RedCapital logo" :src="urlimag"/>
      <!-- Menu Desk -->
      <v-toolbar-items v-if="!isMobile" style="width:100%"> 
        <!-- Menu:  Invertir -->
        <v-btn v-if=" !valroutesfacto && projectsVisibility && SelectedCountry != 'PYME'" v-show="!userAnglo" class="font-OneRem" style="text-transform:capitalize;" to="/invertir" flat>
          Invertir
        </v-btn>
        <v-btn v-if="user == null" class="font-OneRem" style="text-transform:capitalize" to="/como-funciona-solicitar" flat>
          Financiarte
        </v-btn>
        <v-btn v-if="user != null && user.via_id != 2" class="font-OneRem" style="text-transform:capitalize" to="/panel-facto/estadisticas" flat>
          Financiarte
        </v-btn>
        <v-btn v-if="user != null && user.via_id == 2" class="font-OneRem" style="text-transform:capitalize" to="/angloamerican" flat>
          Financiarte
        </v-btn>
        <!-- Menu:  Como Funciona -->
        <v-menu v-if="SelectedCountry == 'CL' || SelectedCountry == 'PYME'" offset-y open-on-hover>
          <template v-slot:activator="{ on }">
            <v-btn class="ml-0 nav-1000 hidden-sm-and-down font-OneRem" style="text-transform:capitalize" flat v-on="on">
              Cómo funciona
              <v-icon>expand_more</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-tile :to="{ name: 'comofuncionainvertir' }">
              <v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" style="text-transform:capitalize" flat>
                Inversiones
              </v-list-tile-title>
            </v-list-tile>
            <v-list-tile :to="{ name: 'comofuncionasolicitar' }">
              <v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" style="text-transform:capitalize" flat>
                Financiamiento
              </v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
        <!-- Menu: Productos  -->
        <v-menu v-if="SelectedCountry == 'CL' || SelectedCountry == 'PYME'" offset-y open-on-hover>
          <template v-slot:activator="{ on }">
            <v-btn class="ml-0 nav-1000 hidden-sm-and-down font-OneRem" style="text-transform:capitalize" flat v-on="on">
              Productos
              <v-icon>expand_more</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-tile :to="{ name: 'homeprontopago' }">
              <v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" style="text-transform:capitalize" flat>
                Prontopago
              </v-list-tile-title>
            </v-list-tile>
            <v-list-tile @click="goblog">
              <v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" style="text-transform:capitalize" flat>
                Blog
              </v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
        <!-- Menu:  Nosotros -->
        <v-menu v-if="SelectedCountry == 'CL' || SelectedCountry == 'PYME'" offset-y open-on-hover>
          <template v-slot:activator="{ on }">
            <v-btn class="ml-0 nav-1000 hidden-sm-and-down font-OneRem" style="text-transform:capitalize" flat v-on="on">
              Nosotros
              <v-icon>expand_more</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-tile to="/quienes-somos">
              <v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" style="text-transform:capitalize" flat>
                ¿Quiénes somos?
              </v-list-tile-title>
            </v-list-tile>
            <v-list-tile to="/quienes-somos#testimonios">
              <v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" style="text-transform:capitalize" flat>
                Testimonios
              </v-list-tile-title>
            </v-list-tile>
            <!-- <v-list-tile to="">
              <v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat style="text-transform:capitalize">
                Blog
              </v-list-tile-title>
            </v-list-tile> --> 
            <v-list-tile to="/prensa">
              <v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" style="text-transform:capitalize" flat>
                En Prensa
              </v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
        <!-- Menu:  Ayuda -->
        <v-menu v-if="SelectedCountry == 'CL' || SelectedCountry == 'PYME'" offset-y open-on-hover>
          <template v-slot:activator="{ on }">
            <v-btn class="ml-0 nav-1000 hidden-sm-and-down font-OneRem" style="text-transform:capitalize" flat v-on="on"
              >Ayuda<v-icon>expand_more</v-icon></v-btn
            >
          </template>
          <v-list>
            <v-list-tile to="/preguntas-frecuentes">
              <v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat style="text-transform:capitalize"
                >Preguntas Frecuentes</v-list-tile-title
              >
            </v-list-tile>
            <v-list-tile to="/garantia-riesgos">
              <v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat
                >Respaldos y Riesgos</v-list-tile-title
              >
            </v-list-tile>
            <v-list-tile to="/contacto">
              <v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat style="text-transform:capitalize"
                >contacto</v-list-tile-title
              >
            </v-list-tile>
          </v-list>
        </v-menu>
        <v-spacer></v-spacer>
        <!-- <v-menu offset-y v-if="user == null"> -->
        <v-btn v-if="user != null && user.user_tipo_id == 1" to="/admin/asociar" flat>Controlar cuenta</v-btn>
        <v-btn v-if="user == null" 
               class="ml-2 " 
               style="text-transform:capitalize; height: auto; padding: 5px 17px; font-size:1rem !important; background-color: #D04B14 !important"
               outline
               round
               color="white"
               dark
               v-on="on"
              @click="golog"
        >
          Prontopago 
        </v-btn>
        <v-btn v-if="user == null" 
               class="ml-2 " 
               style="text-transform:capitalize;height: auto;padding: 5px 17px;font-size:1rem !important"
               outline
               round
               color="white"
               dark
               v-on="on"
               to="/registrar"
        >
          Registrarse 
        </v-btn>
        <v-btn v-if="user == null" 
               class="ml-2 "
               style="text-transform:capitalize;height: auto;padding: 5px 17px;background: #ec6b1c !important;font-size:1rem !important"
               outline
               round
               color="white"
               dark
               v-on="on"
               to="/login"
        >
          Ingresar
        </v-btn>

          <v-menu offset-y v-if="user != null">
          <template v-slot:activator="{ on }">
            <v-btn
              class="ml-0 nav-1000 hidden-sm-and-down"
              v-if="user != null"
              flat
              v-on="on"
              >{{ user.nombre }} {{ user.app_pat }}
              <v-icon>expand_more</v-icon></v-btn
            >
          </template>
          <v-list>
            <v-list-tile v-if="(menu == 2 || user.user_tipo_id != 2) && $route.path != '/evaluacion' && user.via_id != 2 " to="/invertir">
              <v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat>Invertir</v-list-tile-title>
            </v-list-tile>
            <v-list-tile v-if="menu == 2 || user.user_tipo_id != 3"
              @click="panelInversionist()"
            
            >
            
              <v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat
                >Panel Inversionista</v-list-tile-title
              >
            </v-list-tile>
            <v-list-tile v-if="menu == 3 && user.user_tipo_id != 2"
              @click="panelFacture()" >
              <v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat
                >Panel Solicitante</v-list-tile-title
              >
            </v-list-tile>
            <v-list-tile v-if="menu == 2 || user.user_tipo_id != 3"
              to="/panel/inversionista/mis-inversiones"
            >
              <v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat
                >Resumen inversiones</v-list-tile-title
              >
            </v-list-tile>
            <v-list-tile v-if="menu == 3 && user.user_tipo_id != 2 && user.via_id != 2"
              to="/panel-facto/solicitante/solicitudes"
            >
              <v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat
                >Solicitudes Pendientes</v-list-tile-title
              >
            </v-list-tile>
            
            <v-list-tile v-if="menu == 2 || user.user_tipo_id != 3"
              to="/panel/inversionista/transferencias-pendientes"
            >
              <v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat
                >Transferencias pendientes</v-list-tile-title
              >
            </v-list-tile>
            
            <v-list-tile v-if="menu == 3 && user.user_tipo_id != 2 && user.via_id != 2"
              to="/panel-facto/solicitante/mis-proyectos"
            >
              <v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat
                >Solicitudes Aprobadas</v-list-tile-title
              >
            </v-list-tile>
            <v-list-tile @click="logout">
              <v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat
                >Cerrar sesión</v-list-tile-title
              >
            </v-list-tile>
          </v-list>
        </v-menu>
        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ on }">
            <v-btn v-if="SelectedCountry == 'CL'" class="ml-0 nav-1000 hidden-sm-and-down font-OneRem" style="text-transform:capitalize" flat v-on="on">
              <v-img src="/images/flag_chile.svg" aspect-ratio="1.7" contain style="height:26px"></v-img>
            </v-btn>
          </template>
          <v-list>
            <v-list-tile href="https://www.redcapital.pe/">
              <v-list-tile-avatar :tile="true">
                <v-img src="/images/flag_peru.svg" aspect-ratio="1.7" contain style="height:26px"></v-img>
              </v-list-tile-avatar>
              <v-list-tile-content style="text-transform:capitalize">
                <v-list-tile-title>Perú</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile href="https://www.redcapital.com.co/">
              <v-list-tile-avatar :tile="true">
                <v-img src="/images/flag_colombia.svg" aspect-ratio="1.7" contain style="height:26px"></v-img>
              </v-list-tile-avatar>
              <v-list-tile-content style="text-transform:capitalize">
                <v-list-tile-title>Colombia</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-toolbar>
    
    <!-- Menu Movil RC HOME --> 
	<v-navigation-drawer v-model="showMenu"  left app fixed temporary class="rc-menuDrawerSitios">
		<v-list class="transparent pa-2" >
			<!-- Pais -->
			<v-list-group class="rc-list-itemNav">
				<template v-slot:activator>
					<v-list-tile v-if="SelectedCountry == 'CL'" class="rc-listItem-flag">
						<v-list-tile-avatar :tile="true">
							<v-img src="/images/flag_chile.svg" aspect-ratio="1.7" contain style="height:26px"></v-img>
						</v-list-tile-avatar>
						<v-list-tile-content>
							<v-list-tile-title class="rc-listItem-title">Chile</v-list-tile-title>
						</v-list-tile-content>
					</v-list-tile>
				</template>
				<v-list-tile href="https://www.redcapital.pe/">
					<v-list-tile-avatar :tile="true">
						<v-img src="/images/flag_peru.svg" aspect-ratio="1.7" contain style="height:26px"></v-img>
					</v-list-tile-avatar>
					<v-list-tile-content>
						<v-list-tile-title class="rc-listItem-title">Perú</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>
				<v-list-tile href="https://www.redcapital.com.co/">
					<v-list-tile-avatar :tile="true">
						<v-img src="/images/flag_colombia.svg" aspect-ratio="1.7" contain style="height:26px"></v-img>
					</v-list-tile-avatar>
					<v-list-tile-content>
						<v-list-tile-title class="rc-listItem-title">Colombia</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>
			</v-list-group>
			<!-- Pais -->
      <v-list-tile class="v-list-item rc-list-itemNav rc-listItem-pad" v-if="user != null && user.user_tipo_id == 1" to="/admin/asociar">
				<div style="text-align: center; width: 100%; line-height: 1;">
					<div class="rc-listItem-title">Controlar cuenta</div>
				</div>
			</v-list-tile>
      <!-- Invertir -->
      <v-list-tile class="v-list-item rc-list-itemNav rc-listItem-pad" v-show="!userAnglo" v-if=" !valroutesfacto && projectsVisibility && SelectedCountry != 'PYME'" to="/invertir">
				<div style="text-align: center; width: 100%; line-height: 1;">
					<div class="rc-listItem-title">Invertir</div>
				</div>
			</v-list-tile>
			<!-- Solicitar Financiamiento -->
			<v-list-tile class="v-list-item rc-list-itemNav rc-listItem-pad" v-if="user == null" to="/como-funciona-solicitar">
				<div style="text-align: center; width: 100%; line-height: 1;">
					<div class="rc-listItem-title">Financiarte</div>
				</div>
			</v-list-tile>
			<v-list-tile class="v-list-item rc-list-itemNav rc-listItem-pad" v-if="user != null && user.via_id != 2" to="/panel-facto/estadisticas">
				<div style="text-align: center; width: 100%; line-height: 1;">
					<div class="rc-listItem-title">Financiarte</div>
				</div>
			</v-list-tile>
			<v-list-tile class="v-list-item rc-list-itemNav rc-listItem-pad" v-if="user != null && user.via_id == 2" to="/angloamerican">
				<div style="text-align: center; width: 100%; line-height: 1;">
					<div class="rc-listItem-title">Financiarte</div>
				</div>
			</v-list-tile>
			<!-- Solicitar Financiamiento -->
			<!-- Como funciona -->
			<v-list-group class="rc-list-itemNav">
				<template v-slot:activator>
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">Cómo funciona</div>
					</div>
				</template>
				<v-list-tile :to="{ name: 'comofuncionainvertir' }">
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">Inversiones</div>
					</div>
				</v-list-tile>
				<v-list-tile :to="{ name: 'comofuncionasolicitar' }">
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">Financiamiento</div>
					</div>
				</v-list-tile>
			</v-list-group>
			<!-- Como Productos -->
			<v-list-group class="rc-list-itemNav">
				<template v-slot:activator>
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">Productos</div>
					</div>
				</template>
				<v-list-tile :to="{ name: 'homeprontopago' }">
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">Prontopago</div>
					</div>
				</v-list-tile>
				<v-list-tile @click="goblog">
					<div style="width: 100%; line-height: 1;">
						<div class="rc-listItem-title">Blog</div>
					</div>
				</v-list-tile>
			</v-list-group>
			<!-- Nosotros -->
			<v-list-group class="rc-list-itemNav">
				<template v-slot:activator>
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">Nosotros</div>
					</div>
				</template>
				<v-list-tile to="/quienes-somos">
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">¿Quiénes somos?</div>
					</div>
				</v-list-tile>
				<v-list-tile to="/quienes-somos#testimonios">
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">Testimonios</div>
					</div>
				</v-list-tile>
				<v-list-tile to="/prensa">
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">En Prensa</div>
					</div>
				</v-list-tile>
			</v-list-group>
			<!-- Ayuda -->
			<v-list-group class="rc-list-itemNav">
				<template v-slot:activator>
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">Ayuda</div>
					</div>
				</template>
				<v-list-tile to="/preguntas-frecuentes">
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">Preguntas Frecuentes</div>
					</div>
				</v-list-tile>
				<v-list-tile to="/garantia-riesgos">
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">Respaldos y Riesgos</div>
					</div>
				</v-list-tile>
				<v-list-tile to="/contacto">
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">contacto</div>
					</div>
				</v-list-tile>
			</v-list-group>
			<!-- Ayuda -->
			
			<v-list-tile class="v-list-item rc-list-itemNav rc-listItem-pad" v-if="user == null" to="/registrar">
				<div style="text-align: center; width: 100%; line-height: 1;">
					<div class="rc-listItem-title">Crear cuenta</div>
				</div>
			</v-list-tile>
			<v-list-tile class="v-list-item rc-list-itemNav rc-listItem-pad" v-if="user == null" @click="login">
				<div style="text-align: center; width: 100%; line-height: 1;">
					<div class="rc-listItem-title">Ingresar</div>
				</div>
			</v-list-tile>
			<!-- Menus movil, solicitante, inversionista -->
			<v-list-group class="rc-list-itemNav rc-listNameUser" v-if="user != null">
				<template v-slot:activator>
					<div style="text-align: center; width: 100%; line-height: 1;">
						<span class="rc-listItem-img material-icons" style="height:26px;width:26px">account_circle</span>
						<div class="rc-listItem-title">{{ nombreCompleto }}</div>
					</div>
				</template>
				<div v-if="menu == 2 || user.user_tipo_id != 3">
					<v-list-tile v-for="(redCapitalInversionistaMenu, index) in redCapitalInversionistaMenus" :key="`${redCapitalInversionistaMenu.icon}-${index}`" class="v-list-item rc-list-item rc-listItem-pad" :active-class="activeClassMenu" :to="redCapitalInversionistaMenu.to.path ? redCapitalInversionistaMenu.to.path : {name: redCapitalInversionistaMenu.to.name}">
						<div style="text-align: center; width: 100%; line-height: 1;">
							<span class="rc-listItem-img material-icons-outlined" style="height:26px;width:26px">{{ redCapitalInversionistaMenu.icon }}</span>
							<div class="rc-listItem-title">{{ redCapitalInversionistaMenu.text }}</div>
						</div>
					</v-list-tile>
				</div>
				<div v-if="menu == 3">
          <div v-if="user.via_id == 2">
            <v-list-tile v-for="(redCapitalSolicitanteAngloMenu, index) in redCapitalSolicitanteAngloMenus" :key="`${redCapitalSolicitanteAngloMenu.icon}-${index}`" class="v-list-item rc-list-item rc-listItem-pad" :active-class="activeClassMenu" :to="redCapitalSolicitanteAngloMenu.to.path ? redCapitalSolicitanteAngloMenu.to.path : {name: redCapitalSolicitanteAngloMenu.to.name}">
              <div style="text-align: center; width: 100%; line-height: 1;">
                <span class="rc-listItem-img material-icons-outlined" style="height:26px;width:26px">{{ redCapitalSolicitanteAngloMenu.icon }}</span>
                <div class="rc-listItem-title">{{ redCapitalSolicitanteAngloMenu.text }}</div>
              </div>
            </v-list-tile>
          </div>
          <div v-else-if="user.user_tipo_id != 2">
            <v-list-tile v-for="(redCapitalSolicitanteMenu, index) in redCapitalSolicitanteMenus" :key="`${redCapitalSolicitanteMenu.icon}-${index}`" class="v-list-item rc-list-item rc-listItem-pad" :active-class="activeClassMenu" :to="redCapitalSolicitanteMenu.to.path ? redCapitalSolicitanteMenu.to.path : {name: redCapitalSolicitanteMenu.to.name}">
              <div style="text-align: center; width: 100%; line-height: 1;">
                <span class="rc-listItem-img material-icons-outlined" style="height:26px;width:26px">{{ redCapitalSolicitanteMenu.icon }}</span>
                <div class="rc-listItem-title">{{ redCapitalSolicitanteMenu.text }}</div>
              </div>
            </v-list-tile>
          </div>
				</div>
				<v-list-tile class="v-list-item rc-listItem-pad rc-listCerrarSesion" :active-class="activeClassMenu" @click="logout">
					<div style="text-align: center; width: 100%; line-height: 1;">
						<v-icon class="rc-listItem-img">logout</v-icon>
						<div class="rc-listItem-title">Cerrar sesión</div>
					</div>
				</v-list-tile>
			</v-list-group>
		</v-list>
	</v-navigation-drawer>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import {
  Menus,
  menu2,
  Profile,
  ProfileInvestment,
  MyBusiness,
  MyDocuments,
  redCapitalInversionistaMenus,
  redCapitalSolicitanteMenus,
  redCapitalSolicitanteAngloMenus
} from "@/models/DashboardLinks";
import { EventBus } from "@/main";
import { RedCapital, RedCapitalConfig, SelectedCountry } from "@/configuration";

@Component({
  computed: {
    ...mapState(["user", "menu", "showPanelInv", "businesSelected"]),
    canShowLinks() {
      return (
        this.$route.path.startsWith("/panel/") ||
        this.$route.path.startsWith("/panel-facto/")
      );
    },
    projectsVisibility() {
      return (
        this.$store.state.user != null ||
        RedCapitalConfig(SelectedCountry).Project.PublicVisibility
      );
    },
    activeClass() {
      return "primary white--text";
    },
    accountType() {
      return this.$store.state.user.user_tipo_id == 2
        ? "investor"
        : "applicant";
    },
    urlimag() {
      return this.$store.state.menu == 2
        ? "/images/sitio-inversionista.png"
        : "/images/sitio-solicitante.png";
    },
    valroutesfacto(){
      return (
      this.$route.path.startsWith("/panel-facto/")
      )
    },
    userAnglo(){
      return (
        this.$store.state.user != null && this.$store.state.user.via_id == 2 ? true : false
      )
    },
    activeClassMenu() {
      return "rc-menuPanelActive";
    },
    nombreUser(){
      if(this.$store.state.user != null){
        this.$data.nombreCompleto = this.capitalize(this.$store.state.user.nombre)+' '+this.capitalize(this.$store.state.user.app_pat);
        return this.$data.nombreCompleto;
      }
    }
  },
  data() {
    return {
      SelectedCountry,
      RedCapital,
      nombreCompleto: null,
      Menus,
      menu2,
      Profile,
      ProfileInvestment,
      MyBusiness,
      MyDocuments,
	  redCapitalInversionistaMenus,
	  redCapitalSolicitanteMenus,
    redCapitalSolicitanteAngloMenus,
      dark: true,
      canShowUserMenu: false,
      showMenu: false,
      isMobile: true,
      uglyLinks: [
        {
          to: "/quienes-somos",
          text: "Sobre nosotros",
        },
        {
          to: "/prensa",
          text: "En prensa",
        },
        {
          to: "/contacto",
          text: "Contacto",
        },
        {
          to: "/preguntas-frecuentes",
          text: "Preguntas frecuentes",
        },
        {
          to: "/garantia-riesgos",
          text: "Respaldos y riesgos",
        },
      ],
    };
  },
  methods: {
    panelInversionist() {
      this.$store.commit("setMenu", 2);
      this.$router.push("/panel/estadisticas");
     
    },
    panelFacture() {
      this.$store.commit("setMenu", 3);
       this.$router.push("/panel-facto/estadisticas");
    },
    onResize() {
      if (window.innerWidth <= 1270) { /* Old 1080 */ /* 960 Old */
        this.$data.isMobile = true;
      } else {
        this.$data.isMobile = false;
        this.$data.showMenu = false;
      }
    },
    login() {
      this.$data.showMenu = false;
      this.$data.canShowUserMenu = false;
      EventBus.$emit("open-login-dialog");
    },
    logout() {
      EventBus.$emit("logout");
    },
    goblog(){
      window.open("https://blog.redcapital.cl/", '_blank');
    },
    golog(){
      window.open("https://prontopago.redcapital.cl/login", '_blank');
    },
    capitalize(texto){ // #Pendiente dejar esta funcion global para llamar donde sea.
      const lower = texto.toLowerCase();
      return texto.charAt(0).toUpperCase()+lower.slice(1); 
    }
  },
  beforeMount() {
    if(this.$store.state.user != null){
      this.$data.nombreCompleto = this.capitalize(this.$store.state.user.nombre)+' '+this.capitalize(this.$store.state.user.app_pat);
    }
  },
  watch: {
    nombreUser(newVal, oldVal){
      if(newVal != undefined || newVal != null ){
        this.$data.nombreCompleto = newVal
      }
    }
  }
})
export default class NavBar extends Vue {}
</script>

<style lang="scss" scoped>
.font-OneRem{
  font-size: 1rem !important;
  font-weight: 500 !important;
}
.navbar {
  z-index: 3;
}
.rclogo {
  @media (max-width: 960px) {
    height: 40px;
  }
  @media (min-width: 960px) {
    height: 50px;
  }
}
.invlogo {
  @media (max-width: 960px) {
    height: 20px;
  }
  @media (min-width: 960px) {
    height: 25px;
  }
}
.dis-none {
  @media (max-width: 600px) {
    display: none;
  }
}

.v-menu__content {
  position: fixed !important;
  border-radius: 1rem !important;
}
.active {
  box-shadow: 0px -2px 5px 0px rgb(195 87 14);
  //box-shadow: 4px 0 0px -2px #888;
  color: white;
  //border-radius: 0.325rem 0.325rem 0px 0px;
  border-radius: 15px 15px 0px 0px;
}
.btnsitio {
  background-color: #ecded4 !important;
  color: #c3570f;
  //border-radius: 0.325rem 0.325rem 0px 0px;
  border-radius: 15px 15px 0px 0px;
}
.active2 {
  //box-shadow: 0px -2px 5px 0px rgb(195 87 14);
  //box-shadow: 4px 0 0px -2px #888;
  color: white;
  //border-radius: 0.325rem 0.325rem 0px 0px;
}
.btnsitio2 {
  background-color: #ecded4 !important;
  color: #c3570f;
  //border-radius: 0.325rem 0.325rem 0px 0px;
}
.positionabs{
      position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -36px;
    text-align: right;
    right: 0;
}

// MENU
.rc-listItem-title{
	font-weight: 600;
}
// Breakpoints 
@each $breakpoint in $breakpoints_screen_text{
	@include breakpoint_media_queries($breakpoint){
		@if $breakpoint == "x-large"{
			
		}@else if $breakpoint == "large"{
			
		}@else if $breakpoint == "medium-to-large"{
			.rc-menuDrawerSitios{
				margin-top: 0 !important;
				z-index: 5;
				width: 300px !important;
			}
			.rc-listItem-img{
				float: left;
			}
			.rc-listItem-title{
				float: left;
				line-height: 2;
				margin-left: 10px;
			}
		}@else if $breakpoint == "small-to-medium"{
			.rc-menuDrawerSitios{
				margin-top: 0 !important;
				z-index: 5;
				width: 300px !important;
			}
			.rc-listItem-img{
				float: left;
			}
			.rc-listItem-title{
				float: left;
				line-height: 2;
				margin-left: 10px;
			}
		}@else if $breakpoint == "small"{
			.rc-menuDrawerSitios{
				margin-top: 0 !important;
				z-index: 5;
				width: 300px !important;
			}
			.rc-listItem-img{
				float: left;
			}
			.rc-listItem-title{
				float: left;
				line-height: 2;
				margin-left: 10px;
			}
		}
	}
}

/*.rc-listCerrarSesion{
	a{
		div{
			i{
				color: $rc-prim-n1 !important;
			}
			div
			{
				color: $rc-prim-n1 !important;
				font-weight: 600;
			}
		}
	}
}*/

</style>
