export const Configuration = {
  api: {
    base: process.env.VUE_APP_API + "/api",
    // sii: 'http://52.67.252.22:3001/consulta/'
    sii: "https://api.redcapital.cl/inicio_actividades/",
    sii2: process.env.VUE_APP_API + "/api/sii/terceros?rut=",
    landing: "https://landing.redcapital.cl/api/leads?h=",
    status_aws: "https://nv0la6xm66.execute-api.us-east-1.amazonaws.com/prod",
    dte_chile: process.env.VUE_APP_API_DTE + '/api'
  },
  Refresh: {
    Timeout: 10 * 1000
  },
  Redirection: {
    timeout: 1 * 1000 // Seconds * Milliseconds
  },
  Analytics: {
    UA: process.env.VUE_APP_UA,
    Name: process.env.VUE_APP_NAME
  },
  validar_facturas_cotizacion:{
    cotizar: process.env.VUE_APP_FACTURAS,
  }
};

export const SelectedCountry: any = process.env.VUE_APP_SELECTED_COUNTRY;
export const Backend: any = process.env.VUE_APP_API;
export const SocketPort: any = process.env.VUE_APP_SOCKET_PORT;
export const PusherKey: any = process.env.VUE_APP_PUSHER_APP_KEY;
export const PusherCluster: any = process.env.VUE_APP_PUSHER_CLUSTER;
export const ShowDebug: any =
  process.env.VUE_APP_SHOW_DEBUG !== undefined ? true : false;
export const State: any =
  process.env.VUE_APP_STATE !== undefined ? process.env.VUE_APP_STATE : 1;

export const RedCapital = {
  CL: {
    Id: "1",
    Name: "Chile",
    GentilName: "chileno",
    Url: "https://redcapital.cl",
    Dni: "76.400.275-K",
    BusinessName: "RedCapital SPA",
    Logo: "/images/logo-redcapital-head.png",
    PhoneCode: "+56",
    Lang: "es",
    DniName: "RUT",
    Currency: {
      Name: "CLP",
      Prefix: "$",
      Suffix: "",
      LocaleString: "es-ES",
      Uf: "UF"
    },
    Contact: {
      Mail: "contacto@redcapital.cl",
      Phone: "+562 2233 3346",
      Phone2: "+569 4257 6945",
      AddressName: "Coronel Pereira 72, Oficina 703, Las Condes",
      AddressLink: "https://goo.gl/maps/f7T8nxDffbS2",
      AddressName2: null,
      AddressLink2: null,
      Timetable: "Lunes a Jueves de 09:00 a 18:00",
      Timetable2: "Viernes 09:00 a 17:00",
      LinkedIn: "https://cl.linkedin.com/in/red-capital-chile-a93186b0",
      Twitter: "https://twitter.com/_redcapital"
    },
    Project: {
      MinInvest: 300000,
      ClubAmount: 50000,
      PublicVisibility: true,
      TirDecimalsCount: 2
    },
    Account: {
      Bank: "Banco BCI",
      Type: "Cuenta Corriente",
      Number: "30247918",
      Name: "RedCapital SPA",
      Dni: "76.400.275-K"
    },
    Account2: {
      Bank: "Banco Estado",
      Type: "Cuenta Corriente",
      Number: "827523-8",
      Name: "RedCapital SPA",
      Dni: "76.400.275-K"
    }
  },
  PE: {
    Id: "2",
    Name: "Peru",
    GentilName: "peruano",
    Url: "https://redcapital.pe",
    Dni: "20603343329",
    Phone2: null,
    BusinessName: "Red Capital Perú S.A.C.",
    Logo: "/images/redcapital_pe.svg",
    PhoneCode: "+51",
    Lang: "es",
    DniName: "RUC",
    Currency: {
      Name: "PEN",
      Prefix: "S/ ",
      Suffix: "",
      LocaleString: "es-ES"
    },
    Contact: {
      Mail: "contacto@redcapital.pe",
      Phone: "(51) 970 350 429",
      AddressName: "Av. José Pardo 434, Miraflores",
      AddressLink: "https://goo.gl/maps/S9q8staH1jC679aY7",
      AddressName2: null,
      AddressLink2: null,
      Timetable: "Lunes a Jueves de 09:00 a 18:00",
      Timetable2: "Viernes 09:00 a 17:00",
      LinkedIn: "https://cl.linkedin.com/in/red-capital-chile-a93186b0",
      Twitter: "https://twitter.com/_redcapital"
    },
    Project: {
      MinInvest: 3000,
      ClubAmount: 500,
      PublicVisibility: false,
      TirDecimalsCount: 2
    },
    Account: {
      Bank: "BBVA Continental",
      Type: "Cuenta Corriente",
      Number: "0011 0372 030100046699",
      Name: "Red Capital Perú S.A.C.",
      Dni: "20603343329"
    }
  },
  PYME: {
    Id: "1",
    Name: "Chile",
    GentilName: "chileno",
    Url: "https://redcapital.cl",
    Dni: "76.400.275-K",
    BusinessName: "RedCapital SpA",
    Logo: "/images/logo-redcapital-head.png",
    PhoneCode: "+56",
    Lang: "es",
    DniName: "RUT",
    Currency: {
      Name: "CLP",
      Prefix: "$",
      Suffix: "",
      LocaleString: "es-ES"
    },
    Contact: {
      Mail: "contacto@redcapital.cl",
      Phone: "+562 2233 3346",
     // Phone2: "+562 3224 9361",
      AddressName: "Coronel Pereira 72, Oficina 703, Las Condes",
      AddressLink: "https://goo.gl/maps/f7T8nxDffbS2",
      AddressName2: null,
      AddressLink2: null,
      Timetable: "Lunes a Jueves de 09:00 a 18:00",
      Timetable2: "Viernes 09:00 a 17:00",
      LinkedIn: "https://cl.linkedin.com/in/red-capital-chile-a93186b0",
      Twitter: "https://twitter.com/_redcapital"
    },
    Project: {
      MinInvest: 1000000,
      ClubAmount: 50000,
      PublicVisibility: false,
      TirDecimalsCount: 2
    },
    Account: {
      Bank: "Banco BCI",
      Type: "Cuenta Corriente",
      Number: "30247918",
      Name: "RedCapital SpA",
      Dni: "76.400.275-K"
    }
  }
};

export const RedCapitalConfig = (country: string): any => {
  // @ts-ignore
  return RedCapital[country];
};

export const TitleTemplatePublic = "%s | RedCapital";
export const TitleTemplateDashboard = "%s - Panel";

export const Countries = [
  {
    id: 1,
    country: "Chile",
    nationality: "Chilena"
  },
  {
    id: 2,
    country: "Argentina",
    nationality: "Argentina"
  },
  {
    id: 3,
    country: "Peru",
    nationality: "Peruana"
  },
  {
    id: 4,
    country: "Estados unidos",
    nationality: "Estadounidense"
  }
];
