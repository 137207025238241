<template>
  <div class="public-navbar">
    <v-toolbar
      v-resize="onResize"
      dark
      flat
      fixed
      app
      clipped-right
      :color="dark ? 'grisnav' : 'black'"
      class="toolbar-center"
    >
      <div class="toolbar-content">
        <router-link to="/">
          <img
            alt="RedCapital logo"
            :src="'/images/nuevo_logo_RC.png'"
            class="rclogo"
          />
        </router-link>
      </div>
    </v-toolbar>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import {
  Menus,
  Profile,
  MyBusiness,
  MyDocuments
} from "@/models/DashboardLinks";
import { EventBus } from "@/main";
import { RedCapital, RedCapitalConfig, SelectedCountry } from "@/configuration";

@Component({
  computed: {
    canShowLinks() {
      return this.$route.path.startsWith("/panel/");
    },
   
    activeClass() {
      return "primary white--text";
    },
  },
  data() {
    return {
      SelectedCountry,
      RedCapital,
      Menus,
      Profile,
      MyBusiness,
      MyDocuments,
      dark: true,
      isMobile: true,
    };
  },
  methods: {
    onResize() {
      if (window.innerWidth <= 960) {
        this.$data.isMobile = true;
      } else {
        this.$data.isMobile = false;
        this.$data.showMenu = false;
      }
    },
    login() {
      this.$data.showMenu = false;
      this.$data.canShowUserMenu = false;
      EventBus.$emit("open-login-dialog");
    },
    logout() {
      EventBus.$emit("logout");
    }
  }
})
export default class NavBarAnglo extends Vue {}
</script>

<style lang="scss" scoped>
.grisnav{
   background: #EDEDED;
}
.navbar {
  z-index: 3;
}
.rclogo {
  @media (max-width: 960px) {
    height: 40px;
  }
  @media (min-width: 960px) {
    height: 50px;
  }
}
.v-menu__content {
  position: fixed !important;
}
.toolbar-center {
  display: flex;
  justify-content: center;
}

.toolbar-content {
  display: flex;
  align-items: center;
}
</style>
