<template>
	<div>
        <v-img
            :src="iconoImg"
            width="120px"
            class="ma-auto"
        ></v-img>
        <v-btn
            color="rc-button_prim"
            class="text-none  mt-2"
            block
            @click="botonAccion.url ? accionClick(botonAccion.url) : clickFuncion()"
        >
            {{botonAccion.text}}
        </v-btn>
        <!-- Sub Acciones o Sub Mensajes -->
        <router-link v-if="subAccion" :to="subAccion.url" class="d-flex justify-end" style="color: #F7931E;cursor:pointer">	
            <v-icon style="color: #F7931E">notification_important</v-icon>
            <p>{{subAccion.msj}}</p>
        </router-link>
        <p v-if="subMensaje" style="margin-top: 10px;text-align: center;font-weight: 500;color: rgb(255, 138, 0);display: flex;justify-content: center;">
            <span class="material-icons-outlined">error_outline</span>
            {{subMensaje.msj}}
        </p>

        <!-- <router-link v-if="monto_disponible.monto_disponible > 0" :to="{ name: 'dashboard-factoring-anticipo-facture', params: { radius: 2 } }" class="d-flex justify-end" style="color: #F7931E;cursor:pointer">	
        <v-icon style="color: #F7931E">notification_important</v-icon>
        <p>Tiene {{monto_disponible.monto_disponible | currency}} disponibles para cursar </p>
        </router-link>
        <router-link v-if="monto_disponible.monto_disponible_confirming > 0" :to="{ name: 'dashboard-factoring-anticipo-facture', params: { radius: 3 } }" class="d-flex justify-end" style="color: #F7931E;cursor:pointer">	
        <v-icon style="color: #F7931E">notification_important</v-icon>
        <p>Tiene  {{monto_disponible.monto_disponible_confirming | currency}} disponibles para pago</p>
        </router-link> -->
    </div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	
	@Component({
        props: {
			iconoImg : String,
            botonAccion: Object,
            subAccion : Object,
            subMensaje: Object
        },
        methods:{
            accionClick(boton){
                this.$router.push(boton);
            },
            clickFuncion(){
                this.$emit('funcionClick');
            }
        },
	})
	export default class AccionPanelSolicitante extends Vue {}
</script>

<style lang="scss" scoped>
	
</style>
