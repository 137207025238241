import { RedCapital, SelectedCountry } from "@/configuration";
import store from "@/store";

export const orderComunas = (comunas: Array<any>) => {
  return comunas.sort( function( a, b ){
    if( a.name < b.name ){ return -1; }
    if( a.name > b.name ){ return 1; } 
    return 0;
  });
};

export const ProjectsMath = {
  minAmountToInvest(project: any): number {
    let amountLeft = project.monto - this.currentAmountInvested(project);
    // @ts-ignore
    return amountLeft < RedCapital[SelectedCountry].Project.MinInvest
      ? amountLeft
      // @ts-ignore
      : RedCapital[SelectedCountry].Project.MinInvest;
  },
  maxAmountToInvest(project: any): number {
    let amountLeft = project.monto - this.currentAmountInvested(project);
    return amountLeft;
  },
  currentAmountInvested(project: any): number {
    if (project.get_monto_proyecto != null) {
      return parseInt(project.get_monto_proyecto.monto);
    } else {
      return 0;
    }
  },
  currentAmountReserved(project: any): number {
    if (project.get_monto_reserva != null) {
      return parseInt(project.get_monto_reserva.reserva);
    } else {
      return 0;
    }
  },
  currentAmountTemporals(project: any): number {
    if (project.get_monto_proyecto_inversion_tmp != null) {
      return parseInt(project.get_monto_proyecto_inversion_tmp.monto);
    } else {
      return 0;
    }
  }
};

export const ProjectText = {
  timeLimitText(project: any) {
    if (project.credito_tipo.id == 1) {
      return project.cuotas == 1
        ? `${project.cuotas} Dia`
        : `${project.cuotas} Dias`;
    } else {
      return project.cuotas == 1
        ? `${project.cuotas} Mes`
        : `${project.cuotas} Meses`;
    }
  }
};

export const SimulationLogic = {
  getInvestmentPayload(
    investmentId: number,
    amountToInvest: any,
    project: any,
    paymentData: any,
    simulation: any,
    code: any,
    paymentGateway: number
  ) {
    let payload: any = {
      projectId: project.id,
      transaction: `${project.id}`,
      subject: `${project.nombre}`,
      amount: 0,
      investment: 0,
      isReserving: paymentData.isReserving,
      isJoiningClub: false,
      isPartner: false,
      paymentGateway,
      investmentId,
      code
    };

    if (paymentData.partnerState == 2) {
      payload.isPartner = true;
      payload.isJoiningClub = false;
    } else {
      payload.isJoiningClub = paymentData.isJoiningClub;
    }

    if (paymentData.partnerState == 1) {
      payload.isPartner = true;
      payload.isReserving = false;
      payload.isJoiningClub = false;
    }

    if (payload.isReserving && !payload.isJoiningClub && !payload.isPartner) {
      // CASO 1
      payload.amount = simulation.cobroDerechos; // cobroDerechos
      payload.investment = amountToInvest - simulation.cobroDerechos; // montoAInvertir - cobroDerechos
    } else if (
      payload.isReserving &&
      payload.isJoiningClub &&
      !payload.isPartner
    ) {
      // CASO 3
      // @ts-ignore
      payload.amount =
        simulation.cobroDerechos +
        // @ts-ignore
        RedCapital[SelectedCountry].Project.ClubAmount; // cobroDerechos + montoClub
      payload.investment = amountToInvest - simulation.cobroDerechos; // montoAInvertir - cobroDerechos
    } else if (
      payload.isReserving &&
      !payload.isJoiningClub &&
      payload.isPartner
    ) {
      // CASO 2
      payload.amount = 0; // No paga nada por que es socio del club
      payload.investment = amountToInvest; // montoAInvertir
    } else if (
      !payload.isReserving &&
      !payload.isJoiningClub &&
      !payload.isPartner
    ) {
      // CASO 4
      payload.amount = amountToInvest; // montoAInvertir
      payload.investment = 0; // No queda nada en saldo pendiente
    } else if (
      !payload.isReserving &&
      !payload.isJoiningClub &&
      payload.isPartner
    ) {
      // CASO 5
      payload.amount = amountToInvest; // montoAInvertir
      payload.investment = 0; // No queda nada en saldo pendiente
    } else if (
      !payload.isReserving &&
      payload.isJoiningClub &&
      !payload.isPartner
    ) {
      // CASO 6
      // @ts-ignore
      payload.amount =
        // @ts-ignore
        amountToInvest + RedCapital[SelectedCountry].Project.ClubAmount; // montoAInvertir + montoClub
      payload.investment = 0; // No queda saldo pendiente
    }

    if (
      payload.isReserving &&
      !payload.isJoiningClub &&
      !payload.isPartner &&
      simulation.cobroDerechos == 0
    ) {
      // CASO 7
      payload.amount = 1; // Un peso porque si
      payload.investment = amountToInvest; // La inversion queda en saldo pendiente
    }

    return payload;
  }
};

export const DniTrim = {
  string(s: string) {
    var r = s.replace(new RegExp(/\./g), "");
    return r;
  }
};

export const AccountTrim = {
  string(s: string) {
    var r = s.replace(new RegExp(/-/), "");
    return r;
  }
};

export const Uri = {
  string(s: string) {
    var r = s.replace(new RegExp(/%2F/g), "/");
    return r;
  }
};

export const Normalizer = {
  string(s: string) {
    var r = s.toLowerCase();
    r = r.replace(new RegExp(/\s/), " ");
    r = r.replace(new RegExp(/[àáâãäå]/g), "a");
    r = r.replace(new RegExp(/æ/g), "ae");
    r = r.replace(new RegExp(/ç/g), "c");
    r = r.replace(new RegExp(/[èéêë]/g), "e");
    r = r.replace(new RegExp(/[ìíîï]/g), "i");
    r = r.replace(new RegExp(/ñ/g), "n");
    r = r.replace(new RegExp(/[òóôõö]/g), "o");
    r = r.replace(new RegExp(/œ/g), "oe");
    r = r.replace(new RegExp(/[ùúûü]/g), "u");
    r = r.replace(new RegExp(/[ýÿ]/g), "y");
    r = r.replace(new RegExp(/\W/g), "");
    return r;
  }
};

export const isRutValid = (rut_complete: any) => {
  let clean_rut = rut_complete.replace(/[^k0-9]/gi, "").split("");

  let rut: any = clean_rut.slice(0, -1).join("");
  let dv: any = clean_rut
    .slice(clean_rut.length - 1, clean_rut.length)
    .toString();

  let i = 2;
  let sum = 0;

  rut
    .split("")
    .reverse()
    .forEach((el: any) => {
      if (i == 8) {
        i = 2;
      }
      sum += parseInt(el) * i;
      ++i;
    });

  let dvr: any = 11 - (sum % 11);

  if (dvr == 11) dvr = 0;
  if (dvr == 10) dvr = "K";
  return dvr.toString().toUpperCase() == dv.toUpperCase();
};

export const rutParser = (rut: any) => {
  rut = rut.replace(/[^k0-9]/gim, "").split("");
  return rut.length > 1
    ? `${parseInt(rut.slice(0, -1).join("")).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}-${
        rut[rut.length - 1]
      }`
    : rut.join("");
};

export const isUserLogin = (cb: Function, reject?: Function) => {
  if (store.state.user != null) {
    cb();
  } else {
    if (reject !== undefined) reject();
  }
};

export const numeroALetras = (function() {
  // Código basado en https://gist.github.com/alfchee/e563340276f89b22042a
  // ...
  // https://independent.typepad.com/.a/6a00d8341c595453ef01bb08f0cf72970d-500wi
  function Unidades(num: number) {
    switch (num) {
      case 1:
        return "UN";
      case 2:
        return "DOS";
      case 3:
        return "TRES";
      case 4:
        return "CUATRO";
      case 5:
        return "CINCO";
      case 6:
        return "SEIS";
      case 7:
        return "SIETE";
      case 8:
        return "OCHO";
      case 9:
        return "NUEVE";
    }

    return "";
  }
  function Decenas(num: number) {
    let decena = Math.floor(num / 10);
    let unidad = num - decena * 10;

    switch (decena) {
      case 1:
        switch (unidad) {
          case 0:
            return "DIEZ";
          case 1:
            return "ONCE";
          case 2:
            return "DOCE";
          case 3:
            return "TRECE";
          case 4:
            return "CATORCE";
          case 5:
            return "QUINCE";
          default:
            return "DIECI" + Unidades(unidad);
        }
      case 2:
        switch (unidad) {
          case 0:
            return "VEINTE";
          default:
            return "VEINTI" + Unidades(unidad);
        }
      case 3:
        return DecenasY("TREINTA", unidad);
      case 4:
        return DecenasY("CUARENTA", unidad);
      case 5:
        return DecenasY("CINCUENTA", unidad);
      case 6:
        return DecenasY("SESENTA", unidad);
      case 7:
        return DecenasY("SETENTA", unidad);
      case 8:
        return DecenasY("OCHENTA", unidad);
      case 9:
        return DecenasY("NOVENTA", unidad);
      case 0:
        return Unidades(unidad);
    }
  }
  function DecenasY(strSin: string, numUnidades: number) {
    return numUnidades > 0 ? `${strSin} Y ${Unidades(numUnidades)}` : strSin;
  }
  function Centenas(num: number) {
    let centenas = Math.floor(num / 100);
    let decenas = num - centenas * 100;

    switch (centenas) {
      case 1:
        if (decenas > 0) return "CIENTO " + Decenas(decenas);
        return "CIEN";
      case 2:
        return "DOSCIENTOS " + Decenas(decenas);
      case 3:
        return "TRESCIENTOS " + Decenas(decenas);
      case 4:
        return "CUATROCIENTOS " + Decenas(decenas);
      case 5:
        return "QUINIENTOS " + Decenas(decenas);
      case 6:
        return "SEISCIENTOS " + Decenas(decenas);
      case 7:
        return "SETECIENTOS " + Decenas(decenas);
      case 8:
        return "OCHOCIENTOS " + Decenas(decenas);
      case 9:
        return "NOVECIENTOS " + Decenas(decenas);
    }

    return Decenas(decenas);
  }
  function Seccion(
    num: number,
    divisor: number,
    strSingular: string,
    strPlural: string
  ) {
    let cientos = Math.floor(num / divisor);
    let resto = num - cientos * divisor;

    let letras = "";

    if (cientos > 0)
      letras = cientos == 1 ? strSingular : `${Centenas(cientos)} ${strPlural}`;

    if (resto > 0) letras += "";

    return letras;
  }
  function Miles(num: number) {
    let divisor = 1000;
    let cientos = Math.floor(num / divisor);
    let resto = num - cientos * divisor;

    let strMiles = Seccion(num, divisor, "UN MIL", "MIL");
    let strCentenas = Centenas(resto);

    if (strMiles == "") return strCentenas;
    return `${strMiles} ${strCentenas}`;
  }

  function millon(num: number, singular: boolean) {
    var letraMillon = "";
    if (singular == true) letraMillon = "UN MILLON";
    else letraMillon = "MILLONES";
    if (num % 1000000 == 0) letraMillon = letraMillon + " DE";
    return letraMillon;
  }

  function Millones(num: number) {
    let divisor = 1000000;
    let cientos = Math.floor(num / divisor);
    let resto = num - cientos * divisor;

    let strMillones = Seccion(
      num,
      divisor,
      millon(num, true),
      millon(num, false)
    );
    let strMiles = Miles(resto);

    if (strMillones == "") return strMiles;

    return `${strMillones} ${strMiles}`;
  }
  return function NumeroALetras(num: number, currency: any) {
    currency = currency || {};
    let data = {
      numero: num,
      enteros: Math.floor(num),
      centavos: Math.round(num * 100) - Math.floor(num) * 100,
      letrasCentavos: "",
      letrasMonedaPlural: currency.plural || "PESOS CHILENOS", //'PESOS', 'Dólares', 'Bolívares', 'etcs'
      letrasMonedaSingular: currency.singular || "PESO CHILENO", //'PESO', 'Dólar', 'Bolivar', 'etc'
      letrasMonedaCentavoPlural: currency.centPlural || "CENTIMOS CHILENOS",
      letrasMonedaCentavoSingular: currency.centSingular || "CENTIMO CHILENO"
    };

    if (data.centavos > 0) {
      data.letrasCentavos = `CON ${Millones(data.centavos)} ${
        data.centavos == 1
          ? data.letrasMonedaCentavoSingular
          : data.letrasMonedaCentavoPlural
      }`;
    }

    if (data.enteros == 0) {
      return `CERO ${data.letrasMonedaPlural} ${data.letrasCentavos}`;
    }
    if (data.enteros == 1) {
      return `${Millones(data.enteros)} ${data.letrasMonedaSingular} ${
        data.letrasCentavos
      }`;
    } else {
      return `${Millones(data.enteros)} ${data.letrasMonedaPlural} ${
        data.letrasCentavos
      }`;
    }
  };
})();

export function extraerInfoDireccion(direccion: string) {
  const regexAntesNumDeCalle = /^(.*?)(?=\s+\d|,)/;
  const matchAntesNumDeCalle = direccion.match(regexAntesNumDeCalle);
  const calle = matchAntesNumDeCalle ? matchAntesNumDeCalle[1] : '';

  const regexCalle = /(\d+)(?=\s*,)/;
  const matchCalle = direccion.match(regexCalle);
  const numeroCalle = matchCalle ? matchCalle[1] : '';

  const regexDptoCasa = /Dpto\/Casa\s+(.+?),/;
  const matchDptoCasa = direccion.match(regexDptoCasa);
  const numeroDptoCasa = matchDptoCasa ? matchDptoCasa[1] : '';

  //@ts-ignore
  const regexPais = /[^,]+$/;
  const matchPais = direccion.match(regexPais);
  const pais = matchPais ? matchPais[0].trim() : '';

  return {
    calle,
    numeroCalle,
    numeroDptoCasa,
    pais,
  };
}

export function formatoNuevoDireccion(direccion: string): boolean {
  const componentes = direccion.split(",").map((comp) => comp.trim());

  if (componentes.length < 3 || componentes.length > 4) {
    return false;
  }
  if (!componentes[0]) {
    return false;
  }

  if (!componentes[1].match(/^(.+)\s+-\s+(.+)$/)) {
    return false;
  }

  if (componentes.length === 3) {
    if (!componentes[2].match(/^[A-Za-z\s'-]+$/)) {
      return false;
    }
  } else if (componentes.length === 4) {
    if (!componentes[2].match(/Dpto\/Casa\s+(.+?)/)) {
      return false;
    }
  }

  return true;
}

export function infoUsuario(data:any){
  let user_props = [
    'nombre', 
    'app_pat',
    'app_mat',
    'email',
    'rut',
    'fec_nac',
    'telefono',
    'direccion',
    'profesion',
    'region_id',
    'comuna_id'
  ]
  let usuario = {};

  user_props.forEach(function(prop){
    if (data.hasOwnProperty(prop)) {
      //@ts-ignore
      usuario[prop] = data[prop];
    }
  });

  return usuario;
}
