
export const Menus = [
  {
    icon: "insert_chart",
    text: "Mis inversiones",
    categories: [
      {
        icon: "maps",
        text: "Resumen",
        to: {
          name: "dashboard-investor-my-investments"
        }
      },
      {
        icon: "receipt",
        text: "Transferencias Pendientes",
        to: {
          name: "dashboard-investor-pending-payments",
          params: { etapa: 1 }
        }
      },
      {
        icon: "receipt",
        text: "Inversiones Anuladas",
        to: {  name: "dashboard-investor-cancel-payment",
        params: { etapa: 2 }
        }
      },
      {
        icon: "receipt",
        text: "Estado de Pagos",
        to: {
          name: "dashboard-investor-payment-status",
          params: { etapa: 2 }
        }
      },
      { 
        icon: 'receipt',
        text: 'Detalle Cuotas',
        to: {
          name: 'dashboard-investor-fees-details',
        },
      },
      {
        icon: "receipt",
        text: "Detalle Pagos",
        to: {
          name: "dashboard-investor-payment-details",
          params: { etapa: 1 }
        }
      },
      {
        icon: 'receipt',
        text: 'Cartola Mensual',
        to: {
          name: 'dashboard-investor-payment-mensual',
        },
      }
      /*{
        icon: 'receipt',
        text: 'Cartola Anual',
        to: {
          name: 'dashboard-investor-cartola-anual',
        },
      },*/
      // {
      //   icon: 'insert_chart',
      //   text: 'Mis inversiones',
      //   links: [
      //     {
      //       icon: 'maps',
      //       text: 'Resumen Inversiones (RI)',
      //       to: {
      //         name: 'dashboard-investor-my-investments',
      //       },
      //     },
      //     {
      //       icon: 'receipt',
      //       text: 'Transferencias Pendientes',
      //       to: {
      //         name: 'dashboard-investor-pending-payments',
      //       },
      //     },
      //   ],
      // },
    ]
  }
]

export const menu2 = [
  {
    icon: "drafts",
    text: "Mis solicitudes",
    categories: [
      {
        icon: "folder_special",
        text: "Pendientes",
        to: {
          name: "dashboard-applicant-requests"
        }
      },
      {
        icon: "receipt",
        text: "Aprobadas",
        to: {
          name: "dashboard-applicant-my-projects"
        }
      }
    ]
  }
]

      // FIXME: ACTIVAR CERRADAS CUANDO ESTÉ FUNCIONANDO
      // {
      //   icon: 'assignment_turned_in',
      //   text: 'Cerradas',
      //   to: {
      //     name: null
      //   },
      // },
   /* ]
  }*/
  // {
  //   allow: [1],
  //   icon: 'dashboard',
  //   text: 'Administrador',
  //   categories: [
  //     {
  //       icon: 'folder_special',
  //       text: 'Proyectos',
  //       links: [
  //         {
  //           icon: 'work',
  //           text: 'Accion en algun p',
  //         },
  //       ],
  //     }
  //   ],
  // },
  //];

export const Profile = {
  icon: "person",
  text: "Mis datos",
  links: [
    {
      icon: "assignment",
      text: "Datos personales",
      to: {
        name: "dashboard-personal-data"
      }
    },
    {
      icon: "account_balance",
      text: "Cuenta Bancaria",
      to: {
        name: "dashboard-investor-bank-account"
      }
    },
    {
      icon: "lock",
      text: "Cambiar contraseña",
      to: {
        name: "dashboard-change-password"
      }
    }
    /*{
      icon: 'star',
      text: 'Socio club',
      to: {
        name: 'dashboard-investor-pay-membership',
      },
    },*/
    // {
    //   icon: 'import_export',
    //   text: 'Mis movimientos',
    //   to: {
    //     name: 'dashboard-my-movements',
    //   },
    // },
  ]
};

export const MyBusiness = {
  icon: "business",
  text: "Mis empresas",
  to: {
    name: "dashboard-my-business"
  }
};

export const Investment = {
  icon: "/images/panelEstadisticas/ico/ico_invertir.svg",
  text: "Invertir",
  to: {
    name: "investment"
  }
};

export const ProfileInvestment = {
  icon: "receipt",
  text: "Mi Perfil",
  to: {
    name: "dashboard-profile-investment"
  }
};

export const MyDocuments = {
  icon: "folder_special",
  text: "Mis documentos",
  active: false,
  links: [
    {
      icon: "chrome_reader_mode",
      text: "Contratos",
      to: {
        name: "dashboard-contracts"
      }
    },
    {
      icon: "work",
      text: "Mandatos",
      to: {
        name: "dashboard-terms"
      }
    },
    {
      icon: "work",
      text: "Declaraciones SII",
      to: {
        name: "dashboard-declaration"
      }
    },
    {
      icon: "maps",
      text: "Pagarés y respaldos",
      to: {
        name: "dashboard-iou-and-certificates"
      }
    }
  ]
};

export const inversionistaMenus = [
	{
		icon: "/images/panelEstadisticas/ico/ico_misInversiones.svg",
		text: "Mis inversiones",
		categories: [
			{
				icon: "/images/panelEstadisticas/ico/ico_resumen.svg",
				text: "Resumen",
				to: {
					name: "dashboard-investor-my-investments"
				}
			},
			{
				icon: "/images/panelEstadisticas/ico/ico_transferencias.svg",
				text: "Transferencias",
				to: {
					name: "dashboard-investor-pending-payments",
					params: { etapa: 1 }
				}
			},
			/* {
				icon: "receipt",
				text: "Inversiones Anuladas",
				to: {  
					name: "dashboard-investor-cancel-payment",
					params: { etapa: 2 }
				}
			}, */
			/* {
				icon: "receipt",
				text: "Estado de Pagos",
				to: {
					name: "dashboard-investor-payment-status",
					params: { etapa: 2 }
				}
			}, */
			{ 
				icon: '/images/panelEstadisticas/ico/ico_detalleCuotas.svg',
				text: 'Detalle Cuotas',
				to: {
					name: 'dashboard-investor-fees-details',
				},
			},
			/* {
				icon: "receipt",
				text: "Detalle Pagos",
				to: {
					name: "dashboard-investor-payment-details",
					params: { etapa: 1 }
				}
			}, */
			{
				icon: '/images/panelEstadisticas/ico/ico_cartolaMensual.svg',
				text: 'Cartola Mensual',
				to: {
					name: 'dashboard-investor-payment-mensual',
				},
			}
		]
	}
];
export const inversionistaProfile = {
	icon: "/images/panelEstadisticas/ico/ico_misDatos.svg",
	text: "Mis datos",
	links: [
		{
			icon: "/images/panelEstadisticas/ico/ico_datosPersonales.svg",
			text: "Datos personales",
			to: {
				name: "dashboard-personal-data"
			}
		},
		{
			icon: "/images/panelEstadisticas/ico/ico_cuentaBancaria.svg",
			text: "Cuenta Bancaria",
			to: {
				name: "dashboard-investor-bank-account"
			}
		},
		{
			icon: "/images/panelEstadisticas/ico/ico_datosPersonales.svg",
			text: "Cambiar contraseña",
			to: {
				name: "dashboard-change-password"
			}
		}
	]
};
export const inversionistaMyDocuments = {
	icon: "/images/panelEstadisticas/ico/ico_miDocumentos.svg",
	text: "Mis documentos",
	active: false,
	links: [
		{
			icon: "/images/panelEstadisticas/ico/ico_contratos.svg",
			text: "Contratos",
			to: {
				name: "dashboard-contracts"
			}
		},
		{
			icon: "/images/panelEstadisticas/ico/ico_mandatos.svg",
			text: "Mandatos",
			to: {
				name: "dashboard-terms"
			}
		},
		{
			icon: "/images/panelEstadisticas/ico/ico_declaracionesSii.svg",
			text: "Declaraciones SII",
			to: {
				name: "dashboard-declaration"
			}
		},
		{
			icon: "/images/panelEstadisticas/ico/ico_pagaresRespaldos.svg",
			text: "Pagarés y respaldos",
			to: {
				name: "dashboard-iou-and-certificates"
			}
		}
	]
};

/**
 * Menu Solicitante 
 * General
 */
export const panelSolicitanteMenus = [
	{
		icon: "dashboard",
		text: "Mi panel",
		to: {
			path: "/panel-facto/estadisticas"
		}
	},
	{
		icon: "receipt",
		text: "Anticipar facturas",
		to: {
			path: "/panel-facto/anticipo-facturas"
		}
	},
	{
		icon: "receipt_long",
		text: "Ceder facturas",
		to: {
			path: "/panel-facto/ceder-facturas"
		}
	},
	{
		icon: "topic",
		text: "Mis operaciones",
		to: {
			path: "/panel-facto/mis-operaciones"
		}
	},
	{
		icon: "person",
		text: "Mis datos",
		to: {
			path: "/panel-facto/datos-personales"
		}
	},
	{
		icon: "lock",
		text: "Mis claves y certificados",
		to: {
			path: "/panel-facto/mis-claves"
		}
	},
	{
		icon: "business",
		text: "Mis empresas",
		to: {
			path: "/panel-facto/empresas"
		}
	},
	{
		icon: "folder_special",
		text: "Mis documentos",
		to: {
			path: "/panel-facto/documentos"
		}
	},
	{
		icon: "analytics",
		text: "Mis finanzas",
		to: {
			path: "/panel-facto/finanzas"
		}
	},
];
/**
 * Menu Solicitante del tipo de via_id = 2
 */
 export const panelSolicitanteMenus2 = [
	{
		icon: "dashboard",
		text: "Mi panel",
		to: {
			path: "/panel-facto/estadisticas"
		}
	},
	{
		icon: "list",
		text: "Mis Solicitudes",
		to: {
			path: "/panel-facto/solicitante/solicitudes"
		}
	},
	{
		icon: "person",
		text: "Mis datos",
		to: {
			path: "/panel-facto/datos-personales"
		}
	},
	/* {
		icon: "/images/panel/solicitante/ico_misDatos.svg",
		text: "Mis datos",
		to: {
			path: "/panel-facto/cuenta-banco"
		}
	} */
];

/**
 * Menu Inversionista
 */
export const panelInversionistaMenus = [
	{
		icon: "dashboard",
		text: "Mi panel",
		to: {
			name: "dashboard-stats"
		}
	},
	{
		icon: "attach_money",
		text: "Invertir",
		to: {
			name: "investment"
		}
	}, // Mis inversiones
	{
		icon: "paid",
		text: "Mis inversiones",
		categories: [
			{
				icon: "description",
				text: "Resumen",
				to: {
					name: "dashboard-investor-my-investments"
				}
			},
			{
				icon: "pending_actions",
				text: "Transferencias pendientes",
				to: {
					name: "dashboard-investor-pending-payments",
					params: { etapa: 1 }
				}
			},
			{
				icon: "request_quote",
				text: "Mis Pagos",
				to: {
					name: "dashboard-investor-payment-status",
					params: { etapa: 2 }
				}
			},
			{ 
				icon: 'payments',
				text: 'Detalle cuotas',
				to: {
					name: 'dashboard-investor-fees-details',
				},
			},
			{
				icon: 'assignment',
				text: 'Cartola Anual',
				to: {
					name: 'dashboard-investor-payment-mensual',
				},
			}
		]
	},// Mis Datos
	{
		icon: "person",
		text: "Mis datos",
		categories: [
    		{
				icon: "assignment",
				text: "Datos personales",
				to: {
					name: "dashboard-personal-data"
				}
			},
			{
				icon: "account_balance",
				text: "Cuenta Bancaria",
				to: {
					name: "dashboard-investor-bank-account"
				}
			},
		]  
	}, // Perfil y Alertas Pendientes
	{
		icon: "badge",
		text: "Perfil y alertas",
		to: {
			name: "dashboard-profile-investment"
			//path: "/panel/mi-perfil"
		}
	},
	// Mis documentos
	{
		icon: "folder_special",
		text: "Mis documentos",
		active: false,
		categories: [
			{
				icon: "history_edu",
				text: "Contratos",
				to: {
					name: "dashboard-contracts"
				}
			},
			{
				icon: "work_outline",
				text: "Mandatos",
				to: {
					name: "dashboard-terms"
				}
			},
			{
				icon: "feed",
				text: "Declaraciones SII",
				to: {
					name: "dashboard-declaration"
				}
			},
			{
				icon: "shield",
				text: "Pagarés y respaldos",
				to: {
					name: "dashboard-iou-and-certificates"
				}
			}
		]
	}
]

/**
 * Menu Perfil Inversionista
 */
 export const perfilInversionistaMenus = [
	{
		icon: "attach_money",
		text: "Invertir",
		to: {
			name: "investment"
		}
	},
	{
		icon: "description",
		text: "Resumen inversiones",
		to: {
			name: "dashboard-investor-my-investments"
		}
	},
	{
		icon: "pending_actions",
		text: "Transferencias pendientes",
		to: {
			name: "dashboard-investor-pending-payments",
			params: { etapa: 1 }
		}
	},
	// Ayuda --->
	{
		icon: "help_outline",
		text: "Ayuda",
		categories: [
			{
				icon: "info",
				text: "Preguntas Frecuentes",
				to: {
					path: "/preguntas-frecuentes"
				}
			},
			{
				icon: "privacy_tip",
				text: "Garantías y riesgos",
				to: {
					path: "/garantia-riesgos"
				}
			},
			{
				icon: "contact_support",
				text: "Contacto",
				to: {
					path: "/contacto"
				}
			},
			/*{
				icon: "book",
				text: "Blog",
				to: {
					path: "/preguntas-frecuentes"
				}
			},*/
		]
	},
]

export const redCapitalInversionistaMenus = [
	{
		icon: "attach_money",
		text: "Invertir",
		to: {
			name: "investment"
		}
	},
	{
		icon: "description",
		text: "Panel Inversionista",
		to: {
			name: "dashboard-stats"
		}
	},
	{
		icon: "description",
		text: "Resumen inversiones",
		to: {
			name: "dashboard-investor-my-investments"
		}
	},
	{
		icon: "pending_actions",
		text: "Transferencias pendientes",
		to: {
			name: "dashboard-investor-pending-payments",
			params: { etapa: 1 }
		}
	}
]

/**
 * Menu Perfil Solicitante
 */
 export const perfilSolicitanteMenus = [
	{
		icon: "attach_money",
		text: "Invertir",
		to: {
			name: "investment"
		}
	},
	{
		icon: "person",
		text: "Mis datos",
		to: {
			path: "/panel-facto/cuenta-banco"
		}
	},
	{
		icon: "pending_actions",
		text: "Solicitudes pendientes",
		to: {
			name: "dashboard-applicant-requests"
		}
	},
	{
		icon: "inventory",
		text: "Solicitudes aprobadas",
		to: {
			name: "dashboard-applicant-my-projects"
		}
	},
	{
		icon: "help_outline",
		text: "Ayuda",
		categories: [
			{
				icon: "info",
				text: "Preguntas Frecuentes",
				to: {
					path: "/preguntas-frecuentes"
				}
			},
			{
				icon: "privacy_tip",
				text: "Garantías y riesgos",
				to: {
					path: "/garantia-riesgos"
				}
			},
			{
				icon: "contact_support",
				text: "Contacto",
				to: {
					path: "/contacto"
				}
			},
			/*{
				icon: "book",
				text: "Blog",
				to: {
					path: "/preguntas-frecuentes"
				}
			},*/
		]
	},
]

export const redCapitalSolicitanteMenus = [
	{
		icon: "attach_money",
		text: "Invertir",
		to: {
			name: "investment"
		}
	},
	{
		icon: "person",
		text: "Panel Solicitante",
		to: {
			path: "/panel-facto/estadisticas"
		}
	},
	{
		icon: "pending_actions",
		text: "Solicitudes pendientes",
		to: {
			name: "dashboard-applicant-requests"
		}
	},
	{
		icon: "inventory",
		text: "Solicitudes aprobadas",
		to: {
			name: "dashboard-applicant-my-projects"
		}
	},
]

/**
 * Menu Perfil solicitante Anglo
 *  */
 export const redCapitalSolicitanteAngloMenus = [
	{
		icon: "person",
		text: "Panel Solicitante",
		to: {
			path: "/panel-facto/estadisticas"
		}
	}/* , */
	/* {
		icon: "pending_actions",
		text: "Mis Solicitudes",
		to: {
			name: "dashboard-applicant-requests"
		}
	} */
]

