import { chileanRoutes } from "@/dynamic.routes";

export default [{
        path: "/",
        name: "hometest2",
        component: () => import("@/views/HomeNew.vue")
    },{
        path: "/login",
        name: "newlogin",
        component: () => import("@/views/NewLogin.vue")
    },{
        path: "/referidos/:id",
        name: "QuestionsReferidos",
        component: () =>
            import("@/views/QuestionsReferidos.vue"),
            props: true
    },{
        path: "/contacto",
        name: "contact",
        component: () => import("@/views/Contact.vue")
    },{ // TODO: Revisar
        path: "/landing",
        name: "landing",
        component: () => import("@/views/Landing.vue")
    },{ // TODO: Revisar
        path: "/santander",
        name: "landing-santander",
        component: () => import("@/views/LandingSantander.vue"),
        beforeEnter: chileanRoutes
    },{ // TODO: Revisar
        path: "/cotizafactoring",
        name: "landing-cotizafactoring",
        component: () => import("@/views/LandingCotizaFactoring.vue"),
        beforeEnter: chileanRoutes
    },{ // TODO: Revisar
        path: "/cotizafactoring2",
        name: "landing-cotizafactoring2",
        component: () => import("@/views/LandingCotizaFactoring2.vue"),
        beforeEnter: chileanRoutes
    },{
        path: "/fondoemprendedoressolidarios.cl",
        name: "landing-angloameriican",
        component: () => import("@/views/LandingAngloAmerican.vue"),
        // beforeEnter: chileanRoutes
    },{ // TODO: Revisar
        path: "/banigualdad",
        name: "landing-banigualdad",
        component: () => import("@/views/LandingBanigualdad.vue"),
        beforeEnter: chileanRoutes
    },{
        path: "/quienes-somos",
        name: "about",
        component: () => import("@/views/aboutUs/AboutUs.vue")
    },{
        path: "/como-funciona-invertir",
        name: "comofuncionainvertir",
        component: () => import("@/views/HowItWorksNew.vue")
    },{
        path: "/como-funciona",
        redirect: "como-funciona-invertir"
    },{
        path: "/garantia-riesgos",
        name: "warrantyrisk",
        component: () => import("@/views/Warranty.vue")
    },{
        path: "/preguntas-frecuentes",
        name: "faq",
        component: () => import("@/views/Faq.vue")
    },{
        path: "/politica-privacidad",
        name: "politica-privacidad",
        component: () => import("@/views/Politicas-privacidad.vue")
    },{
        path: "/protocolo-cobranza",
        name: "protocolo-cobranza",
        component: () => import("@/views/Protocolo-cobranza.vue")
    },{
        path: "/ingresar",
        name: "login",
        component: () => import("@/views/Login.vue")
    },{
        path: "/registrar",
        name: "register",
        component: () => import("@/views/Register.vue"),
        props: true
    },{
        path: '/registro/:tipo?',
        name: "register-requests",
        component: () => import("@/views/Register.vue"),
        props: true,
    },{ // TODO: REVISAR DESIGN SYSTEM
        path: "/recuperar",
        name: "recuperar",
        component: () => import("@/views/Recover.vue")
    },{
        path: "/recuperar/:token",
        name: "recuperar contraseña",
        component: () => import("@/views/NewPassword.vue"),
        props: true
    },{
        path: "/prensa",
        name: "press",
        component: () => import("@/views/Press.vue")
    },{ // TODO: REVISAR
        path: "/chilecompra",
        name: "chilecompra-form",
        component: () => import("@/views/ChileCompraForm.vue"),
        beforeEnter: chileanRoutes
    },{ // TODO: REVISAR
        path: "/chilecompra-exitosa",
        name: "chilecompra-success",
        component: () => import("@/views/ChileCompraSuccess.vue"),
        beforeEnter: chileanRoutes
    },{
        path: "/simulacion/proyecto-:projectId",
        name: "simulation",
        component: () => import("@/views/simulacionInvertir/Simulation.vue"),
        props: true
    },{
        path: "/inversion-realizada",
        name: "investment-completed",
        component: () => import("@/views/InvestmentCompleted.vue")
    },{
        path: "/reserva-exitosa",
        name: "successful-reserving",
        component: () => import("@/views/SuccessfulReserving.vue")
    },{
        path: "/solicitar",
        redirect: "/como-funciona-solicitar"
    },{
        path: "/como-funciona-solicitar",
        name: "comofuncionasolicitar", //old Action
        component: () => import("@/views/HowItWorkSolicit.vue"),
        beforeEnter: chileanRoutes
    },{
        path: "/prontopago",
        name: "homeprontopago", //old Action
        component: () => import("@/views/prontoPago/HomeProntoPago.vue"),
        beforeEnter: chileanRoutes
    },{
        path: "/caso-de-estudio/:idCaso",
        name: "caso-estudio-1",
        component: () => import("@/views/studyCase/StudyCase.vue"),
        beforeEnter: chileanRoutes
    },{
        path: "/financiamiento",
        name: "finance",
        component: () => import("@/views/Financing.vue"),
        beforeEnter: chileanRoutes
    },{
        path: "/bueno-saberlo",
        name: "good-to-know",
        component: () => import("@/views/GoodToKnow.vue")
    },{
        path: "/ingreso_clave_sii/:token",
        name: "ingreso-claveSii",
        component: () => import("@/views/claveSii/ClaveSii.vue"),
        props: true
    },{
        path: "/operacionempresas",
        name: "empresas-claveSii",
        component: () => import("@/views/landingClaveSii.vue")
    },{
        path: "/nuestrosbeneficios",
        name: "landing-factoring-passuno",
        component: () => import("@/views/LandingFactoringReg.vue"),
        beforeEnter: chileanRoutes
    },{
        path: "/potenciatuPyme",
        name: "landing-factoring-passdos",
        component: () => import("@/views/LandingFactoringReg2.vue"),
        beforeEnter: chileanRoutes
    },
    {
        path: "/liquidez",
        name: "landing-factoring-passtres",
        component: () => import("@/views/LandingFactoringReg3.vue"),
        beforeEnter: chileanRoutes
    },
    {
        path: "/leaseback",
        name: "info-leaseback",
        component: () => import("@/views/LandingLeaseback.vue"),
        beforeEnter: chileanRoutes
    },
    {
        path: "/enrolamiento",
        name: "enrolamiento-empresa",
        component: () => import("@/views/LandingWarren.vue"),
        beforeEnter: chileanRoutes
    },
    {
        path: "/login-company",
        name: "login-alternativo",
        component: () => import("@/views/alternateLogin/alternateLogin.vue"),
        beforeEnter: chileanRoutes
    },
    {
        path: "/verificar_facturas",
        name: "verificacion-facturas",
        component: () => import("@/views/LandingVerificacionFacturasDeudor.vue"),
        beforeEnter: chileanRoutes
    },

]