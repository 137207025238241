import Axios, { AxiosResponse } from "axios";
import store from "@/store";
import { Configuration } from "@/configuration";
import { Project, ProjectInterface } from "@/models/Project";
import { DniTrim } from "@/utils";
import { Compania } from '@/models/Company';
//@ts-ignore
import md5 from 'crypto-js/md5';


export const ErrorHandler = (res: AxiosResponse, data: any) => {
  return data;
};

Axios.defaults.headers["Content-Type"] = "application/json";

Axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest";


export default {
  async informacion_adicional2(empresa_id:number) {
     const informacion = await Axios.get(`${Configuration.api.base}/cotizacion/informacion_adicional/${empresa_id}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
   return informacion.data
  },
  async operacion_credito(empresa_id:number){
    try {
      const resp = await Axios.get(`${Configuration.api.base}/operacion/credito/${empresa_id} `, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
      return resp.data.data;
    } catch (error) {
    }
},
async creditos(empresa_id:number){
  const credits = await Axios.get(`${Configuration.api.base}/listar_creditos/${empresa_id}`, {
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  })
  return credits.data;
},
async operacion_factoring(empresa_id:number){
  try {
    const resp = await Axios.get(`${Configuration.api.base}/operacion/factoring/${empresa_id}`,{
      headers: {
        Authorization: `Bearer ${store.state.token}`
      }
    })
    return resp.data.data;
  } catch (error) {
  }
},
async solicitud_factoring(empresa_id:number){
    const resp = await Axios.get(`${Configuration.api.base}/operacion/solicitudes_factoring/${empresa_id}`,{
      headers: {
        Authorization: `Bearer ${store.state.token}`
      }
    })
   return resp.data;
},
async solicitud_factoringVerDetalle(cotizacion_id:number,empresa_id:number){
  try {
    const resp = await 
    Axios.get(`${Configuration.api.base}/operacion/solicitudes_factoring/detalle/${cotizacion_id}/${empresa_id}`,{
      headers: {
        Authorization: `Bearer ${store.state.token}`
      }
    })
    return resp.data;
  } 
  catch (error) {
  }
},
  getEstados() {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/proyecto/estados`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then((res: any) => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getlanding(query: any){
    return new Promise((resolve, reject) => {
      fetch(`${Configuration.api.landing}${query}`)
      .then(response => resolve(response.json()))
      .catch( (err: any) => {
        reject(err)
      })
     /* Axios.get(`${Configuration.api.landing}${query}`
      ).then( (res: any) => {
        resolve(res)
      }).catch( (err: any) => {
        reject(err)
      })*/
    });
  },
  registraranalytics( 
    origen: string,
    ip_address: string,
    variable:string,
    user_id: any,
    empresaid?:any,
    info?:any){
    return new Promise((resolve, reject) => {
      Axios.post(`${Configuration.api.base}/tracking/track-create`,{
        origen,
        ip_address,
        variable,
        user_id,
        empresa_id:empresaid,
        info
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then((res: any) => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },canje(id:any){
    return new Promise((resolve,reject)=>{
      Axios.get(`${Configuration.api.base}/canje/${id}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then((res: any) => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    })
  },
  getGateways() {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/pasarelas`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then((res: any) => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getVIP(id: any) {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/usuario/vip/${id}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then((res: any) => {
          resolve(res.data.message);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  admin: {
    getAllUsers() {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(`${Configuration.api.base}/usuario`, {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        })
          .then(res => {
            resolve(res.data.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    loginBypass(id: any) {
      return new Promise((resolve, reject) => {
        Axios.post(
          `${Configuration.api.base}/bypassLogin/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  khipu: {
    confirm: {
      normal(transaction: any) {
        return new Promise((resolve, reject) => {
          Axios.post(
            `${Configuration.api.base}/khipu/confirmar`,
            {
              inversion_id: transaction.inversion_id,
              transaccion_id: transaction.id,
              monto: transaction.monto,
              cod: transaction.cod
            },
            {
              headers: {
                Authorization: `Bearer ${store.state.token}`
              }
            }
          )
            .then(res => {
              resolve(res.data);
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      pending(transaction: any) {
        return new Promise((resolve, reject) => {
          Axios.post(
            `${Configuration.api.base}/khipu/confirmar-pago-pendiente`,
            {
              inversion_id: transaction.inversion_id,
              transaccion_id: transaction.id,
              monto: transaction.monto,
              cod_transaccion: transaction.transaccion,
              pago_deta_tipo_id: transaction.pago_deta_tipo_id,
              cod_inversion: transaction.cod
            },
            {
              headers: {
                Authorization: `Bearer ${store.state.token}`
              }
            }
          )
            .then(res => {
              resolve(res.data);
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    async obtenerwidget(monto:number, proyecto_pago_id:number, proyecto_pago_tipo_accion:number){
      const widget = await Axios.post(`${Configuration.api.base}/generarPaymentKhipu/`,{
        monto, 
        proyecto_pago_id, 
        proyecto_pago_tipo_accion
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return widget.data;
    },
    async validarPago(paymentIntent_id:number, proyecto_pago_id:number, proyecto_pago_tipo_accion:number){
      const widget = await Axios.post(`${Configuration.api.base}/validarPagoKhipu/`,{
        paymentIntent_id, 
        proyecto_pago_id, 
        proyecto_pago_tipo_accion
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return widget.data;
    }
  },
  payment: {
    create(payload: any) {
      return new Promise((resolve, reject) => {
        Axios.post(
          `${Configuration.api.base}/transaccion/crear`,
          {
            // @ts-ignore
            user_id: store.state.user.id,
            proyecto_id: payload.projectId,
            motivo: payload.subject,
            // @ts-ignore
            monto: store.state.user.id == 3818 ? 1000 : payload.amount,
            reserva: payload.isReserving,
            socio: payload.isJoiningClub,
            es_socio: payload.isPartner,
            pasarela_id: payload.paymentGateway,
            inversion_id: payload.investmentId,
            pago_deta_tipo_id: payload.paymentType,
            return_url: window.location.origin + "/gracias-por-invertir",
            cancel_url:
              window.location.origin +
              "/panel/inversionista/transferencias-pendientes",
            // @ts-ignore
            payer_email: store.state.user.email,
            // @ts-ignore
            payer_name: store.state.user.nombre + " " + store.state.user.app_pat
          },
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
    types() {
      return new Promise((resolve, reject) => {
        Axios.get(`${Configuration.api.base}/pagos/detalle/tipos`, {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        })
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    details(investmentId: number) {
      return new Promise((resolve, reject) => {
        Axios.get(`${Configuration.api.base}/pagos/${investmentId}`, {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        })
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    report: {
      gateway(projectId: number, amount: number, payload: any) {
        return new Promise((resolve, reject) => {
          Axios.post(
            `${Configuration.api.base}/pagos/crear-inversionista`,
            {
              // @ts-ignore
              user_id: store.state.user.id,
              inversion_id: payload.id,
              proyecto_id: projectId,
              monto: amount,
              nombre_titular: payload.name,
              cedula_titular: payload.dni,
              fecha: payload.date,
              pasarela_id: payload.gatewayId,
              cod_transaccion: payload.code,
              pago_deta_tipo_id: 1
            },
            {
              headers: {
                Authorization: `Bearer ${store.state.token}`
              }
            }
          )
            .then(res => {
              resolve(res.data);
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      tracking(tipo: any, id: any) {
        return new Promise((resolve, reject) => {
          Axios.put(
            `${Configuration.api.base}/tracking-inversion/eleccion-pago`,
            {
              tipo: tipo,
              inversion_id: id
            },
            {
              headers: {
                Authorization: `Bearer ${store.state.token}`
              }
            }
          )
            .then(res => {
              resolve(res.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      transference(projectId: number, amount: number, payload: any) {
        return new Promise((resolve, reject) => {
          Axios.post(
            `${Configuration.api.base}/pagos/crear-transferencia-inversionista`,
            {
              // @ts-ignore
              user_id: store.state.user.id,
              proyecto_id: projectId,
              pasarela_id: 1,
              cod_transaccion: payload.cod,
              // monto: amount,
              // @ts-ignore
              monto: store.state.user.id == 3818 ? 1000 : amount,
              inversion_id: payload.investmentId,
              banco_id: payload.bank,
              nro_cuenta: payload.accountNumber,
              nombre_titular: payload.name,
              cedula_titular: payload.dni,
              fecha: payload.date,
              pago_deta_tipo_id: payload.paymentType
            },
            {
              headers: {
                Authorization: `Bearer ${store.state.token}`
              }
            }
          )
            .then(res => {
              resolve(res.data);
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      first_transfer(){
        return new Promise((resolve,reject) => {
          Axios.get(`${Configuration.api.base}/pagos/primera_transferencia`, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          })
            .then(res => {
              resolve(res);
            })
            .catch(error => {
              reject(error);
            });
        })
      }
    },
    pending: {
      transfer(projectId: number, payload: any) {
        return new Promise((resolve, reject) => {
          Axios.post(
            `${Configuration.api.base}/pagos/crear-transferencia-inversionista`,
            {
              // @ts-ignore
              user_id: store.state.user.id,
              proyecto_id: projectId,
              // @ts-ignore
              monto: store.state.user.id == 3818 ? 1000 : payload.amount,
              // monto: payload.amount,
              inversion_id: payload.investmentId,
              banco_id: payload.bank,
              nro_cuenta: payload.accountNumber,
              nombre_titular: payload.name,
              cedula_titular: payload.dni,
              fecha: payload.date,
              pago_deta_tipo_id: payload.paymentType
            },
            {
              headers: {
                Authorization: `Bearer ${store.state.token}`
              }
            }
          )
            .then(res => {
              resolve(res.data);
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      gateway(payload: any) {
        return new Promise((resolve, reject) => {
          Axios.post(
            `${Configuration.api.base}/transaccion/crear-pago-pendiente-khipu`,
            {
              inversion_id: payload.investmentId,
              monto: payload.amount,
              pago_deta_tipo_id: payload.paymentType,
              transaccion: payload.transaction,
              motivo: payload.subject,
              return_url: window.location.origin + "/gracias-por-invertir",
              cancel_url: window.location.origin + "/pago-erroneo",
              // @ts-ignore
              payer_email: store.state.user.email,
              // @ts-ignore
              payer_name:
                // @ts-ignore
                store.state.user.nombre + " " + store.state.user.app_pat
            },
            {
              headers: {
                Authorization: `Bearer ${store.state.token}`
              }
            }
          )
            .then(res => {
              resolve(res.data);
            })
            .catch(error => {
              reject(error);
            });
        });
      }
    },
    emails: {
      primeraTransferencia(id_transfer:any){
          return new Promise((resolve, reject) => {
            Axios.get(
              `${Configuration.api.base}/pagos/primera_transferencia_mail/${id_transfer}`,
              {
                headers: {
                  Authorization: `Bearer ${store.state.token}`
                }
              }
            )
              .then(res => {
                resolve(res);
              })
              .catch(error => {
                reject(error);
              });
          });
      },
      transferenciaMayorCincoMM(id_transfer:any){
        return new Promise((resolve, reject) => {
          Axios.get(
            `${Configuration.api.base}/pagos/transferencia_5_m_mail/${id_transfer}`,
            {
              headers: {
                Authorization: `Bearer ${store.state.token}`
              }
            }
          )
            .then(res => {
              resolve(res);
            })
            .catch(error => {
              reject(error);
            });
        });
    }
      
    }
  },
  investment: {
    normal(paymentId: number) {
      return new Promise((resolve, reject) => {
        Axios.get(`${Configuration.api.base}/inversion/${paymentId}`, {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        })
          .then(res => {
            resolve(res.data.data);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    pending(paymentId: number) {
      return new Promise((resolve, reject) => {
        Axios.get(`${Configuration.api.base}/inversion/${paymentId}`, {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        })
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    temporal: {
      create() {},
      delete() {},
      all() {}
    },
    initial: {
      create() {},
      all() {}
    },
    all() {}
  },
  user: {
    account: {
      get() {},
      update() {},
      password: {
        update() {}
      }
    },
    terms: {
      get() {}
    }
  },
  dashboard: {
    montoTotalInvertido() {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(
          // @ts-ignore
          `${Configuration.api.base}/estadisticas/montoTotalInvertido/${store.state.user.id}`,
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data.data.monto_invertido);
          })
          .catch(error => {
            reject(null);
          });
      });
    },
    montoTotalRecibidoProyectoCerrado() {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(
          // @ts-ignore
          `${Configuration.api.base}/estadisticas/montoTotalRecibidoProyectoCerrado/${store.state.user.id}`,
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data.data);
          })
          .catch(error => {
            reject(null);
          });
      });
    },
    montoTotalInvertidoPendienteDePago() {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(
          // @ts-ignore
          `${Configuration.api.base}/estadisticas/montoTotalInvertidoPendienteDePago/${store.state.user.id}`,
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data.data.monto_invertido);
          })
          .catch(error => {
            reject(null);
          });
      });
    },
    rentabilidadProyectosPagadosCerrados() {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(
          // @ts-ignore
          `${Configuration.api.base}/estadisticas/rentabilidadProyectosPagadosCerrados/${store.state.user.id}`,
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data.data);
          })
          .catch(error => {
            reject(null);
          });
      });
    },
    rentabilidadProyectosMoraMayor180DiasImpagas() {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(
          // @ts-ignore
          `${Configuration.api.base}/estadisticas/rentabilidadProyectosMoraMayor180DiasImpagas/${store.state.user.id}`,
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data.data);
          })
          .catch(error => {
            reject(null);
          });
      });
    },
    totalMontosInvertidoProyectosActivos() {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(
          // @ts-ignore
          `${Configuration.api.base}/estadisticas/totalMontosInvertidoProyectosActivos/${store.state.user.id}`,
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data.data.monto);
          })
          .catch(error => {
            reject(null);
          });
      });
    },
    montoInvertidoEnMora() {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(
          // @ts-ignore
          `${Configuration.api.base}/estadisticas/montoInvertidoEnMora/${store.state.user.id}`,
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data.data.monto);
          })
          .catch(error => {
            reject(null);
          });
      });
    },
    montoEnMora180Dias() {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(
          // @ts-ignore
          `${Configuration.api.base}/estadisticas/montoEnMora180Dias/${store.state.user.id}`,
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data.data.monto);
          })
          .catch(error => {
            reject(null);
          });
      });
    },
    tasaMontoEnMora180Dias() {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(
          // @ts-ignore
          `${Configuration.api.base}/estadisticas/tasaMontoEnMora180Dias/${store.state.user.id}`,
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data.data);
          })
          .catch(error => {
            reject(null);
          });
      });
    }
  },
  // START ESTADISTICAS
  getTotalAmountInvested() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/montoTotalInvertido/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getTotalAmountInvestedFromClosedProjects() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/montoTotalInvertidoProyectoCerrado/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getTotalAmountReceivedFromClosedProjects() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/montoTotalRecibidoProyectoCerrado/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getTotalUtilityEarnedFromClosedProjects() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/gananciaTotalProyectoCerrado/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getRateOfThreeMonths() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/montosMoraTresMeses/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getRateOfSixMonths() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/montosMoraSeisMeses/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data.monto_mora);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getRateOfThirtyDays() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/vencimientoTreintaDias/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data.monto_por_vencer);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getRateOfSixtyDays() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/vencimientoSesentaDias/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data.monto_por_vencer);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getBeatenRate() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/montoVencidoMora/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data.monto_vencido_mora);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getBeatenBalance() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/saldoInsoluto/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  // END ESTADISTICAS
  getAmounts() {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/montos`)
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  getTransferences() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/transferencias/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error.response);
        });
    });
  },
  getInitialInvestments(projectId: any) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/inversiones/iniciales/${store.state.user.id}/${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createInitialInvestment(payload: any) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/inversiones/crear`,
        {
          // @ts-ignore
          user_id: store.state.user.id,
          proyecto_id: payload.projectId,
          // @ts-ignore
          monto: store.state.user.id == 3818 ? 1000 : payload.amount,
          // inversion: payload.investment,
          reserva: payload.isReserving,
          cancelando_socio: payload.isCancellingClub,
          es_socio: payload.isPartner,
          socio: payload.isJoiningClub,
          inversion_tmp_id: payload.investmentId,
          cod_inversion: payload.code
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getTemporalInvestmentsByUserId() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/inversion-tmp/listado/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getTemporalInvestments(projectId: any) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/inversion-tmp/listado/${store.state.user.id}/${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createTemporalInvestment(tmp: any) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/inversion-tmp/crear`,
        {
          // @ts-ignore
          user_id: store.state.user.id,
          proyecto_id: tmp.project_id,
          // @ts-ignore
          monto: store.state.user.id == 3818 ? 1000 : tmp.amount
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteTemporalInvestments(projectId: number) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.delete(`${Configuration.api.base}/inversion-tmp/eliminar`, {
        data: {
          // @ts-ignore
          user_id: store.state.user.id,
          proyecto_id: projectId
        },
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getClub() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(`${Configuration.api.base}/socio/${store.state.user.id}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getSecretProjects(): Promise<Array<Project>> {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(`${Configuration.api.base}/secretos/${store.state.user.id}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          let projects: Array<ProjectInterface> = res.data.data.proyectos;
          resolve(projects.map(project => new Project(project)));
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getProjects(data: any): Promise<Array<Project>> {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/filtrado`,
        {
          factura: data.factura ? 1 : 0,
          credito: data.credito ? 1 : 0,
          tasa: data.tasa,
          cuota: data.cuota,
          offset: data.offset
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          let projects: Array<ProjectInterface> = res.data;
          if (process.env.VUE_APP_API_ENV == "development") {
            resolve(projects.map(project => new Project(project)));
          } else {
            resolve(
              projects
                .map(project => new Project(project))
                .filter(pro => pro.id != 1)
            );
          }
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getStatistics(projectId: number) {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/proyectoEstadistica/${projectId}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getProject(projectId: number): Promise<ProjectInterface> {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/proyecto/${projectId}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          let project: ProjectInterface = res.data.data;
          // @ts-ignore
          resolve(new Project(project));
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getSecretProject(projectId: number): Promise<ProjectInterface> {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/proyecto/secreto/${projectId}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          let project: ProjectInterface = res.data.data;
          // @ts-ignore
          resolve(new Project(project));
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  addProjectView(projectId: number) {
    return new Promise((resolve, reject) => {
      Axios.post(`${Configuration.api.base}/proyecto/visita`, {
        proyecto_id: projectId
      })
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getProjectAmounts(projectId: number) {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${Configuration.api.base}/proyecto-montos-total/${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  simulate(projectId: number, amount: number, last: any, id?: any) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/simular`,
        {
          proyecto_id: projectId,
          monto: amount,
          user: id,
          ultimo: last
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  login(rut: string, password: string) {
    return new Promise((resolve, reject) => {
      Axios.post(`${Configuration.api.base}/login`, {
        rut: DniTrim.string(rut),
        password: password
      })
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  register(
    token:any,
    nombre: string,
    app_pat: string,
    email: string,
    password: string,
    c_password: string,
    dni: string,
    via_id:number,
    telefono?: string,
    user_tipo_id?: number,
    app_mat?: string,
  ) {
    return new Promise((resolve, reject) => {
      Axios.post(`${Configuration.api.base}/register`, {
        "g-recaptcha-response" : token,
        nombre: nombre,
        app_pat: app_pat,
        email: email,
        password: password,
        c_password: c_password,
        rut: DniTrim.string(dni),
        via_id: via_id,
        telefono: telefono,
        user_tipo_id: user_tipo_id,
        app_mat: app_mat
      })
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  logout() {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/logout`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getBanks() {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/banco`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getAccountTypes() {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/bancos/tipos`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getCompany(companyId: number) {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/empresa/${companyId}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getCountries() {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/pais`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getRegions(countryId?: number) {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/region`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          if (countryId == undefined) {
            resolve(res.data.data);
          } else {
            resolve(res.data.data.filter((el: any) => el.pais_id == countryId));
          }
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getCommunes(regionId: number) {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/comuna/region/${regionId}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  async listar_clasificaciones(){
    const listar_clasificaciones =await Axios.get(`${Configuration.api.base}/listar_clasificaciones`,
    { 
      headers: { 
        Authorization: `Bearer ${store.state.token}`
      } 
    })
    return listar_clasificaciones.data
  },
  async getClasificacion(id:any){
    const getClasificacion =await Axios.get(`${Configuration.api.base}/getClasificacion/${id}`,
    { 
      headers: { 
        Authorization: `Bearer ${store.state.token}`
      } 
    })
    return getClasificacion.data
  },
  contact(token: string, name: string, mail: string, telefono: string, message: string, clasificacion_id? : number,dato_adicional? :any, origen_reclamo?:any) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/contacto`,
        {
          nombre: name,
          mail: mail,
          telefono,
          mensaje: message,
          clasificacion_id,
          dato_adicional,
          origen_reclamo,
          "g-recaptcha-response" : token
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          resolve(error.response.data);
        });
    });
  },
  recover(token:string,rut: string) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/password/create`,
        {
          "g-recaptcha-response":token,
          rut
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  newPassword(
    rut: string,
    password: string,
    c_password: string,
    token: string
  ) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/password/reset`,
        {
          rut: DniTrim.string(rut),
          password: password,
          c_password: c_password,
          token: token,
        },
        {
          headers: {}
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  credit(creditPayload: any) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/credito/solicitar`,
        {
          rut: DniTrim.string(creditPayload.rut),
          empresa: creditPayload.name,
          telefono: creditPayload.phone,
          email: creditPayload.email,
          credito_necesidad_id: creditPayload.needSelected,
          credito_venta_id: creditPayload.salesSelected,
          credito_garantia_id: creditPayload.propertyWarrantySelected,
          credito_objetivo_id: creditPayload.averageSelected,
          deuda: creditPayload.debt,
          propiedad: creditPayload.propertyCost,
          propiedad_deuda: creditPayload.propertyDebt,
          propiedad_tipo: creditPayload.propertyWarrantySelected
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getDuenosbusiness(empresa_id: any){
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/empresa_dueno/${empresa_id}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createBusiness(payload: any) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.post(`${Configuration.api.base}/empresa`, payload, {
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${store.state.token}`
        }
        /* onUploadProgress: callback, */
      })
        .then((res: any) => {
          resolve(res.data.data);
        })
        .catch((error: any) => {
          reject(error.response);
        });
    });
  },
  stateBusiness(empresa_id:any){
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/empresa_estado/${empresa_id}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  closedstateBusiness(empresa_id:any){
    return new Promise((resolve, reject) => {
      Axios.post(`${Configuration.api.base}/cerrar_solicitud/${empresa_id}`,{} ,{
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createsolicitud(empresa_id:any, 
        nombre: string,
        rut: string,
        descripcion: string,
        telefono: string,
        direccion: string,
        empresa_target_id: string,
        tiempo_experiencia: string,
        sc_3:number,
        tiempo_local: string,
        sc_4:number,
        a_quien_vendes: string,
        sc_8: string,
        deuda_sin_hipoteca: string,
        deuda_hoy: string,
        alguna_hipoteca: string,
        clavesii?: string,
        razon_social?: string,
        giro?: number,
        web?: string,
        fecha_inicio_actividades?: string,
        categoria?:any,
        tramo_id?:number,
        cant_trabajadores?:number,
        proveedores_comuna?:string,
        region_id?:number,
        comuna_id?:number,
        pais_id?:number,){
    return new Promise((resolve, reject) => {
      Axios.post(`${Configuration.api.base}/crear_solicitud/${empresa_id}`,{
        // @ts-ignore
        user_id: store.state.user.id,
        nombre,
        rut,
        descripcion,
        telefono,
        direccion,
        empresa_target_id,
        tiempo_experiencia,
        sc_3,
        tiempo_local,
        sc_4,
        a_quien_vendes,
        sc_8,
        deuda_sin_hipoteca,
        deuda_hoy,
        alguna_hipoteca,
        clavesii,
        razon_social,
        giro,
        web,
        fecha_inicio_actividades,
        categoria,
        cant_trabajadores,
        proveedores_comuna,
        tramo_id,
        region_id,
        comuna_id,
        pais_id,
      } ,{
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  empresadueno(empresa_id:any){
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/empresa_dueno/${empresa_id}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  editCompany(company: any) {
    return new Promise((resolve, reject) => {
      let excludes = [
        "id",
        "ejecutivo_id",
        "credito_venta_id",
        "imagen_id",
        "fecha_creacion",
        "deuda",
        "afecto_iva",
        "carpeta"
      ];

      let payload: any = {};

      for (let key in company) {
        if (key.endsWith("ed_at") || excludes.includes(key)) continue;

        payload[key] = company[key];
      }

      Axios.put(`${Configuration.api.base}/empresa/${company.id}`, payload, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(true);
        })
        .catch(error => {
          reject(false);
        });
    });
  },
  requestsByCompanyId(empresa_id: number) {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/solicitudes-empresa/${empresa_id}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  projectsByCompanyId(empresa_id: number) {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/proyectos-empresa/${empresa_id}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  request: {
    factoring(empresa_id: string, facturas: any[]) {
      return new Promise((resolve, reject) => {
        let payload: any = {
          // @ts-ignore
          user_id: store.state.user.id,
          empresa_id,
          facturas
        };
        Axios.post(`${Configuration.api.base}/solicitud/factoring`, payload, {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        })
          .then(res => {
            resolve(res.data.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
    credit(
      empresa_id: string,
      motivo: string,
      monto: string,
      plazo: number,
      garantia: any
    ) {
      return new Promise((resolve, reject) => {
        let payload: any = {
          // @ts-ignore
          user_id: store.state.user.id,
          empresa_id,
          motivo,
          monto,
          plazo
        };

        if (garantia != null) {
          payload["garantia"] = garantia;
        }

        Axios.post(`${Configuration.api.base}/solicitud/credito`, payload, {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        })
          .then(res => {
            resolve(res.data.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
    leaseback(
      empresa_id: string,
      motivo: string,
      monto: string,
      plazo: number,
      garantia?: any
    ) {
      return new Promise((resolve, reject) => {
        Axios.post(
          `${Configuration.api.base}/solicitud/leaseback`,
          {
            // @ts-ignore
            user_id: store.state.user.id,
            empresa_id,
            motivo,
            monto,
            plazo,
            garantia
          },
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
    accion(
      empresa_id: string,
      motivo: string,
      monto: string,
      plazo: number,
      garantia: any,
      accion_pyme_id?: any
    ) {
      return new Promise((resolve, reject) => {
        let payload: any = {
          // @ts-ignore
          user_id: store.state.user.id,
          empresa_id,
          motivo,
          monto,
          plazo,
          accion_pyme_id
        };

        Axios.post(`${Configuration.api.base}/solicitud/credito`, payload, {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        })
          .then(res => {
            resolve(res.data.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    }
  },
  requestCredit(
    empresa_id: string,
    type_id: string,
    rut: string,
    monto: number,
    nombre: string,
    plazo: number,
    garantia_id?: number,
    motivo?: string
  ) {
    return new Promise((resolve, reject) => {
      let payload: any = {
        // @ts-ignore
        user_id: store.state.user.id,
        empresa_id: empresa_id,
        tipo_id: type_id,
        rut,
        garantia_id,
        monto,
        nombre,
        plazo,
        motivo
        // facturas: [
        //   {
        //     folio,
        //     rut,
        //     monto,
        //     nombre,
        //     plazo,
        //   },
        // ],
        // garantia: {
        //   monto,
        //   garantia_id,
        //   plazo,
        //   valor_garantia,
        //   deuda_garantia,
        // }
      };

      Axios.post(`${Configuration.api.base}/solicitud`, payload, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  getMyInvestments(): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/inversiones/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data[0]);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getMyStates(): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/inversiones/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data[1]);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getUserBank() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/usuario/banco/id`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          if (res.data.data.cuenta_bancaria != null) {
            resolve(res.data.data.cuenta_bancaria);
          } else {
            reject(null);
          }
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  updateBankAccount(
    accountNumber: string,
    bank_id: number,
    bankAccountType: number
  ) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/usuario/banco`,
        {
          // @ts-ignore
          numero: accountNumber,
          banco_id: bank_id,
          tipo_cuenta_id: bankAccountType
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve({
            message: "ok"
          });
        })
        .catch(error => {
          reject({
            error
          });
        });
    });
  },
  getPendingPayments(): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/inversiones/${store.state.user.id}/pendiente`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          let payments: Array<any> = res.data.data;
          resolve(payments);
        })
        .catch(error => {
          reject([]);
        });
    });
  },
  AnularPendingPayments(id:any,comentarios:any){
      return new Promise((resolve, reject)=>{
        Axios.post(`${Configuration.api.base}/inversiones/inversionista_anula`, {
          inversion_proyecto_id: id,
          texto:comentarios
        }, {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }).then(res=>{
          resolve(res)
        }).catch(error => {reject(error)})
      })
  },
  getCancelPayments(){
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/inversionista_anulaciones`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          let payments: Array<any> = res.data.data;
          resolve(payments);
        })
        .catch(error => {
          reject([]);
        });
    });
  },
  async detalle_cuotas(){
    /* let detalle_cuotas = await Axios.get(`${Configuration.api.base}/detalle_cuotas`,{
      headers: {
        Authorization: `Bearer ${store.state.token}`
      }
    })
    return detalle_cuotas.data */
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/detalle_cuotas`,{
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      }).then(res => {
        let detalle_cuotas: Array<any> = res.data.data;
        resolve(detalle_cuotas);
      }).catch(error => {
        reject([]);
      })
    })

  },
  documents: {
    contracts() {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(
          // @ts-ignore
          `${Configuration.api.base}/documentos/${store.state.user.id}/contratos`,
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            if (res.data.errors != undefined) {
              resolve([]);
            } else {
              resolve(res.data.data.contratos);
            }
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
    
    terms() {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(
          // @ts-ignore
          `${Configuration.api.base}/documentos/${store.state.user.id}/mandatos`,
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            if (res.data.errors != undefined) {
              resolve([]);
            } else {
              resolve(res.data.data.contratos);
            }
          })
          .catch(error => {
            reject(null);
          });
      });
    },
    ious() {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.get(
          // @ts-ignore
          `${Configuration.api.base}/documentos/${store.state.user.id}/pagares`,
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            if (res.data.errors != undefined) {
              resolve([]);
            } else {
              resolve(res.data.data.contratos);
            }
          })
          .catch(error => {
            reject(null);
          });
      });
    }
  },
  getUniqueCode() {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/codigo`).then(res => {
        resolve(res.data);
      });
    });
  },
  updatePersonalData(user: any) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.put(
        // @ts-ignore
        `${Configuration.api.base}/usuario/${store.state.user.id}`,
        user,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getPersonalData(userId: number) {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/usuario/${userId}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  changePassword(
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
  ) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/usuario/password`,
        {
          password: oldPassword,
          password_new: newPassword,
          password_c: confirmPassword
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getBusiness() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      Axios.get(
        // @ts-ignore
        `${Configuration.api.base}/empresa/usuario/${store.state.user.id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data.empresas);
        })
        .catch(error => {
          reject(null);
        });
    });
  },
  getBusiness2(user_id:any) {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/empresa/usuario/${user_id}`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data.data.empresas);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getQuote(amount: number, month: number) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/simulacion/accion`,
        {
          capital: amount,
          tiempo_meses: month
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  actionSimulate(simulate: any) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/simulacion/accion/email`,
        {
          monto: simulate.amount,
          email: simulate.mail,
          cuota: simulate.quote,
          plazo: simulate.month,
          tasa_interes: simulate.tasa_interes
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  simulate_day(ip:any){
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/tracking/simular_dia/${ip}`, {
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  landing: {
    chilecompra(
      name: string,
      mail: string,
      message: string,
      phone: string,
      rut_empresa: any,
      nombre_empresa: any
    ) {
      return new Promise((resolve, reject) => {
        Axios.post(
          `${Configuration.api.base}/landing/chilecompra`,
          {
            nombre: name,
            mail: mail,
            rut_empresa: DniTrim.string(rut_empresa),
            nombre_empresa: nombre_empresa,
            mensaje: message,
            telefono: phone
          },
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            reject(error.response.data);
          });
      });
    },
    landing(name: string, mail: string, phone: string, via: string) {
      return new Promise((resolve, reject) => {
        Axios.post(`${Configuration.api.base}/landingcrm`, {
          nombre: name,
          email: mail,
          telefono: phone,
          via: via
        })
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    santander(
      name: string,
      lastname: string,
      dni: any,
      businessName: string,
      businessDni: any,
      mail: string,
      landing: string,
      via: string,
      phone?: string,
      amount?: number,
      comment?: string
    ) {
      return new Promise((resolve, reject) => {
        Axios.post(`${Configuration.api.base}/landingSolicitantes`, {
          nombre: name,
          apellido: lastname,
          rut: DniTrim.string(dni),
          nombre_empresa: businessName,
          rut_empresa: DniTrim.string(businessDni),
          telefono: phone,
          email: mail,
          landing: landing,
          via: via,
          monto_factura: amount,
          comentario_banco: comment
        })
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    bcp(
      name: string,
      lastname: string,
      dni: any,
      businessName: string,
      businessDni: any,
      mail: string,
      landing: string,
      via: string,
      phone?: string,
      amount?: number,
      comment?: string
    ) {
      return new Promise((resolve, reject) => {
        Axios.post(`${Configuration.api.base}/landingSolicitantes`, {
          nombre: name,
          apellido: lastname,
          rut: DniTrim.string(dni),
          nombre_empresa: businessName,
          rut_empresa: DniTrim.string(businessDni),
          telefono: phone,
          email: mail,
          landing: landing,
          via: via,
          monto_factura: amount,
          comentario_banco: comment
        })
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    banigualdad(
      name: string,
      lastname: string,
      dni: any,
      businessName: string,
      businessDni: any,
      mail: string,
      landing: string,
      via: string,
      phone?: string,
      amount?: number,
      comment?: string
    ) {
      return new Promise((resolve, reject) => {
        Axios.post(`${Configuration.api.base}/landingFactoring`, {
          nombre: name,
          apellido: lastname,
          rut: DniTrim.string(dni),
          nombre_empresa: businessName,
          rut_empresa: DniTrim.string(businessDni),
          telefono: phone,
          email: mail,
          landing: landing,
          via: via,
          monto_factura: amount,
          comentario_banco: comment
        })
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    factoring(
      name: string,
      lastname: string,
      dni: any,
      mail: string,
      landing: string,
      via: string,
    ) {
      return new Promise((resolve, reject) => {
        Axios.post(`${Configuration.api.base}/landingFactoring`, {
          nombre: name,
          apellido: lastname,
          rut: DniTrim.string(dni),
          email: mail,
          landing: landing,
          via: via,
        })
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  customContact(
    name: string,
    mail: string,
    message: string,
    phone: string,
    rut_empresa: any,
    nombre_empresa: any,
    landing: any,
    type: any
  ) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/landing`,
        {
          nombre: name,
          mail: mail,
          rut_empresa: DniTrim.string(rut_empresa),
          nombre_empresa: nombre_empresa,
          mensaje: message,
          telefono: phone,
          landing: landing,
          clasificacion_id: type
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  chilecompra(
    name: string,
    mail: string,
    message: string,
    phone: string,
    type: any
  ) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/contacto`,
        {
          nombre: name,
          mail: mail,
          mensaje: message,
          telefono: phone,
          clasificacion_id: type
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error.response.data);
        });
    });
  },
  scheduleCall(message: string, phone: string) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/contacto`,
        {
          // @ts-ignore
          nombre: `${store.state.user.nombre} ${store.state.user.app_pat}`,
          // @ts-ignore
          mail: store.state.user.email,
          mensaje: message,
          telefono: phone,
          clasificacion_id: 1
        },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
      )
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          resolve(error.response.data);
        });
    });
  },
  declarationes(user_id:number){
    return new Promise((resolve,reject)=>{
      Axios.get(`${Configuration.api.base}/documentos/${user_id}/declaraciones`,{
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    })
  },
  declaration(user_id:number, anio:any){
    return new Promise((resolve,reject)=>{
      Axios.get(`${Configuration.api.base}/documentos/${user_id}/declaracion/${anio}`,{
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    })
  },
  accion: {
    eleven: {
      howMuch() {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.get(`${Configuration.api.base}/cuanto_vendes`, {})
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      anualSales() {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.get(`${Configuration.api.base}/ventas_anio_anterior`, {})
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      nextYear() {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.get(`${Configuration.api.base}/proximo_anio`, {})
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      ifYouHave() {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.get(`${Configuration.api.base}/disponible_millones`, {})
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      debts() {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.get(`${Configuration.api.base}/deuda_hoy`, {})
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      toWho() {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.get(`${Configuration.api.base}/a_quien_debes`, {})
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      houseDebt() {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.get(`${Configuration.api.base}/credito_hipotecario`, {})
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      amountDebt() {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.get(`${Configuration.api.base}/cuanto_debe_total`, {})
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      ifGetCredit() {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.get(`${Configuration.api.base}/credito_disponible`, {})
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      ifGetGifted() {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.get(`${Configuration.api.base}/te_regalan`, {})
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      dreamingCredit() {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.get(`${Configuration.api.base}/elegir_credito`, {})
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      updateData(payload: any) {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.put(
            // @ts-ignore
            `${Configuration.api.base}/usuario/${store.state.user.id}`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${store.state.token}`
              }
            }
          )
            .then(res => {
              resolve(res.data);
            })
            .catch(error => {
              reject(null);
            });
        });
      },
      business(
        nombre: string,
        rut: string,
        descripcion: string,
        telefono: string,
        direccion: string,
        empresa_target_id: string,
        tiempo_experiencia: string,
        sc_3:number,
        tiempo_local: string,
        sc_4:number,
        a_quien_vendes: string,
        sc_8: string,
        deuda_sin_hipoteca: string,
        deuda_hoy: string,
        alguna_hipoteca: string,
        clavesii: string,
        razon_social?: string,
        giro?: number,
        web?: string,
        fecha_inicio_actividades?: string,
        categoria?:any,
        tramo_id?:number,
        cant_trabajadores?:number,
        proveedores_comuna?:string,
        region_id?:number,
        comuna_id?:number,
        pais_id?:number,
      ) {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.post(
            `${Configuration.api.base}/empresa`,
            {
              // @ts-ignore
              user_id: store.state.user.id,
              nombre,
              rut,
              descripcion,
              telefono,
              direccion,
              empresa_target_id,
              tiempo_experiencia,
              sc_3,
              tiempo_local,
              sc_4,
              a_quien_vendes,
              sc_8,
              deuda_sin_hipoteca,
              deuda_hoy,
              alguna_hipoteca,
              clavesii,
              razon_social,
              giro,
              web,
              fecha_inicio_actividades,
              categoria,
              cant_trabajadores,
              proveedores_comuna,
              tramo_id,
              region_id,
              comuna_id,
              pais_id,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.token}`
              }
            }
          )
            .then((res: any) => {
              resolve(res);
            })
            .catch((error: any) => {
              reject(error.response);
            });
        });
      },
      business_sii(
        clavesii: string,
        empresa_id:number
      ) {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.post(
            `${Configuration.api.base}/clave/${empresa_id}`,
            {
              // @ts-ignore
              user_id: store.state.user.id,
              clavesii,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.token}`
              }
            }
          )
            .then((res: any) => {
              resolve(res.data.data);
            })
            .catch((error: any) => {
              reject(error.response);
            });
        });
      },
      request(
        empresa_id: string,
        motivo: string,
        monto: string,
        plazo: number,
        sc_7: number,
        tramo_id:number,
        destino?: string,
        sc_evaluacion_id?: any,
        monto_ideal?:string,
        me_permitira?:string,
      ) {
        return new Promise((resolve, reject) => {
          let payload: any = {
            // @ts-ignore
            user_id: store.state.user.id,
            empresa_id,
            motivo,
            monto,
            plazo,
            sc_7,
            tramo_id,
            sc_evaluacion_id,
            destino,
            monto_ideal,
            me_permitira
          };
          Axios.post(`${Configuration.api.base}/solicitud/credito_sg`, payload, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          })
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(error.response.data);
            });
        });
      },
      requestanglo( // TODO: AGREGAR location: string con valor 'anglo' y en scoring con valor null
        empresa_id: string,
        motivo: string,
        monto: string,
        plazo: number,
        sc_7: number,
        tramo_id:number,
        location?:string,
        destino?: string,
        monto_ideal?:string,
        me_permitira?:string,
      ) {
        return new Promise((resolve, reject) => {
          let payload: any = {
            // @ts-ignore
            user_id: store.state.user.id,
            empresa_id,
            motivo,
            monto,
            plazo,
            sc_7,
            tramo_id,
            location,
            destino,
            monto_ideal,
            me_permitira,
          };
          Axios.post(`${Configuration.api.base}/solicitud/credito_sg`, payload, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          })
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(error.response.data);
            });
        });
      },
      owners(owners: any, empresa_id:any,sc_evaluacion_id?:any) {
        return new Promise((resolve, reject) => {
          Axios.post(`${Configuration.api.base}/empresa_dueno`, {
            owners,
            empresa_id,
            sc_evaluacion_id
          }, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          })
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(error.response.data);
            });
        });
      },
      meetYou(
        first: string,
        second: string,
        third: string,
        eighth: string,
        nineth: string,
        twelfth?: string,
        fourth?: string,
        fifth?: string,
        seventh?: string,
        tenth?: string,
        eleventh?: string
      ) {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.post(`${Configuration.api.base}/preguntas/accion`, {
            // @ts-ignore
            user_id: store.state.user.id,
            first: first,
            second: second,
            third: third,
            fourth: fourth,
            fifth: fifth,
            seventh: seventh,
            eighth: eighth,
            nineth: nineth,
            tenth: tenth,
            eleventh: eleventh,
            twelfth: twelfth
          },{
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          })
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      carpeta(folder: any, empresa: any) {
        return new Promise((resolve, reject) => {
          let payload: any = {
            carpeta: folder
          }
          // @ts-ignore
          Axios.post(`${Configuration.api.base}/carpeta/${empresa}`, payload, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          })
            .then((res: any) => {
              resolve(res.data.data);
            })
            .catch((error: any) => {
              reject(error.response);
            });
        });
      },
      submitQuestions(
        first: string,
        second: string,
        third: string,
        fourth: string,
        fifth: string,
        seventh: string,
        eighth: string,
        nineth: string,
        tenth: string,
        eleventh: string
      ) {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.post(`${Configuration.api.base}/preguntas/accion`, {
            // @ts-ignore
            user_id: store.state.user.id,
            first: first,
            second: second,
            third: third,
            fourth: fourth,
            fifth: fifth,
            seventh: seventh,
            eighth: eighth,
            nineth: nineth,
            tenth: tenth,
            eleventh: eleventh
          })
            .then(res => {
              resolve(res.data.data);
            })
            .catch(error => {
              reject(error);
            });
        });
      },postReferidos(referidos:any,empresaid:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/sc_referidos/${empresaid}`,referidos,{
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(err =>{
            reject(err)
          })
        })
      },
      referido(codigo_enlace:any,
        rut:any,
        t_relacion_id: any,
        sc_tiempo_id: any,
        familiar: any,
        sc_13: any,
        sc_14: any
        ){
        return new Promise((resolve,reject)=>{
          Axios.put(`${Configuration.api.base}/referido/${codigo_enlace}`,{
            rut,
            t_relacion_id,
            sc_tiempo_id,
            familiar,
            sc_13,
            sc_14
          },{
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(err =>{
            reject(err)
          })
      })
    },
    getReferidos(id: any){
      return new Promise((resolve,reject)=>{
        Axios.get(`${Configuration.api.base}/referido/datos/${id}`,{
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }).then(res=>{
          resolve(res)
        }).catch(err =>{
          reject(err)
        })

      })
    },
    ultimopaso(empresaid:any){
        return new Promise((resolve,reject)=>{
          Axios.get(`${Configuration.api.base}/ultimoPaso/${empresaid}`,{
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(err =>{
            reject(err)
          })
  
        })
  
      },
      async Is_Referidos(id: any){
        const referidos =await Axios.get(`${Configuration.api.base}/verificar_referidos/${id}`,
        { 
          headers: { 
            Authorization: `Bearer ${store.state.token}`
          } 
        })
        return referidos.data
      },
      async qconocerte_anglo(){
        const preguntas =await Axios.get(`${Configuration.api.base}/scoring/preguntas/2/3.5`,
        { 
          headers: { 
            Authorization: `Bearer ${store.state.token}`
          } 
        })
        return preguntas.data
      },
      async qconocerte_scoring(){
        const preguntas =await Axios.get(`${Configuration.api.base}/scoring/preguntas/1/2`,
        { 
          headers: { 
            Authorization: `Bearer ${store.state.token}`
          } 
        })
        return preguntas.data
      },
      async guardar_anglo(respuestas:any){
        const guardar_anglo =await Axios.post(`${Configuration.api.base}/scoring/evaluacion/generar`,
        
          respuestas
        ,
        { 
          headers: { 
            Authorization: `Bearer ${store.state.token}`
          } 
        })
        return guardar_anglo.data
      }
    },
    uploadImages( user_id: any, empresa_id: any, images: any){
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('user_id', user_id);
        formData.append('empresa_id', empresa_id);

        images.map((foto: any) => {
          formData.append('imagenes[]', foto, foto.name);
        });

        Axios.post(
          `${Configuration.api.base}/respaldo_empresa`,
          //'https://release.redcapital.cl/api/respaldo_empresa',
          formData,
          {
						headers: {
							Authorization: `Bearer ${store.state.token}`
						}
					}
        ).then(res => {
          resolve(res);
        }).catch(error => {
          resolve(error);
        })
      })
    }
  },
  actividad:{
    organiza:{
      guardarlistado(answers:any){
        return new Promise((resolve, reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta11`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => {reject(error)})
        })
      },
      listadosituacion(answers:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta12`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => {reject(error)})
        })
      },guardarfirma(answers:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta13`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => {reject(error)})
        })
      },guardarfinanzas(answers:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta14`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => {reject(error)})
        })
      }
    },costos:{
      comprension(answers:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta21`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => reject(error))
        })
      },dejastedevender(answers:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta23`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => reject(error))
        })
      },subirimg(data:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta22`, 
             { 
               data
             },{
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => reject(error))
        })
      },prodestrella(answers:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta24`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => reject(error))
        })
      }
    },metascortoplazo:{
      objetivosmes(answers:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta31`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => reject(error))
        })
      },objetivosanio(answers:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta32`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => reject(error))
        })
      },objetivobussiness(answers:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta34`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => reject(error))
        })
      }
    },gestionadeuda:{
      calcular(answers:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta41`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => reject(error))
        })
      },capacidadpago(answers:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta42`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => reject(error))
        })
      },vendescredito(answers:any){
        return new Promise((resolve,reject)=>{
          Axios.post(`${Configuration.api.base}/cta/cta43`, answers, {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }).then(res=>{
            resolve(res)
          }).catch(error => reject(error))
        })
      }
    },infoactividades:{
      actividadesrealizadas(id:any){
        return new Promise((resolve, reject) => {
          // @ts-ignore
          Axios.get(`${Configuration.api.base}/cta/advance/${id}`,{ headers: {
            Authorization: `Bearer ${store.state.token}`
          }
          })
            .then(res => {
              resolve(res);
            })
            .catch(error => {
              reject(null);
            });
        });
      }
    }
  },
  factoring: {
    sii(businesSelected_id:any,password:any,c_password:any){
      return new Promise((resolve,reject)=>{
        Axios.post(`${Configuration.api.base}/sii-configurations/${businesSelected_id}/password`,{
          password: password,
          password_confirmation: c_password,
        },{
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        })
        .then((res)=>{
          resolve(res)
        })
        .catch((err)=>{
          reject(err)
        })
      })
    },
    certificado_sii(businesSelected_id:any,archivo:any,c_password:any){
      return new Promise((resolve,reject)=>{
        let formData = new FormData();
        archivo.forEach((f:any) => {
          formData.append('original', f);
        });
        formData.append('password', c_password);
        Axios.post(`${Configuration.api.base}/empresas/${businesSelected_id}/certificadosDigitales/upload`,
         formData
        ,
          { 
            headers: { 
              Authorization: `Bearer ${store.state.token}`,
              'Content-Type': 'multipart/form-data'
            } 
          }
        )
        .then((res)=>{
          resolve(res)
        })
        .catch((err)=>{
          reject(err)
        })
      })
    },
    factureUploads(businesSelected_id:any,archivo:any){
      return new Promise((resolve,reject)=>{
        let formData = new FormData();
        archivo.forEach((el: any)=>{
          formData.append('xml[]',el)
        })
        Axios.post(`${Configuration.api.base}/empresas/${businesSelected_id}/facturasSubidas/upload`,
        formData
        ,
          { 
            headers: { 
              Authorization: `Bearer ${store.state.token}`,
              'Content-Type': 'multipart/form-data'

            } 
          }
        )
        .then((res)=>{
          resolve(res)
        })
        .catch((err)=>{
          reject(err)
        })
      })
    },
    cederFacture(businesSelected_id:any,id_factura:any){
      return new Promise((resolve,reject)=>{
        Axios.post(`${Configuration.api.base}/empresas/${businesSelected_id}/facturasSubidas/${id_factura}/ceder`, {},
        { 
          headers: { 
            Authorization: `Bearer ${store.state.token}`
          } 
        }
        ).then((res)=>{
          resolve(res)
        }).catch((err)=>{
          reject(err)
        })
      })
    },
    async cederFacturasMasivo(businesSelected_id:any,facturas:any){
      const ceder = await Axios.post(`${Configuration.api.base}/ceder_masivo/${businesSelected_id}`, 
        {facturas},
        { 
          headers: { 
            Authorization: `Bearer ${store.state.token}`
          } 
        }
      );
      return ceder.data;
    },
    async listarFacturasParaCeder(businesSelected_id:any){
      const listado_faturas = await Axios.get(`${Configuration.api.base}/empresas/${businesSelected_id}/facturasSubidas/ceder`, 
        { 
          headers: { 
            Authorization: `Bearer ${store.state.token}`
          } 
        }
      );
      return listado_faturas.data;
    },
    listarFacturasCargadas(businesSelected_id:any){
      return new Promise((resolve,reject)=>{
        Axios.get(`${Configuration.api.base}/empresas/${businesSelected_id}/facturasSubidas`,{
           headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }).then((res)=>{
          resolve(res)
        })
        .catch((err)=>{
          reject(err)
        })
      })
    },
    listarFacturasCargadasFiltradas(businesSelected_id:any){
      return new Promise((resolve,reject)=>{
        Axios.get(`${Configuration.api.base}/empresas/${businesSelected_id}/facturasSubidas/filtradas`,{
           headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }).then((res)=>{
          resolve(res)
        })
        .catch((err)=>{
          reject(err)
        })
      })
    },
    cargarFactura(businesSelected_id:any,formData:any){
      return new Promise((resolve,reject)=>{
        Axios.post(`${Configuration.api.base}/empresas/${businesSelected_id}/facturasSubidas/upload`, formData,
        { headers: {
          Authorization: `Bearer ${store.state.token}`,
          "X-Requested-With": "XMLHttpRequest",
        }, }
        ).then((res)=>{
          resolve(res)
        }).catch((err)=>{
          reject(err)
        })
      })
    },
    carga_masiva_facturas_sin_proyecto(formData:any){
      return new Promise((resolve,reject)=>{
        Axios.post(`${Configuration.api.base}/carga_masiva_facturas_sin_proyecto`, formData,
        { headers: {
          Authorization: `Bearer ${store.state.token}`,
        }, }
        ).then((res)=>{
          resolve(res)
        }).catch((err)=>{
          reject(err)
        })
      })
    },
    
    sincronizar_emitidos(rut:any){
     return new Promise((resolve,reject)=>{
      Axios.get(`${Configuration.api.base}/sii/${rut}/sincronizar_emitidos`,{
        headers: {
         Authorization: `Bearer ${store.state.token}`
       }
      }).then((res)=>{
        resolve(res)
      })
      .catch((err)=>{
        reject(err)
      })
     })
    },
    async lista_parametros_cotizacion(){
      const parametros = await Axios.get(`${Configuration.api.base}/lista_parametros_cotizacion`,{
          headers: {
           Authorization: `Bearer ${store.state.token}`
         }
        })
      return parametros.data;
    },
    async date_parametros_onboarding(){
      const dates = await Axios.get(`${Configuration.api.base}/cotizacion/params/onboarding`,{
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      })
      return dates.data;

    },
    crear_cotizacion(cotizacion:any){
      return new Promise((resolve,reject)=>{
        Axios.post(`${Configuration.api.base}/crear_cotizacion`, 
          cotizacion,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        }
        ).then((res)=>{
          resolve(res)
        }).catch((err)=>{
          reject(err)
        })
      })
    },
    async mis_cotizaciones(empresa_id:any){
      const mis_cotizaciones = await Axios.get(`${Configuration.api.base}/mis_cotizaciones/${empresa_id}`,{
        headers: {
        Authorization: `Bearer ${store.state.token}`
      }
      })
      return mis_cotizaciones.data
    },
    async get_notificaciones(){
      const info_user_notificacion = await Axios.get(`${Configuration.api.base}/info_user_notificacion`,
      {   
        headers :{
          Authorization: `Bearer ${store.state.token}`
        }
      })
      return info_user_notificacion;
    },
    async get_cuentas(id_empresa:any){
      const listar_mis_cuentas = await Axios.get(`${Configuration.api.base}/listar_mis_cuentas`, 
      {
        params:{
          empresa_id:id_empresa
        },
        headers :{
          Authorization: `Bearer ${store.state.token}`
        }
      })
      return listar_mis_cuentas.data;
    },
    async get_cuenta_sii(dni:any){
       const cuenta_sii = await Axios.get(`${Configuration.api.base}/obtener_cuenta_sii`,
      { params:{
          rut:dni
        },
        headers :{
          Authorization: `Bearer ${store.state.token}`
        }
      })
      return cuenta_sii;
    },
    async get_cuenta_previred(dni:any){
      const cuenta_previred = await Axios.get(`${Configuration.api.base}/obtener_cuenta_previred`,
     {  
       params:{
        rut:dni
      },
       headers :{
         Authorization: `Bearer ${store.state.token}`
       }
     })
     return cuenta_previred;
    },
    async get_cuenta_tgr(dni:any){
      const obtener_cuenta_tgr = await Axios.get(`${Configuration.api.base}/obtener_cuenta_tgr`,
      { 
        params:{
        rut:dni
      },
        headers :{
          Authorization: `Bearer ${store.state.token}`
        }
      })
      return obtener_cuenta_tgr;
    },
    async guardar_cuenta_tgr(dni:any,clave_tgr:any){
      const guardar_cuenta_tgr = await Axios.post(`${Configuration.api.base}/agregar_cuenta_tgr`,
      {
        "rut":dni,
        "clave": clave_tgr
      },
      {
        headers: {
        Authorization: `Bearer ${store.state.token}`
      }
      })
      return guardar_cuenta_tgr.data
    },
    async guardar_cuenta_previred(dni:any,clave_tgr:any){
      const agregar_cuenta_previred = await Axios.post(`${Configuration.api.base}/agregar_cuenta_previred`,
      {
        "rut":dni,
        "clave": clave_tgr
      },
      {
        headers: {
        Authorization: `Bearer ${store.state.token}`
      }
      })
      return agregar_cuenta_previred.data
    },
    async guardar_actualizar_notificacion(mail:any,whatsapp:any,news:any){
      const actualizar_notificacion = await Axios.post(`${Configuration.api.base}/actualizar_notificacion`,
      {
        "whatsapp": whatsapp,
        "news": news,
        "mail": mail
      },
      {
        headers: {
        Authorization: `Bearer ${store.state.token}`
      }
      })
      return actualizar_notificacion.data
    },
    async guardar_cuenta_banco(cuenta:any){
      const cuenta_banco = await Axios.post(`${Configuration.api.base}/crear_cuenta_bancaria_usuario`,
      {
        "banco_id": cuenta.banco_id,
        "cuenta_tipo_id":cuenta.tipo_id,
        "numero": cuenta.numero,
        "empresa_id": cuenta.empresa_id,
        "alias": cuenta.alias
      },
      {
        headers: {
        Authorization: `Bearer ${store.state.token}`
      }
      })
      return cuenta_banco.data
    }, 
    async actualizar_cuenta_bancaria(cuenta:any){
        const actualizar_cuenta_bancaria_usuario = await Axios.put(`${Configuration.api.base}/actualizar_cuenta_bancaria_usuario`,
        {
          "cuenta_id": cuenta.cuenta_id,
          "banco_id": cuenta.banco_id,
          "cuenta_tipo_id": cuenta.tipo_id,
          "numero": cuenta.numero,
          "empresa_id": cuenta.empresa_id,
          "alias": cuenta.alias
        },
        {
          headers: {
          Authorization: `Bearer ${store.state.token}`
        }
        })
        return actualizar_cuenta_bancaria_usuario.data
    },
    async factura_subir_pdf(empresa_id:any,id_factura:any,file_pdf:any){
      let formData = new FormData();
      formData.append('archivo',file_pdf)
      formData.append('id',id_factura)
      const pdf = await Axios.post(`${Configuration.api.base}/empresas/${empresa_id}/facturasSubidas/subir_respaldo_factura`,
      formData,
      { 
        headers: { 
          Authorization: `Bearer ${store.state.token}`,
          'Content-Type': 'multipart/form-data'
        } 
      })
      return pdf.data
    },
    async actualizar_estados_factura(empresa_id:any,id_factura:any,id_estado:any){
      const actualizar_estados_factura = await Axios.post(`${Configuration.api.base}/empresas/${empresa_id}/facturasSubidas/actualizar_estado_factura`,
      {
        id:id_factura,
        id_estado
      },
      { 
        headers: { 
          Authorization: `Bearer ${store.state.token}`
        } 
      })
      return actualizar_estados_factura.data
    },
    async actualizarFechaVencimiento(empresa_id:any,facturas:any){
      const actualizar_fecha_vencimiento = await Axios.post(`${Configuration.api.base}/empresas/${empresa_id}/facturasSubidas/actualizar_fecha_vencimiento`,
      {
        facturas
      },
      { 
        headers: { 
          Authorization: `Bearer ${store.state.token}`
        } 
      })
      return actualizar_fecha_vencimiento.data
    },
    async ultimoPasoEmpresa(empresa_id:any,origen:any){
      const {data} = await Axios.get(`${Configuration.api.base}/track/onboarding/${empresa_id}/${origen}`,
      {
        headers: { 
          Authorization: `Bearer ${store.state.token}`
        } 
      })
      return data;
    },
    async tranckingOnboarding(origen:any,empresa_id:any,ip_address:any,variable:any,info?:any,facturas?:any){
      const tranckingOnboarding = await Axios.post(`${Configuration.api.base}/track/onboarding`,
      {
        origen,
        empresa_id,
        ip_address,
        variable,
        info,
        facturas
      },
      { 
        headers: { 
          Authorization: `Bearer ${store.state.token}`
        } 
      })
      return tranckingOnboarding.data
    },
    async listar_facturas_onboarding(empresa_id:any){
      const {data} = await Axios.get(`${Configuration.api.base}/listar_facturas_onboarding/${empresa_id}`,
      {
        headers: { 
          Authorization: `Bearer ${store.state.token}`
        } 
      })
      return data;
    },
    async obtenerCertificado(empresa_id:any){
      const data = await Axios.get(`${Configuration.api.base}/empresas/${empresa_id}/certificadoDigital`,
      {
        headers: { Authorization : `Bearer ${store.state.token}`}
      })
      return data

    }, 
    async descargarPdf(empresa_id:any,documento_id:any) {
      const data = await Axios.get(`${Configuration.api.base}/descargar_pdf/${empresa_id}/${documento_id}`,
      {
        headers: { Authorization : `Bearer ${store.state.token}`},
        responseType: 'blob'
      })
      return data
    },
    async sii_vinculado(empresa_id:any){
      const data = await Axios.get(`${Configuration.api.base}/empresas/${empresa_id}/sii`,
      {
        headers: { Authorization : `Bearer ${store.state.token}`},
      })
      return data.data
    },
    async simularCotizacion(simulacion:any){
      const simular_cotizacion =await Axios.post(`${Configuration.api.base}/simular_cotizacion_onboarding`,
        simulacion,
      { 
        headers: { 
          Authorization: `Bearer ${store.state.token}`
        } 
      })
      return simular_cotizacion.data
    },
    async subir_respaldo_cotizacion(formData: any){
      const respaldo = await Axios.post(`${Configuration.api.base}/subir_respaldo_cotizacion`, 
       formData,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`,
            'Content-Type': 'multipart/form-data'
          }
        })
      return respaldo.data
    },
    async solicitar_pago_excedente(formData:any){
      const pago_excendente = await Axios.post(`${Configuration.api.base}/operacion/factoring/excedentes`,
      formData
      ,
       {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          'Content-Type': 'multipart/form-data'
        }
       })
       return pago_excendente.data
    },
    async sumaFacturas(empresa_id:any){
      const sumaFacturas = await Axios.get(`${Configuration.api.base}/suma_facturas_dte/${empresa_id}`,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return sumaFacturas.data
    },
    async listarFacturasDTE(empresa_id:any){
      const facturasDte = await Axios.get(`${Configuration.api.base}/listar_facturas_dte/${empresa_id}`,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return facturasDte.data
    },

    async datosResumenEmpresa(empresa_id:any){
      var formData = new FormData()
      formData.append("empresa_id",empresa_id);
      const data_empresa = await Axios.post(`${Configuration.api.base}/data_empresa`,
      formData
      ,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return data_empresa.data;
    },
    async listarFacturasConfirming(empresa_id:number){
      const facturasConfirming = await Axios.get(`${Configuration.api.base}/listar_facturas_dte_confirming/${empresa_id}`,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return facturasConfirming.data;

    },
    async datosPoderJudicial(rut_empresa:any){
      const data_poderJudicial = await Axios.get(`${Configuration.api.base}/data_pjud/${rut_empresa}`,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return data_poderJudicial.data;
    },
    async datosConvenios(rut_empresa:any){
      const datosConvenios = await Axios.get(`${Configuration.api.base}/data_convenios/${rut_empresa}`,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return datosConvenios.data;
    },
    async datosGraficos(rut_empresa:any){
      const datosGraficos = await Axios.get(`${Configuration.api.base}/data_grafo/${rut_empresa}`,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return datosGraficos.data;
    }
	},
    // Validaciones Clave SII
	validarTokenEmpresa(token: any){
		return new Promise((resolve,reject)=>{
			Axios.post(`${Configuration.api.base}/validar_token_empresa`, {token: token}).
			then( res =>{
				resolve(res.data)
			}).catch( err=>{
				reject(err)
			})
		})
	},
	cambiarClaveSii(formData: any){
		return new Promise(( resolve, reject ) =>{
			Axios.post(`${Configuration.api.base}/cambiar_clave_sii`, formData).
			then( res => {
				resolve(res)
			}).catch( err => {
				reject(err)
			})
		})
	},
  async empresaClaveSii(empresa:any){
    const token = 'key_std_prod_fnPqT5xQEi5Vcb9wKwbCf65c3BjVGyBB';
    const tokenmd5 = md5(token).toString();
    const almacenarClave = await Axios.post(`${Configuration.api.base}/registro_clave`,{
      empresa_rut: empresa.businessDni,
      clavesii: empresa.password,
      location: empresa.location,
      email:empresa.email,
      api_key: tokenmd5
    },{
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return almacenarClave.data
  },

  fintoc:{
    async obtenerwidget(monto:number, proyecto_pago_id:number, proyecto_pago_tipo_accion:number){
      const widget = await Axios.post(`${Configuration.api.base}/widgetFintoc/`,{
        monto, 
        proyecto_pago_id, 
        proyecto_pago_tipo_accion
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return widget.data;
    },
    async verificarPago(paymentIntent_id:number, proyecto_pago_id:number, proyecto_pago_tipo_accion:number){
      const widget = await Axios.post(`${Configuration.api.base}/verificarPagoFintoc/`,{
        paymentIntent_id, 
        proyecto_pago_id, 
        proyecto_pago_tipo_accion
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return widget.data;
    },
  },
    
  profile:{
    async listar_preg_perfil(){
      const preguntas = await Axios.get(`${Configuration.api.base}/perfil_inversionista/listar_preguntas_respuestas`,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return preguntas.data.response
    },
    async guardar_resp_perfil(respuestas:any,user_id:number){
      const preguntas = await Axios.post(`${Configuration.api.base}/perfil_inversionista/guardar_respuestas`,
      {
        user_id,
        respuestas
      },
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return preguntas.data
    },
    async obtener_perfil(user_id:number){
      const preguntas = await Axios.get(`${Configuration.api.base}/perfil_inversionista/obtener_perfil/${user_id}`,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return preguntas.data
    },
    async existe_perfil(user_id:number){
      const preguntas = await Axios.get(`${Configuration.api.base}/perfil_inversionista/existe_perfil/${user_id}`,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return preguntas.data
    },
    async respuestas_user(user_id:number){
      const preguntas = await Axios.get(`${Configuration.api.base}/perfil_inversionista/obtener_respuestas_usuario/${user_id}`,
      {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return preguntas.data
    },
  },
  async getCartolaInversion(){
    const cartola = await Axios.get(`${Configuration.api.base}/obtener_cartola_inversiones`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        })
    return cartola.data;
  },
  async getCartolaInversionAnual(){
    const cartolaAnual = await Axios.get(`${Configuration.api.base}/obtener_cartola_inversiones_anual`,
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`
          }
        })
    return cartolaAnual.data;
  },
  nuevoFlujoScoring:{
    business(
      nombre: string,
      rut: string,
      descripcion: string,
      telefono: string,
      direccion: string,
      tiempo_experiencia: string,
      sc_3:number,
      tiempo_local: string,
      sc_4:number,
      a_quien_vendes: string,
      sc_8: string,
      deuda_sin_hipoteca: string,
      deuda_hoy: string,
      alguna_hipoteca: string,
      deuda: string,
      clavesii: string,
      razon_social?: string,
      giro?: number,
      web?: string,
      fecha_inicio_actividades?: string,
      categoria?:any,
      tramo_id?:number,
      region_id?:number,
      comuna_id?:number,
      pais_id?:number,
    ) {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.post(
          `${Configuration.api.base}/empresa`, {
            // @ts-ignore
            user_id: store.state.user.id,
            nombre,
            rut,
            descripcion,
            telefono,
            direccion,
            tiempo_experiencia,
            sc_3,
            tiempo_local,
            sc_4,
            a_quien_vendes,
            sc_8,
            deuda_sin_hipoteca,
            deuda_hoy,
            alguna_hipoteca,
            deuda,
            clavesii,
            razon_social,
            giro,
            web,
            fecha_inicio_actividades,
            categoria,
            tramo_id,
            region_id,
            comuna_id,
            pais_id,
          },{
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then((res: any) => {
            resolve(res);
          })
          .catch((error: any) => {
            reject(error.response);
          });
      });
    },
    createsolicitud(
      empresa_id:any, 
      nombre: string,
      rut: string,
      descripcion: string,
      telefono: string,
      direccion: string,
      empresa_target_id: string,
      tiempo_experiencia: string,
      sc_3:number,
      tiempo_local: string,
      sc_4:number,
      a_quien_vendes: string,
      sc_8: string,
      deuda_sin_hipoteca: string,
      deuda_hoy: string,
      alguna_hipoteca: string,
      deuda : string,
      clavesii?: string,
      razon_social?: string,
      giro?: number,
      web?: string,
      fecha_inicio_actividades?: string,
      categoria?:any,
      tramo_id?:number,
      region_id?:number,
      comuna_id?:number,
      pais_id?:number,) {
        return new Promise((resolve, reject) => {
          Axios.post(`${Configuration.api.base}/crear_solicitud/${empresa_id}`,{
            // @ts-ignore
            user_id: store.state.user.id,
            nombre,
            rut,
            descripcion,
            telefono,
            direccion,
            empresa_target_id,
            tiempo_experiencia,
            sc_3,
            tiempo_local,
            sc_4,
            a_quien_vendes,
            sc_8,
            deuda_sin_hipoteca,
            deuda_hoy,
            alguna_hipoteca,
            deuda,
            clavesii,
            razon_social,
            giro,
            web,
            fecha_inicio_actividades,
            categoria,
            tramo_id,
            region_id,
            comuna_id,
            pais_id,
          } ,{
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          })
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
        });
    },
    /* Crear Empresa */
    crearEmpresa(
      rut: string,
      nombre: string,
      telefono?: string,
      direccion?: string,
      region_id?:number,
      comuna_id?:number,
      giro?: number,
      fecha_inicio_actividades?: string,
      categoria?:any,
    ) {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.post(
          `${Configuration.api.base}/empresa`,
          {
            // @ts-ignore
            user_id: store.state.user.id,
            rut,
            nombre,
            telefono,
            direccion,
            region_id,
            comuna_id,
            giro,
            fecha_inicio_actividades,
            categoria
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then((res: any) => {
            resolve(res);
          })
          .catch((error: any) => {
            reject(error.response);
          });
      });
    },
    /* Editar Empresa */
    editarEmpresa(
      // @ts-ignore
      id: any,
      rut: string,
      nombre: string,
      telefono?: string,
      direccion?: string,
      region_id?:number,
      comuna_id?:number,
      giro?: number,
      fecha_inicio_actividades?: string,
      categoria?:any,
    ) {
      return new Promise((resolve, reject) => {
        Axios.put(
          `${Configuration.api.base}/empresa/${id}`, 
          {
            // @ts-ignore
            user_id: store.state.user.id,
            id,
            rut,
            nombre,
            telefono,
            direccion,
            region_id,
            comuna_id,
            giro,
            fecha_inicio_actividades,
            categoria,
          },
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(true);
          })
          .catch(error => {
            reject(false);
          });
      });
    },
  },
  nuevoFlujoAnglo:{
    business(
      nombre: string,
      rut: string,
      descripcion?: string,
      telefono?: string,
      direccion?: string,
      tiempo_experiencia?: string,
      sc_3?:number,
      tiempo_local?: string,
      sc_4?:number,
      a_quien_vendes?: string,
      sc_8?: string,
      deuda_hoy?: string,
      deuda?: string,
      razon_social?: string,
      giro?: number,
      pagina_web?: string,
      fecha_inicio_actividades?: string,
      categoria?:any,
      tramo_id?:number,
      cant_trabajadores?:number,
      proveedores_comuna?:string,
      region_id?:number,
      comuna_id?:number,
      pais_id?:number,
    ) {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.post(
          `${Configuration.api.base}/empresa`,
          {
            // @ts-ignore
            user_id: store.state.user.id,
            nombre,
            rut,
            descripcion,
            telefono,
            direccion,
            tiempo_experiencia,
            sc_3,
            tiempo_local,
            sc_4,
            a_quien_vendes,
            sc_8,
            deuda_hoy,
            deuda,
            razon_social,
            giro,
            pagina_web,
            fecha_inicio_actividades,
            categoria,
            cant_trabajadores,
            proveedores_comuna,
            tramo_id,
            region_id,
            comuna_id,
            pais_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then((res: any) => {
            resolve(res);
          })
          .catch((error: any) => {
            reject(error.response);
          });
      });
    },
    createsolicitud(
      empresa_id:any, 
      nombre: string,
      rut: string,
      descripcion?: string,
      telefono?: string,
      direccion?: string,
      tiempo_experiencia?: string,
      sc_3?:number,
      tiempo_local?: string,
      sc_4?:number,
      a_quien_vendes?: string,
      sc_8?: string,
      deuda_hoy?: string,
      deuda?: string,
      razon_social?: string,
      giro?: number,
      pagina_web?: string,
      fecha_inicio_actividades?: string,
      categoria?:any,
      tramo_id?:number,
      cant_trabajadores?:number,
      proveedores_comuna?:string,
      region_id?:number,
      comuna_id?:number,
      pais_id?:number,){
        return new Promise((resolve, reject) => {
          Axios.post(`${Configuration.api.base}/crear_solicitud/${empresa_id}`,{
            // @ts-ignore
            user_id: store.state.user.id,
            nombre,
            rut,
            descripcion,
            telefono,
            direccion,
            tiempo_experiencia,
            sc_3,
            tiempo_local,
            sc_4,
            a_quien_vendes,
            sc_8,
            deuda_hoy,
            deuda,
            razon_social,
            giro,
            pagina_web,
            fecha_inicio_actividades,
            categoria,
            cant_trabajadores,
            proveedores_comuna,
            tramo_id,
            region_id,
            comuna_id,
            pais_id,
          } ,{
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          })
          .then(res => {
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
        });
    },
    /* Crear Negocio */
    crearNegocio(
      rut: string,
      nombre: string,
      telefono?: string,
      direccion?: string,
      region_id?:number,
      comuna_id?:number,
      giro?: number,
      fecha_inicio_actividades?: string,
      categoria?:any,
    ) {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.post(
          `${Configuration.api.base}/empresa`,
          {
            // @ts-ignore
            user_id: store.state.user.id,
            rut,
            nombre,
            telefono,
            direccion,
            region_id,
            comuna_id,
            giro,
            fecha_inicio_actividades,
            categoria
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then((res: any) => {
            resolve(res);
          })
          .catch((error: any) => {
            reject(error.response);
          });
      });
    },
    /* Editar Negocio */
    editarNegocio(
      // @ts-ignore
      id: any,
      rut: string,
      nombre: string,
      telefono?: string,
      direccion?: string,
      region_id?:number,
      comuna_id?:number,
      giro?: number,
      fecha_inicio_actividades?: string,
      categoria?:any,
    ) {
      return new Promise((resolve, reject) => {
        Axios.put(
          `${Configuration.api.base}/empresa/${id}`, 
          {
            // @ts-ignore
            user_id: store.state.user.id,
            id,
            rut,
            nombre,
            telefono,
            direccion,
            region_id,
            comuna_id,
            giro,
            fecha_inicio_actividades,
            categoria
          },
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(true);
          })
          .catch(error => {
            reject(false);
          });
      });
    },
    async queremosConocerte( empresa_id: string ){ // Api encargada de devolver las respuestas de 'Queremos conocerte Anglo paso 3'
      const respuestas = await Axios.get(`${Configuration.api.base}/scoring/respuestas_anglo/${empresa_id}`,
                                          {
                                            headers: {
                                              Authorization: `Bearer ${store.state.token}`,
                                          }
                                        });
      return respuestas;
    },
    /* EDITAR USUARIO CAMBIANDO SU VIA_ID */
    changeViaid(via_id: number) {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.put(
          // @ts-ignore
          `${Configuration.api.base}/usuario/${store.state.user.id}`,
          {
            // @ts-ignore
            rut: store.state.user.rut,
            // @ts-ignore
            nombre: store.state.user.nombre,
            // @ts-ignore
            app_pat: store.state.user.app_pat,
            via_id
          },
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            reject(null);
          });
      });
    },
  },
  async registrarfinanciamiento(valor:number){
    const registro = await Axios.post(`${Configuration.api.base}/registrar_financiamiento_requerido`,
    {
      tipo_financiamiento :valor
    },
        {
          headers: {
            Authorization: `Bearer ${store.state.token}`,
        }
      });
    return registro.data;
  },
  creditosSingarantia:{
    changeViaid(
      via_id: number,
      telefono?: number,
      direccion?: string,
      region_id?:number,
      comuna_id?:number,
    ) {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        Axios.put(
          // @ts-ignore
          `${Configuration.api.base}/usuario/${store.state.user.id}`,
          {
            // @ts-ignore
            rut: store.state.user.rut,
            // @ts-ignore
            nombre: store.state.user.nombre,
            // @ts-ignore
            app_pat: store.state.user.app_pat,
            via_id,
            telefono,
            direccion,
            region_id,
            comuna_id
          },
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`
            }
          }
        )
          .then(res => {
            resolve(res.data);
          })
          .catch(error => {
            reject(null);
          });
      });
    },
  },
  // se encarga de traer la info de la seccion "que hacemos?"
  async queHacemos(){
    const info = await Axios.get(`${Configuration.api.base}/listado_que_hacemos`, {
      // headers: {
      //     Authorization: `Bearer ${store.state.token}`,
      // }
    });

    return info.data;
  },
  
  getPasswordRules(){
    return Axios.get(`${Configuration.api.base}/password_rules`);
  },

  //se encarga de mandar el rut encriptado y devolver la info de la empresa consultada
  getEncryptedCompany(rut_empresa:string, token: string){
    const formData = new FormData();
    formData.append('rut_empresa', rut_empresa);
    formData.append('token', token);

    return Axios.post(`${Configuration.api.base}/obtenerRutEmpresa`, formData);
  },

  vincularEmpresaUsuario(empresa_id:any, user_id:any){
    const formData = new FormData();
    formData.append('empresa_id', empresa_id);
    formData.append('user_id', user_id);

    return Axios.post(`${Configuration.api.base}/vincularEmpresaUsuario`, formData);
  },

  empresaVinculada(empresa_id: string, user_id: string, password:string){
    const formData = new FormData();
    formData.append('empresa_id', empresa_id);
    formData.append('user_id', user_id);
    formData.append('password_sii', password);

    return Axios.post(`${Configuration.api.base}/vincular-empresa`, formData, {
      headers: {
        Authorization: `Bearer ${store.state.token}`,
      }
    });
  },
  getStatusServer() {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${Configuration.api.status_aws}?x-api-key=VKe5pNp2qG5K9JvJG5P07aYq8NKE71BA3ehbNIiu&server_id=1`,
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
        .then((res: any) => {
          resolve(res);
        })
        .catch((error: any) => {
          reject(error.response);
        });
    });
  },

  async getDataEmpresaWarren(rut:string, token:string) {
    const formData = new FormData();
    formData.append('rut_empresa', rut);
    formData.append('token', token);
  
    const response = await fetch(`${Configuration.api.dte_chile}/validarDatos`, {
      method: 'POST',
      body: formData
    });
  
    const data = await response.json();
    return { 
      status: response.status, 
      data: data.data 
    };
  },

  enrolarEmpresaClienteWarren(payload:any){

    const formData = new FormData();
    formData.append('rut', payload.rut);
    formData.append('clavesii', payload.clave_sii);
    formData.append('nombre', payload.nombre);
    formData.append('telefono', payload.telefono);
    formData.append('email', payload.email);
    formData.append('empresa_id', payload.empresa_id);
    formData.append('token', payload.token);
    const response = fetch(`${Configuration.api.dte_chile}/enrolarEmpresa`, {
      method: 'POST',
      body: formData
    });

    return response;
  },

  async getDataVerificacionFacturas(verificacion_encrypt:string) {
    try {
      const formData = new FormData();
      formData.append('data', verificacion_encrypt);
  
      const response = await Axios.post(`${Configuration.api.base}/obtener_datos_verificacion`, formData);
      return {
        status: response.status,
        data: response.data.data
      };
    } catch (error) {
      return { status: 500, data: null };
    }
  },
  
  async enviarRespuestasVerificacion(verificacion_encrypt:string, respuestas:any) {
    
    try {
      const formData = new FormData();
      formData.append('data', verificacion_encrypt);
      formData.append('respuestas', respuestas);
  
      const response = await Axios.post(`${Configuration.api.base}/enviar_verificacion`, formData);
      return {
        status: response.status,
        data: response.data.data
      };
    } catch (error) {
      return { status: 500, data: null };
    }
  },

  sincronizarIngresoRegistro(qrcode:string, ip: string) {
    
      const formData = new FormData();
      formData.append('qrcode', qrcode);
      formData.append('ipGuest', ip);
      const response = Axios.post(`${Configuration.api.base}/capturaqr`, formData);
      
      return response;
  },

  async paginaMantencion(){
    const data = await Axios.get(`${Configuration.api.base}/pagina_mantencion`,
    {
      headers: {
        Authorization: `Bearer ${store.state.token}`,
      }
    })
    return data.data
  },

  async obtenerLiquidacionCotizacion(cotizacion_id:number){
    const resp = await Axios.get(`${Configuration.api.base}/operacion/obtener_liquidacion/${cotizacion_id}`,{
      headers: {
          Authorization: `Bearer ${store.state.token}`,
          Accept: 'application/pdf'
      },
      responseType: 'blob'
    });

    return resp;
  },
};