<template>
  <div class="countdown">
    <div v-if="Date.now() / 1000 <= date && !reserved">
      <span class="digit"
        >{{ days | two_digits }}:{{ hours | two_digits }}:{{
          minutes | two_digits
        }}:{{ seconds | two_digits }}</span
      >
    </div>
    <div v-else-if="Date.now() / 1000 <= date && reserved">
      <span class="digit"
        >{{ days | two_digits }}:{{ hours | two_digits }}:{{
          minutes | two_digits
        }}:{{ seconds | two_digits }}</span
      >
    </div>
    <div v-else>
      Tiempo expirado
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from "vue-property-decorator";

Vue.filter("two_digits", function(value) {
  if (value.toString().length == 1) {
    return `0${value.toString()}`;
  } else {
    return value.toString();
  }
});

@Component({
  props: {
    reserved: Boolean,
    date: {
      type: Number,
      coerce: (str) => Math.trunc(str)
    }
  },
  computed: {
    days() {
      return Math.trunc((this.$props.date - this.$data.now) / 60 / 60 / 24);
    },
    hours() {
      return Math.trunc((this.$props.date - this.$data.now) / 60 / 60) % 24;
    },
    minutes() {
      return Math.trunc((this.$props.date - this.$data.now) / 60) % 60;
    },
    seconds() {
      return (this.$props.date - this.$data.now) % 60;
    },
    idays() {
      return Math.trunc((this.$data.now - this.$props.date) / 60 / 60 / 24);
    },
    ihours() {
      return Math.trunc((this.$data.now - this.$props.date) / 60 / 60) % 24;
    },
    iminutes() {
      return Math.trunc((this.$data.now - this.$props.date) / 60) % 60;
    },
    iseconds() {
      return (this.$data.now - this.$props.date) % 60;
    }
  },
  data() {
    return {
      now: Math.trunc(new Date().getTime() / 1000),
      intervalId: null
    };
  },
  mounted() {
    this.$data.intervalId = setInterval(() => {
      this.$data.now = Math.trunc(new Date().getTime() / 1000);
    });
  },
  beforeDestroy() {
    clearInterval(this.$data.intervalId);
  }
})
export default class Countdown extends Vue {}
</script>
