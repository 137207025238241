<template>
  <div class="footer">
    <!-- Footer Desktop -->
    <v-container class="hidden-sm-and-down py-0 mx-5">
      <v-layout row wrap my-0>
        <v-flex md6 text-xs-center mb-3 text-md-left>
          <p
            class="headline slab white--text font-weight-light text-md-left mt-3 ml-5 mb-0"
          >
            ¿Tienes preguntas?
          </p>
          <p class="subheading font-weight-thin white--text px-5 py-3">
            No dudes en contactarnos, estamos felices de <br />aclarar tus
            dudas:
          </p>
          <v-layout row wrap px-3 mb-3>
            <v-flex xs2 text-xs-center>
              <i class="material-icons big-icon white--text">email</i>
            </v-flex>
            <v-flex xs10 pt-2>
              <a
                class="subheading font-weight-light"
                :href="`mailto:${RedCapital[SelectedCountry].Contact.Mail}`"
                >{{ RedCapital[SelectedCountry].Contact.Mail }}</a
              >
            </v-flex>
          </v-layout>
          <v-layout row wrap px-3 mb-3>
            <v-flex xs2 text-xs-center>
              <i class="material-icons big-icon white--text">local_phone</i>
            </v-flex>
            <v-flex xs10 text-xs-left>
              <a
                class="white--text subheading font-weight-thin"
                :href="`tel:${RedCapital[SelectedCountry].Contact.Phone}`"
                >{{ RedCapital[SelectedCountry].Contact.Phone }}</a
              ><br />
              <!-- <a
                v-if="RedCapital[SelectedCountry].Contact.Phone2 != null"
                class="white--text subheading font-weight-thin"
                :href="`tel:${RedCapital[SelectedCountry].Contact.Phone2}`"
                >{{ RedCapital[SelectedCountry].Contact.Phone2 }}</a
              ><br /> -->
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex row wrap px-3 mb-3>
          <p class="headline slab white--text font-weight-light text-md-center">
            <br />
          </p>
          <!-- <v-layout wrap row px-3 py-3>
            <v-flex xs2>
              <a :href="`${RedCapital[SelectedCountry].Contact.LinkedIn}`" target="_blank" rel="noreferrer">
                <img class="rrss" alt="LinkedIn" v-lazy="'/images/linkedin-logo.svg'" width="23px">
                </a>
            </v-flex>
            <v-flex xs2>
              <a :href="`${RedCapital[SelectedCountry].Contact.Twitter}`" target="_blank" rel="noreferrer">
                <img class="rrss" alt="Twitter" v-lazy="'/images/twitter-logo.svg'" width="23px">
              </a>
            </v-flex>
          </v-layout> -->
          <v-layout wrap row px-3 py-3 fill-height>
            <v-flex xs2 text-xs-left>
              <i class="material-icons big-icon white--text">place</i>
            </v-flex>
            <v-flex xs10 text-xs-left>
              <p class="subheading font-weight-light mb-0">Oficinas:</p>
              <a
                class="subheading font-weight-light"
                rel="noreferrer"
                :href="`${RedCapital[SelectedCountry].Contact.AddressLink}`"
                target="_blank"
                >{{ RedCapital[SelectedCountry].Contact.AddressName }}</a
              >
              <a
                v-if="
                  RedCapital[SelectedCountry].Contact.AddressLink2 != null ||
                    RedCapital[SelectedCountry].Contact.AddressName2 != null
                "
                class="subheading font-weight-light"
                rel="noreferrer"
                :href="`${RedCapital[SelectedCountry].Contact.AddressLink2}`"
                target="_blank"
                >{{ RedCapital[SelectedCountry].Contact.AddressName2 }}</a
              >
              <p class="subheading font-weight-light mt-2 mb-0">
                Horario de atención:
              </p>
              <a
                class="subheading font-weight-light"
                rel="noreferrer"
                :href="`${RedCapital[SelectedCountry].Contact.AddressLink}`"
                target="_blank"
                >{{ RedCapital[SelectedCountry].Contact.Timetable }} </a
              ><br />
              <a
                class="subheading font-weight-light"
                rel="noreferrer"
                :href="`${RedCapital[SelectedCountry].Contact.AddressLink}`"
                target="_blank"
                >{{ RedCapital[SelectedCountry].Contact.Timetable2 }}
              </a>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex md6 mb-2>
          <v-layout column class="subheading font-weight-thin">
            <v-flex xs12 ml-5 pb-1 v-for="link in mobile" :key="link.text">
              <v-hover>
                <router-link
                  slot-scope="{ hover }"
                  :class="{ [`${hover ? 'primary' : 'white'}--text`]: true }"
                  :to="link.to"
                >
                  {{ link.text }}
                </router-link>
              </v-hover>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex md6 text-md-left mb-2>
          <p class="body-2 font-weight-thin white--text px-4 mx-5 justify">
            A través de RedCapital los inversionistas financian a un Pyme a
            través de préstamo o compra de facturas. Si bien hay una tasa fijada
            con anticipación - Renta Fija - existen riesgos asociados y las
            fechas de pagos anunciadas son estimadas por lo que podrían varíar.
            Recomendamos revisar en detalle "<router-link
              class="font-weight-regular"
              to="/garantia-riesgos"
              >Garantías y Riesgos</router-link
            >"
          </p>
        </v-flex>
      </v-layout>
      <v-layout wrap text-xs-center justify-center ma-1>
        <v-flex xs12>
          <p class="subheading font-italic font-weight-thin">
            RedCapital, Todos los derechos reservados 2021
          </p>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- / Footer Desktop -->
    <!-- Footer Mobile -->
    <v-container class="hidden-md-and-up pb-0">
      <v-layout wrap row align-center justify-center>
        <v-flex xs12 mb-2>
          <p class="subheading font-weight-light mb-0">Oficinas:</p>
          <a
            class="subheading font-weight-light"
            rel="noreferrer"
            :href="`${RedCapital[SelectedCountry].Contact.AddressLink}`"
            target="_blank"
            >{{ RedCapital[SelectedCountry].Contact.AddressName }}</a
          >
          <p class="subheading font-weight-light mt-2 mb-0">
            Horario de atención:
          </p>
          <a
            class="subheading font-weight-light"
            rel="noreferrer"
            :href="`${RedCapital[SelectedCountry].Contact.AddressLink}`"
            target="_blank"
            >{{ RedCapital[SelectedCountry].Contact.Timetable }} </a
          ><br />
          <a
            class="subheading font-weight-light"
            rel="noreferrer"
            :href="`${RedCapital[SelectedCountry].Contact.AddressLink}`"
            target="_blank"
            >{{ RedCapital[SelectedCountry].Contact.Timetable2 }}
          </a>
          <p class="subheading font-weight-light mt-2 mb-0">Contacto:</p>
          <a
            class="subheading font-weight-light"
            rel="noreferrer"
            :href="`tel:${RedCapital[SelectedCountry].Contact.Phone}`"
            >{{ RedCapital[SelectedCountry].Contact.Phone }}</a
          ><br />
          <!-- <a
            v-if="RedCapital[SelectedCountry].Contact.Phone2 != null"
            class="subheading font-weight-light"
            :href="`tel:${RedCapital[SelectedCountry].Contact.Phone2}`"
            >{{ RedCapital[SelectedCountry].Contact.Phone2 }}</a
          > -->
        </v-flex>
      </v-layout>
      <!-- <v-layout wrap>
        <v-flex xs12 mt-3>
          <p class="font-weight-bold">Suscríbete: </p>
        </v-flex>
        <v-flex xs12>
          <v-text-field class="outlined" background-color="white" dark outline label="ejemplo@mail.com" append-outer-icon="arrow_forward_ios"></v-text-field>
        </v-flex>
      </v-layout> -->
      <!-- <v-layout wrap row>
        <v-flex xs3>
          <p class="font-weight-bold">Compartir:</p>
        </v-flex>
        <v-flex xs2>
          <a :href="`${RedCapital[SelectedCountry].Contact.LinkedIn}`" target="_blank" rel="noreferrer">
            <img class="rrss" alt="LinkedIn" v-lazy="'/images/linkedin-logo.svg'" width="24px">
          </a>
        </v-flex>
        <v-flex xs2>
          <a :href="`${RedCapital[SelectedCountry].Contact.Twitter}`" target="_blank" rel="noreferrer">
            <img class="rrss" alt="Twitter" v-lazy="'/images/twitter-logo.svg'" width="24px">
          </a>
        </v-flex>
      </v-layout> -->
      <v-divider dark class="my-3"></v-divider>
      <v-layout row>
        <ul>
          <router-link
            class="mb-2"
            v-for="link in mobile"
            :key="link.to"
            tag="li"
            :to="link.to"
          >
            <a class="white--text">{{ link.text }}</a>
          </router-link>
        </ul>
      </v-layout>
      <v-layout wrap text-xs-center justify-center ma-3>
        <v-flex xs12>
          <p class="font-italic">
            RedCapital, Todos los derechos reservados 2021
          </p>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- / Footer Mobile -->
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import { SelectedCountry, RedCapital } from "@/configuration";

@Component({
  data() {
    return {
      SelectedCountry,
      RedCapital,
      desktop: [
        {
          to: "contacto",
          text: "Contacto"
        },
        {
          to: "quienes-somos",
          text: "Sobre Nosotros"
        },
        {
          to: "preguntas-frecuentes",
          text: "Preguntas Frecuentes "
        },
        {
          to: "garantia-riesgos",
          text: "Garantías y Riesgos"
        },
        {
          to: "/",
          text: "Terminos y condiciones"
        },
        {
          to: "/garantia-riesgos",
          text: "Garantías y Riesgos"
        },
        {
          to: "/politica-privacidad",
          text: "Políticas de privacidad"
        },
        {
          to: "/protocolo-cobranza",
          text: "Protocolo de cobranza"
        }
      ],
      mobile: [
        {
          to: "/quienes-somos",
          text: "Sobre Nosotros"
        },
        {
          to: "/preguntas-frecuentes",
          text: "Preguntas Frecuentes "
        },
        {
          to: "/contacto",
          text: "Contáctanos"
        },
        {
          to: "/garantia-riesgos",
          text: "Garantías y Riesgos"
        },
        {
          to: "/politica-privacidad",
          text: "Políticas de privacidad"
        },
        {
          to: "/protocolo-cobranza",
          text: "Protocolo de cobranza"
        }
      ]
    };
  }
})
export default class FooterLandingAnglo extends Vue {}
</script>

<style lang="scss">
.footer {
  background-color: #353e47;
  padding: 0 0px;
  p {
    color: #fff;
  }
  a {
    text-decoration: none;
    color: #b2b2b2;
  }
  i {
    color: #9d9d9c;
    margin: 0 5px;
  }
  a:hover {
    color: #da5a38;
  }
  li {
    list-style: none;
  }
  ul {
    padding: 0;
  }
  .footer-title {
    font-size: 15px;
    font-weight: 400;
  }
  .rrss {
    color: #fff;
    margin: 0 5px;
  }
  .rrss:hover {
    color: #da5a38;
  }
  .outlined .v-input__slot {
    padding-left: 20px !important;
  }
  .big-icon {
    font-size: 1.7em;
    margin-right: 10px;
    margin-top: 9px;
  }
}
</style>
