<template>
	<div class="dashboard-navbar">
    	<!-- Menu Btn -->
		<v-layout wrap row align-start justify-start style="position:fixed;z-index:1;margin: 10px">
      		<v-flex xs12 @click="onMenu()">
        		<v-icon style="cursor: pointer; color: #ff8a00">menu</v-icon>
        	</v-flex>
    	</v-layout>
		<!-- List Menu -->
    	<v-navigation-drawer id="rc-menu-inversionista" v-model="drawer" fixed hide-overlay style="margin-top:64px; z-index:1; width:200px; height: calc( 100vh - 64px ) !important; min-height: 100%">
			<v-list class="transparent pa-2">
        		<!-- Mi Panel -->
				<v-list-tile :active-class="activeClass" :to="{ name: 'dashboard-stats' }" class="v-list-item rc-list-item probando2">
          			<div style="text-align: center; width: 100%; line-height: 1;">
						<img src="/images/panelEstadisticas/ico/ico_miPanel.svg" height="26px" width="26px" alt="Mi Panel">
						<div class="rc-listItem-title">Mi panel</div>
					</div>
        		</v-list-tile>
        		<!-- Invertir -->
				<v-list-tile :active-class="activeClass" :to="Investment.to" class="v-list-item rc-list-item probando2">
          			<div style="text-align: center; width: 100%; line-height: 1;">
						<img :src="Investment.icon" height="26px" width="26px" alt="Invertir">
						<div class="rc-listItem-title">{{ Investment.text }}</div>
					</div>
        		</v-list-tile>
				<!-- Mis Inversiones -->
        		<v-list-group :key="`${menu.icon}-${index}`" v-for="(menu, index) in inversionistaMenus" class="probando2">
					<template v-slot:activator>
						<div style="text-align: center; width: 100%; line-height: 1;">
							<img :src="menu.icon" height="26px" width="26px" alt="Mis inversiones">
							<div class="rc-listItem-title">{{ menu.text }}</div>
						</div>
					</template>
					<div :active-class="activeClassSubMenu" :to="category.to" :key="`${category.icon}-${index}`" v-for="(category, index) in menu.categories" class="v-list-item modified-text rc-noml" >
						<v-list-group v-if="category.links != undefined" class="transparent">
							<template v-slot:activator>
								<v-list-tile class="v-list-item">
									<div style="text-align: center; width: 100%; line-height: 1;">
										<img :src="category.icon" height="26px" width="26px" :alt="category.text">
										<div class="rc-listItem-title">{{ category.text }}</div>
									</div>
								</v-list-tile>
							</template>
							<v-list-tile :key="link.icon" v-for="link in category.links" :to="link.to" class="v-list-item">
								<div style="text-align: center; width: 100%; line-height: 1;">
									<img :src="link.icon" height="26px" width="26px" :alt="link.text">
									<div class="rc-listItem-title">{{ link.text }}</div>
								</div>
							</v-list-tile>
						</v-list-group>
						<v-list-tile v-else :to="category.to" class="transparent rc-subitem" v-show="category.text != 'Cartola Mensual' || showtable ">
							<div style="text-align: center; width: 100%; line-height: 1;">
								<img :src="category.icon" height="26px" width="26px" :alt="category.text">
								<div class="rc-listItem-title">{{ category.text }}</div>
							</div>
						</v-list-tile>
					</div>
				</v-list-group>
        		<!-- Mis Datos -->
				<v-list-group class="probando2">
					<template v-slot:activator>
						<div style="text-align: center; width: 100%; line-height: 1;">
							<img :src="inversionistaProfile.icon" height="26px" width="26px" :alt="inversionistaProfile.text">
							<div class="rc-listItem-title">{{ inversionistaProfile.text }}</div>
						</div>
					</template>
					<v-list-tile :active-class="activeClassSubMenu" :to="link.to" :key="link.icon" v-for="link in inversionistaProfile.links" class=" v-list-item modified-text rc-subitem rc-noml">
						<div style="text-align: center; width: 100%; line-height: 1;">
							<img :src="link.icon" height="26px" width="26px" :alt="link.text">
							<div class="rc-listItem-title">{{ link.text }}</div>
						</div>
					</v-list-tile>
				</v-list-group>
				<!-- Mis Documentos -->
				<v-list-group class="probando2">
					<template v-slot:activator>
						<v-list-tile class="v-list-item fsize" :active-class="activeClass">
							<div style="text-align: center; width: 100%; line-height: 1;">
								<img :src="inversionistaMyDocuments.icon" height="26px" width="26px" :alt="inversionistaMyDocuments.text">
								<div class="rc-listItem-title">{{ inversionistaMyDocuments.text }}</div>
							</div>
						</v-list-tile>
					</template>
					<v-list-tile :active-class="activeClassSubMenu" :to="link.to" :key="`${link.icon}-${index}`" v-for="(link, index) in inversionistaMyDocuments.links" class=" v-list-item modified-text rc-subitem rc-noml">
						<div style="text-align: center; width: 100%; line-height: 1;">
							<img :src="link.icon" height="26px" width="26px" :alt="link.text">
							<div class="rc-listItem-title">{{ link.text }}</div>
						</div>
					</v-list-tile>
				</v-list-group>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>

<script>
	import { Component, Prop, Vue } from "vue-property-decorator";
	import { mapState } from "vuex";
	import API from "@/api";
	import {
	MyDocuments,
	Profile,
	NewMenus,
	Menus,
	MyBusiness,
	Investment,
	inversionistaMenus,
	inversionistaProfile,
	inversionistaMyDocuments
	} from "@/models/DashboardLinks";
	import { EventBus } from "@/main";

@Component({
  computed: {
    ...mapState(["user","openMenuPanelInvest"]),
    activeClass() {
      return "rc-menuInversionista-active";
    },
	activeClassSubMenu() {
      return "rc-submenuInversionista-active";
    },
    accountType() {
      return this.$store.state.user.user_tipo_id == 2
        ? "investor"
        : "applicant";
    },
    showtable(){
        return this.$store.state.user.id == 6984 || this.$store.state.user.id == 28 || this.$store.state.user.id == 167 || this.$store.state.user.id == 274 || this.$store.state.user.id == 123;
    }
  },
  data() {
    return {
      title: "Dashboard",
      darkMode: true,
      MyBusiness,
      Investment,
      Menus,
      Profile,
      ProfileInvestment,
      MyDocuments,
		inversionistaMenus,
		inversionistaProfile,
		inversionistaMyDocuments,
      mini: true,
      right: null,
      canShowMenu: false,
      drawer: false,
      responsive: false,
      tooltip: false,
      user_id:null
    };
  },
  watch: {
    $route(val) {
      // @ts-ignore
      this.changeTitle();
    }
  },
  async beforeMount() {
    // @ts-ignore
    this.changeTitle();
    this.$data.user_id = this.$store.state.user.id;
  },
  mounted() {
    // @ts-ignore
    this.onResponsive();
    // @ts-ignore
    window.addEventListener("resize", this.onResponsive);
  },
  beforeDestroy() {
    // @ts-ignore
    window.removeEventListener("resize", this.onResponsive);
  },
  methods: {
    onResponsive() {
	  	if (window.innerWidth >= 1264){
		  	this.hasClassMenu();
		  	this.drawer = true;
			this.$store.commit("setopenMenuPanelInvest", true);
		}
      if (window.innerWidth < 960) {
        this.$data.responsive = true;
	  } else {
        this.$data.responsive = false;
      }
    },
    changeTitle() {
      this.$data.title = this.$route.name;
    },
    logout() {
      EventBus.$emit("logout");
    },
	onMenu(){
		//console.log(this.$store.state.openMenuPanelInvest);
		if(this.$data.drawer){
			this.drawer = false;
			this.$store.commit("setopenMenuPanelInvest", false);
		}else{
			this.drawer = true;
			this.$store.commit("setopenMenuPanelInvest", true);
		}
	},
	hasClassMenu(){
		if(document.getElementById('rc-menu-inversionista').className.match(/\bv-navigation-drawer--open\b/)){
			this.drawer = true;
			this.$store.commit("setopenMenuPanelInvest", true);
		}else{
			this.drawer = false;
			this.$store.commit("setopenMenuPanelInvest", false);
		}
	}

  }
})
export default class NavBar extends Vue {}
</script>

