<template>
  <div class="public-navbar">
    <v-toolbar v-resize="onResize" dark fixed app clipped-right flat
      :color="dark ? 'active-menu' : 'black'"
      :class="{ [`pt-${isMobile ? 0 : 0}`]: true }"
    >
      <router-link to="/"
        ><img
          alt="RedCapital logo"
          :src="`${RedCapital[SelectedCountry].Logo}`"
          class="rclogo"
      /></router-link>
    </v-toolbar>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import {
  Menus,
  Profile,
  MyBusiness,
  MyDocuments
} from "@/models/DashboardLinks";
import { EventBus } from "@/main";
import { RedCapital, RedCapitalConfig, SelectedCountry } from "@/configuration";

@Component({
  computed: {
    ...mapState(["user"]),
    canShowLinks() {
      return this.$route.path.startsWith("/panel/");
    },
    projectsVisibility() {
      return (
        this.$store.state.user != null ||
        RedCapitalConfig(SelectedCountry).Project.PublicVisibility
      );
    },
    activeClass() {
      return "primary white--text";
    },
    accountType() {
      return this.$store.state.user.user_tipo_id == 2
        ? "investor"
        : "applicant";
    }
  },
  data() {
    return {
      SelectedCountry,
      RedCapital,
      Menus,
      Profile,
      MyBusiness,
      MyDocuments,
      dark: true,
      canShowUserMenu: false,
      showMenu: false,
      isMobile: true,
      uglyLinks: [
        {
          to: "/quienes-somos",
          text: "Sobre nosotros"
        },
        {
          to: "/prensa",
          text: "En prensa"
        },
        {
          to: "/contacto",
          text: "Contacto"
        },
        {
          to: "/preguntas-frecuentes",
          text: "Preguntas frecuentes"
        },
        {
          to: "/garantia-riesgos",
          text: "Garantías y riesgos"
        }
      ]
    };
  },
  methods: {
    onResize() {
      if (window.innerWidth <= 960) {
        this.$data.isMobile = true;
      } else {
        this.$data.isMobile = false;
        this.$data.showMenu = false;
      }
    },
    login() {
      this.$data.showMenu = false;
      this.$data.canShowUserMenu = false;
      EventBus.$emit("open-login-dialog");
    },
    logout() {
      EventBus.$emit("logout");
    }
  }
})
export default class NavBar extends Vue {}
</script>

<style lang="scss" scoped>
.navbar {
  z-index: 3;
}
.rclogo {
  @media (max-width: 960px) {
    height: 40px;
  }
  @media (min-width: 960px) {
    height: 50px;
  }
}
.v-menu__content {
  position: fixed !important;
}

.v-toolbar{
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

</style>
