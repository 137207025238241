import { render, staticRenderFns } from "./PaymentGateways.vue?vue&type=template&id=296d1dfc&scoped=true&"
import script from "./PaymentGateways.vue?vue&type=script&lang=ts&"
export * from "./PaymentGateways.vue?vue&type=script&lang=ts&"
import style0 from "./PaymentGateways.vue?vue&type=style&index=0&id=296d1dfc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "296d1dfc",
  null
  
)

export default component.exports