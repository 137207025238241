<template> <!-- #Pendiente eliminar antiguas NavBar y NewNavbarFactoring -->
	<div class="dashboard-navbar">
		<!-- Menu Btn -->
		<v-layout wrap row align-start justify-start style="position:fixed;z-index:1;margin: 10px">
			<v-flex xs12 @click="setOpenMenu()">
				<v-icon class="rc-menuPanelBtn">menu</v-icon>
			</v-flex>
		</v-layout>
		<!-- List Menu -->
    	<v-navigation-drawer id="rc-menuPanel" v-model="drawer" fixed class="rc-menuDrawer">
			<v-list v-if="tipo_menu === 3" class="transparent pa-2">
        		<!-- Mi Panel -->
				<div v-if='user.via_id == 1'>
					<v-flex v-for="(panelSolicitanteMenu, index) in panelSolicitanteMenus" :key="`${panelSolicitanteMenu.icon}-${index}`">
						<v-list-tile  v-if="valMisDatos && panelSolicitanteMenu.text == 'Mis claves y certificados'" 
						class="v-list-item rc-listItem-pad" :class="stateOptionMisClaves" :active-class="activeClassMenuAlert" :to="panelSolicitanteMenu.to.path">
							<div style="text-align: center; width: 100%; line-height: 1; display: flex; flex-direction: row; align-items:center; ">
								<div style="flex-basis: 90%;" class="custom-margin">
									<span class="rc-listItem-img material-icons-outlined " style="height:26px;width:26px">{{ panelSolicitanteMenu.icon }}</span>
									<div class="rc-listItem-title">{{ panelSolicitanteMenu.text }}</div>
								</div>
								<!-- <span TODO: pendiente ICONO exclamacion
									class="rc-listItem-img material-icons-outlined" 
									style="height: 26px;width: 26px;position: absolute;right: 2px;">error_outline</span> -->
								<!-- <div style="flex-basis: 10%;margin-right: -20px">
									<v-tooltip
										v-model="show"
										right
										>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
											icon
											v-bind="attrs"
											v-on="on"
											>
											<v-icon color="#E83F29">
												error_outline
											</v-icon>
											</v-btn>
										</template>
										<span>Claves y certificado</span>
									</v-tooltip>
								</div> -->
							</div>
						</v-list-tile>
						<v-list-tile v-else class="v-list-item rc-list-item rc-listItem-pad" :active-class="activeClassMenu" :to="panelSolicitanteMenu.to.path">
							<div style="text-align: center; width: 100%; line-height: 1;">
								<span class="rc-listItem-img material-icons-outlined" style="height:26px;width:26px">{{ panelSolicitanteMenu.icon }}</span>
								<div class="rc-listItem-title">{{ panelSolicitanteMenu.text }}</div>
							</div>
						</v-list-tile>
					</v-flex>
				</div>
				<div v-else>
					<v-flex v-for="(panelSolicitanteMenu, index) in panelSolicitanteMenus2" :key="`${panelSolicitanteMenu.icon}-${index}`">
						<v-list-tile  v-if="valMisDatos && panelSolicitanteMenu.text == 'Mis claves y certificados'" 
						class="v-list-item rc-listItem-pad" :class="stateOptionMisClaves" :active-class="activeClassMenuAlert" :to="panelSolicitanteMenu.to.path">
							<div style="text-align: center; width: 100%; line-height: 1; display: flex; flex-direction: row; align-items:center; ">
								<div style="flex-basis: 90%;" class="custom-margin">
									<span class="rc-listItem-img material-icons-outlined " style="height:26px;width:26px">{{ panelSolicitanteMenu.icon }}</span>
									<div class="rc-listItem-title">{{ panelSolicitanteMenu.text }}</div>
								</div>
								<!-- <div style="flex-basis: 10%;margin-right: -20px">
									<v-tooltip
										v-model="show"
										right
										>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
											icon
											v-bind="attrs"
											v-on="on"
											>
											<v-icon color="#E83F29">
												error_outline
											</v-icon>
											</v-btn>
										</template>
										<span>Claves y certificado</span>
									</v-tooltip>
								</div> -->
							</div>
						</v-list-tile>
						<v-list-tile v-else class="v-list-item rc-list-item rc-listItem-pad" :active-class="activeClassMenu" :to="panelSolicitanteMenu.to.path">
							<div style="text-align: center; width: 100%; line-height: 1;">
								<span class="rc-listItem-img material-icons-outlined" style="height:26px;width:26px">{{ panelSolicitanteMenu.icon }}</span>
								<div class="rc-listItem-title">{{ panelSolicitanteMenu.text }}</div>
							</div>
						</v-list-tile>
					</v-flex>
				</div>
				
			</v-list>
			<v-list v-if="tipo_menu === 2" class="transparent pa-2">
        		<!-- Mi Panel -->
				<div v-for="(panelInversionistaMenu, index2) in panelInversionistaMenus" :key="`${panelInversionistaMenu.icon}-${index2}`">
					<v-list-tile v-if="!panelInversionistaMenu.categories" :class="!tienePerfil && panelInversionistaMenu.text === 'Perfil y alertas' ? stateOptionsPI : false" class="v-list-item rc-list-item rc-listItem-pad" :active-class="!tienePerfil && panelInversionistaMenu.text === 'Perfil y alertas' ? stateOptionsPIActive : false" :to="{name: panelInversionistaMenu.to.name}">
						<div style="text-align: center; width: 100%; line-height: 1;">
							<span class="rc-listItem-img material-icons-outlined" style="height:26px;width:26px">{{ panelInversionistaMenu.icon }}</span>
							<div class="rc-listItem-title">{{ panelInversionistaMenu.text }}</div><!-- Evaluar aca el color -->
						</div>
					</v-list-tile>
					<v-list-group v-else class="rc-list-item">
						<template v-slot:activator>
							<div style="text-align: center; width: 100%; line-height: 1;">
								<span class="rc-listItem-img material-icons-outlined" style="height:26px;width:26px">{{ panelInversionistaMenu.icon }}</span>
								<div class="rc-listItem-title">{{ panelInversionistaMenu.text }}</div>
							</div>
						</template>
						<div v-for="(category, index3) in panelInversionistaMenu.categories" :key="`${category.icon}-${index3}`">
							
							<v-list-tile class="v-list-item rc-list-item rc-listItem-pad" :active-class="activeClassMenu" :to="{name: category.to.name}">
								<div style="text-align: center; width: 100%; line-height: 1;">
									<span class="rc-listItem-img material-icons-outlined" style="height:26px;width:26px">{{ category.icon }}</span>
									<div class="rc-listItem-title">{{ category.text }}</div>
								</div>
							</v-list-tile>
<!-- 							
							<v-list-tile v-else-if="category.text == 'Cartola inversiones' && showtable" class="v-list-item rc-list-item rc-listItem-pad" :active-class="activeClassMenu" :to="{name: category.to.name}">
								<div style="text-align: center; width: 100%; line-height: 1;">
									<span class="rc-listItem-img material-icons-outlined" style="height:26px;width:26px">{{ category.icon }}</span>
									<div class="rc-listItem-title">{{ category.text }}</div>
								</div>
							</v-list-tile> -->
						</div>
						
					</v-list-group>
				</div>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	import { mapState } from "vuex";
	import { panelSolicitanteMenus, panelInversionistaMenus, panelSolicitanteMenus2 } from "@/models/DashboardLinks";
	
	@Component({
		props: {
			// datos
			tipo_menu: Number,
			sii: Boolean,
			certificado: Boolean,
			estadoCertificado: String,
			estadoPerfil:Boolean

        },
		computed: {
			...mapState(["user", "setOpenMenuPanel", "siiConfigurations", "claveSii","certificadoSii"]),
			activeClassMenu() {
				return "rc-menuPanelActive";
			},
			activeClassMenuAlert(){
				if((this.$props.sii == false && this.$props.certificado == true) || (this.$props.sii == false && this.$props.certificado == false) || (this.$props.sii == true && this.$props.certificado == false) || (this.$props.sii == true && this.$props.certificado == true && this.$props.estadoCertificado == 'EXPIRADO')){
					return "rc-listItem-alert-active";
				}
				else if(this.$props.certificado == true && this.$props.estadoCertificado == 'POR EXPIRAR' && this.$props.sii == true){
					return 'rc-listItem-warning-active';
				}
			},
			stateOptionMisClaves(){
				if(this.$props.certificado == true && this.$props.estadoCertificado == 'POR EXPIRAR' && this.$props.sii == true){
					return 'rc-listItem-warning rc-text-color-warning';
				}
				else{
					return 'rc-listItem-alert rc-text-color-alert'	
				}
			},
			stateOptionsPI(){
				if(!this.$props.estadoPerfil){
					return "rc-listItem-alert";
				}
				else{
					return "";
				}
			},
			stateOptionsPIActive(){
				if(!this.$props.estadoPerfil){
					return "rc-listItem-alert-active";
				}
				else{
					return false;
				}
			},
			valMisDatos(){
				return (this.$props.sii == false && this.$props.certificado == false) || (this.$props.sii == true && this.$props.certificado == false) || (this.$props.sii == false && this.$props.certificado == true) || (this.$props.sii == true && this.$props.certificado == true && this.$props.estadoCertificado == 'POR EXPIRAR') || (this.$props.sii == true && this.$props.certificado == true && this.$props.estadoCertificado == 'EXPIRADO')
			},
			showtable(){
				return this.$store.state.user.id == 6984 || this.$store.state.user.id == 28 || this.$store.state.user.id == 167 || this.$store.state.user.id == 274 || this.$store.state.user.id == 123;
			},
			// Valida si posee perfil de inversionista
			tienePerfil(){
				if(this.$store.state.user.tiene_perfil){
					return this.$store.state.user.tiene_perfil
				}
			}
		},
		data() { // Data Menu
			return {
				// Menu Solicitante general
				panelSolicitanteMenus,
				panelInversionistaMenus,
				panelSolicitanteMenus2,
				title: null,
				drawer: false,
				responsive: false,
				dark: true,
				show: false,
			}
		},
		// beforeMount() {}, // Antes de Montar el componente
		// beforeUpdate(){}, // Antes de Actualizar el componente
		mounted(){
			 // @ts-ignore
			this.onResponsive();
			// @ts-ignore
			window.addEventListener("resize", this.onResponsive);
			
		},
		methods:{
			onResponsive() {
				var url = window.location.pathname;
				let valPreguntasPefil = url === '/panel/preguntas-perfil' ? false : true;
				if (window.innerWidth >= 1264){
					this.hasClassMenu();
					this.drawer = true;
					if((this.$props.tipo_menu === 2 || this.$props.tipo_menu === 3) && valPreguntasPefil === true){ // Modifica variable para expandir o cerrar el menu lateral Inversionista
						this.$store.commit("setOpenMenuPanel", true);
					}
				}
				if (window.innerWidth < 960) {
					this.$data.responsive = true;
				} else {
					this.$data.responsive = false;
				}
			},
			setOpenMenu(){
				if(this.$data.drawer){
					this.drawer = false;
					this.$store.commit("setOpenMenuPanel", false);
				}else{
					this.drawer = true;
					this.$store.commit("setOpenMenuPanel", true);
				}
			},
			hasClassMenu(){
				if(document.getElementById('rc-menuPanel').className.match(/\bv-navigation-drawer--open\b/)){
					this.drawer = true;
					this.$store.commit("setOpenMenuPanel", true);
				}else{
					this.drawer = false;
					this.$store.commit("setOpenMenuPanel", false);
				}
			}
		}
	})
	export default class SideBarPanel extends Vue {}
</script>

<style lang="scss" scoped>
	.rc-menuPanelBtn{
		cursor: pointer; 
		color: $rc-prim-n1;
	}

	.rc-listItem-title{
		font-weight: 600;
	}

	.v-list{
		padding: 5px 0px !important;
	}
	
	.rc-listItem-pad{
		padding: 5px 5px;
	}
	.rc-text-color-alert{
		color: #E83F29 !important;
	}
	.rc-text-color-warning{
		color: #FFD541 !important;
	}
	
	/* Menu */
	.rc-list_ico{
		height: 20px;
		width: 20px;
	}
	.rc-right-bar{
		height: calc(100vh - 64px);
		background-color: $rc-escala5;
	}

	.rc-noml{
		margin-left: 0 !important;
	}

	.rc-menuDrawer{
		top: auto;
		//margin-top:64px !important;
		z-index:1; 
		width:150px !important; 
		height: calc( 100vh - 64px ) !important; 
		min-height: calc( 100vh - 64px ) !important; 
	}

	// Breakpoints 
	@each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
				
			}@else if $breakpoint == "large"{
				
			}@else if $breakpoint == "medium-to-large"{
				.rc-menuDrawer{
					margin-top: 0 !important;
					z-index: 5;
					width: 300px !important;
					height: 100vh !important; 
					min-height: 100vh !important;
				}
				.rc-listItem-img{
					float: left;
				}
				.rc-listItem-title{
					float: left;
					line-height: 2;
					margin-left: 10px;
				}
			}@else if $breakpoint == "small-to-medium"{
				.rc-menuDrawer{
					margin-top: 0 !important;
					z-index: 5;
					width: 300px !important;
					height: 100vh !important; 
					min-height: 100vh !important;
				}
				.rc-listItem-img{
					float: left;
				}
				.rc-listItem-title{
					float: left;
					line-height: 2;
					margin-left: 10px;
				}
			}@else if $breakpoint == "small"{
				.rc-menuDrawer{
					margin-top: 0 !important;
					z-index: 5;
					width: 300px !important;
					height: 100vh !important; 
					min-height: 100vh !important; 
				}
				.rc-listItem-img{
					float: left;
				}
				.rc-listItem-title{
					float: left;
					line-height: 2;
					margin-left: 10px;
				}
			}
		}
	}
	@media (min-width:1264px) {

    .custom-margin{
      margin-left: 5px;
	}
  }
  @media (max-width:1264px) {

    .custom-margin{
      margin-left: 0px;
    }
  }
</style>
