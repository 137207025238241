export const Menus = [
  {
    icon: "insert_chart",
    text: "Mis Facturas",
    categories: [
     /* {
        icon: "maps",
        text: "Anticipo de Facturas",
        to: {
          name: "dashboard-factoring-anticipo-facture"
        }
      },*/
      {
        icon: "receipt",
        text: "Pronto Pago",
        to: {
          name: "dashboard-investor-pending-payments"
        }
      },
      {
        icon: "receipt",
        text: "Línea de Crédito",
        to: {
          name: "dashboard-investor-payment-status"
        }
      },
      
      /*{
        icon: 'receipt',
        text: 'Detalle Cuotas',
        to: {
          name: 'dashboard-investor-details-cuotas',
        },
      },*/
      
     /* {
        icon: 'receipt',
        text: 'Cartola Mensual',
        to: {
          name: 'dashboard-investor-payment-mensual',
        },
      }*/
      /*{
        icon: 'receipt',
        text: 'Cartola Anual',
        to: {
          name: 'dashboard-investor-cartola-anual',
        },
      },*/
      // {
      //   icon: 'insert_chart',
      //   text: 'Mis inversiones',
      //   links: [
      //     {
      //       icon: 'maps',
      //       text: 'Resumen Inversiones (RI)',
      //       to: {
      //         name: 'dashboard-investor-my-investments',
      //       },
      //     },
      //     {
      //       icon: 'receipt',
      //       text: 'Transferencias Pendientes',
      //       to: {
      //         name: 'dashboard-investor-pending-payments',
      //       },
      //     },
      //   ],
      // },
    ]
  }
];
export const Anticipo = {
  icon: "maps",
  text: "Anticipo de Facturas",
  links: [
    {
      icon: "maps",
      text: "Anticipa tus Facturas",
      to: {
        name: "dashboard-factoring-anticipo-facture"
      }
    },
    {
      icon: "maps",
      text: "Ceder Facturas",
      to: {
        name: "dashboard-factoring-ceder-facture"
      }
    },
  ]

};
export const MyDocuments = {
  icon: "business",
  text: "Mis Operaciones",
  to: {
    name: "dashboard-factoring-my-operation"
  }
};
export const Profile = {
  icon: "person",
  text: "Mis datos - Configuración",
  links: [
    {
      icon: "account_balance",
      text: "Cuenta Bancaria",
      to: {
        name: "dashboard-factoring-My-info-acount-bank"
      }
    },
    {
      icon: "assignment",
      text: "SII (Servicios de impuestos Internos)",
      to: {
        name: "dashboard-factoring-My-sii"
      }
    },
   {
      icon: "lock",
      text: "TGR (Tesorería general de República)",
      to: {
        name: "dashboard-factoring-Tgr"
      }
    },
    {
      icon: 'star',
      text: 'Previred (Pago de cotizaciones)',
      to: {
        name: 'dashboard-factoring-Previred',
      },
    },
    {
      icon: 'import_export',
      text: 'Notificaciones (Mail, WhatsApp, otro)',
      to: {
        name: 'dashboard-factoring-Notification',
      },
    },
    {
      icon: "assignment",
      text: "Datos personales",
      to: {
        name: "dashboard-factoring-personal-data"
      }
    },
    // {
    //   icon: "account_balance",
    //   text: "Cuenta Bancaria",
    //   to: {
    //     name: "dashboard-investor-bank-account"
    //   }
    // },
    {
      icon: "lock",
      text: "Cambiar contraseña",
      to: {
        name: "dashboard-factoring-change-password"
      }
    }
  ]
};

export const Profile2 = {
  icon: "person",
  text: "Mis datos - Configuración",
  links: [
    {
      icon: "account_balance",
      text: "Cuenta Bancaria",
      to: {
        name: "dashboard-factoring-My-info-acount-bank"
      }
    },
    {
      icon: "assignment",
      text: "Datos personales",
      to: {
        name: "dashboard-factoring-personal-data"
      }
    },
    {
      icon: "lock",
      text: "Cambiar contraseña",
      to: {
        name: "dashboard-factoring-change-password"
      }
    }
  ]
};

export const MyBusiness = [
  {
    icon: "drafts",
    text: "Mis solicitudes",
    categories: [
      {
        icon: "folder_special",
        text: "Pendientes",
        to: {
          name: "dashboard-factoring-applicant-requests"
        }
      },
      {
        icon: "receipt",
        text: "Aprobadas",
        to: {
          name: "dashboard-factoring-applicant-my-projects"
        }
      }
    ]
  }
]


export const Legal = {
    icon: "receipt",
    text: "Mis Empresas",
    to: {
      name: "dashboard-factoring-bussiness"
    }
  }

  export const Finanzas = {
    icon: "receipt",
    text: "Mis Finanzas",
    to: {
      name: "dashboard-factoring-bussiness"
    }
  }

  // export const Profile2 = {
  //   icon: "person",
  //   text: "Mis datos",
  //   links: [
  //     {
  //       icon: "assignment",
  //       text: "Datos personales",
  //       to: {
  //         name: "dashboard-personal-data"
  //       }
  //     },
  //     // {
  //     //   icon: "account_balance",
  //     //   text: "Cuenta Bancaria",
  //     //   to: {
  //     //     name: "dashboard-investor-bank-account"
  //     //   }
  //     // },
  //     {
  //       icon: "lock",
  //       text: "Cambiar contraseña",
  //       to: {
  //         name: "dashboard-change-password"
  //       }
  //     }
  //     /*{
  //       icon: 'star',
  //       text: 'Socio club',
  //       to: {
  //         name: 'dashboard-investor-pay-membership',
  //       },
  //     },*/
  //     // {
  //     //   icon: 'import_export',
  //     //   text: 'Mis movimientos',
  //     //   to: {
  //     //     name: 'dashboard-my-movements',
  //     //   },
  //     // },
  //   ]
  // };
  